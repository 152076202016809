<template>
<div class="markets">
  <div class="box">
    <div class="texts">
      <h3>{{boxOne.title}}</h3>
      <span>{{boxOne.text}}</span>
      <button     @click.prevent="!state.token ? $go('/login') : $go('/trade') "  >{{boxOne.button}} <img :src="require('../../../assets/Public/join.png')" alt=""></button>
    </div>

  </div>
  <div class="pictures">
    <img class="pic" src="../../../assets/Public/walletsSup.svg" alt="">
<!--    <img src="../../../assets/Public/ball1.svg" alt="">-->
<!--    <img src="../../../assets/Public/ball2.svg" alt="">-->

<div class="balls"></div>
  </div>

</div>
</template>

<script>
export default {
  name: "Marketplace",
  data(){
    return {
boxOne:{
  title: "DeFi NFT Marketplace",
  text:'Mint DeFi NFTs and easily add utility with full control over their smart contracts. Trade DeFi NFTs such as the Loop Bond NFTs with fixed yield',
  button:"Trade and Mint Now"
}
     }
  }
}
</script>

<style lang="scss" scoped>
.markets{
  position: relative;
  height: 418px;
overflow: hidden;
  display: flex;
  //padding: clamp(1.25em,1.25em,2em);
  justify-content: space-between;
  background: #1C1C1C;
  border-radius: 30px;
  //width: 100%;
  align-items: flex-end;
  flex-wrap: wrap;
}


.box {
align-self: center;
  //height: 564px;
  display: flex;
  background-color: #1C1C1C;
  border-radius: 2em;
  flex-direction: column;

  flex: 1 1 300px;
  padding: clamp(1.5em, 2vw, 2.5em);

  h3{
    font-weight: 600;
    //line-height: 50px;
  }

  .texts{
    display: flex;
    flex-direction: column;
    gap: 16px;
    gap: 16px;
    button{

      display: flex;
      align-self: flex-start;
      font-size: clamp(0.75em, 1vw, 1.25em);
      font-weight: 600;
      gap:8px;
      img{
        align-self: center;
      }
    }
  }
.pictures{
  flex: 1 1 300px;
  align-self: flex-end;
  justify-content: flex-end;
  margin-bottom: -20px;


}






}
.pic{
  width: 100%;

  max-width: 618px;

  margin-bottom: -20px !important;
  right: 0;
  bottom: 0;



}
@media (max-width: 870px){

  .pic{
    margin-bottom: unset;
    display: none;
  }
}

</style>