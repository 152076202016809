<template>
  <div class="">history is here</div>
</template>

<script>
export default {
  name: "History"
}
</script>

<style scoped>

</style>