<template>
  <div class="sidebar">
    <template
    v-if="$route.name =='Dashboard' || $route.name==='Settings' || $route.name==='Invite' || $route.name==='KYC'"
    >
    <span v-for="(item,index) in dashList" :key="index">
      <div class="link-container" :class="{'border': $route.name ==item.link}">
        <a @click.prevent="$router.push({name:item.link})" href="">{{item.title}}</a>
      </div>
    </span>
    </template>
<template
v-if="$route.path.includes('wallet') "
>
      <span v-for="(item,index) in walletList" :key="index">
      <div class="link-container" :class="{'border': $route.name ==item.link}">
        <a @click.prevent="$router.push({name:item.link})" href="">{{item.title}}</a>
      </div>
    </span>
</template>

<template
v-if="$route.path.includes('history') "
>

    <span v-for="(item,index) in historyList" :key="index">
      <div class="link-container" :class="{'border': $route.name ==item.link}">
        <a @click.prevent="$router.push({name:item.link})" href="">{{item.title}}</a>
      </div>
    </span>
</template>






  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      dashList: [
        { title: "User Dashboard", link: "Dashboard" },
        { title: "Security", link: "Settings" },
        { title: "KYC Verification", link: "KYC" },
        { title: "Referral", link: "Invite" },
      ],
      walletList: [
        { title: "Overview", link: "WalletOverview" },
        { title: "Fiat and Spot", link: "Fiat" },
        { title: "Deposit", link: "Deposit" },
        { title: "Withdraw", link: "Withdraw" },
        { title: "History", link: "WalletHistory" },

      ],
      historyList: [
        { title: "Open Orders", link: "" },
        { title: "Spot Orders", link: "" },
        { title: "Order History", link: "" },
        { title: "Trade History", link: "" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  padding: 0 clamp(60px,4vw,100px);
  gap: clamp(1.1em, 1.5vw, 2.1em);
  background: #1D1F22;
  a{
      color: #7A8083;
  font-size: clamp(10px,1.1vw,14px);


  }

}

.link-container{
  display: flex;
  height: 52px;
  align-items: center;
}
.border{
    border-bottom: 2px solid #FFBB38;
   a{
      color: #FFBB38;
   }
}

@media (max-width: 768px) {
  .sidebar {
    padding: 0;
    gap: 10px;
  }
}
</style>
