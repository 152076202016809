<template>
  <div class="main">
<div class="titles">
  <div class="title">
    <p>Estimated Balance <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="26" height="26" rx="3" fill="#1D1F22"/>
      <path d="M13 8.5C8 8.5 6 13 6 13C6 13 8 17.5 13 17.5C18 17.5 20 13 20 13C20 13 18 8.5 13 8.5Z" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13 15.5C14.3807 15.5 15.5 14.3807 15.5 13C15.5 11.6193 14.3807 10.5 13 10.5C11.6193 10.5 10.5 11.6193 10.5 13C10.5 14.3807 11.6193 15.5 13 15.5Z" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    </p>
    <h4>0.13623600 BTC <span class="gray">≈ $6460.4737104</span></h4>
  </div>
  <div class="amounts">
    <div class="amount-cont">
      <span>Spot balance</span>
      <p>0.13623600 BTC</p>
      <span>≈ $6460.4737104</span>
    </div>

    <div class="amount-cont">
      <span>Fiat balance</span>
      <p>0.13623600 BTC</p>
      <span>≈ $6460.4737104</span>
    </div>

  </div>
</div>
    <div class="search">
      <div class="search-container">
        <img src="@/assets/profile/mag.svg" alt="">
        <input type="text" placeholder="Search Coin">
      </div>
      <div class="hide-zero">
        <input type="checkbox" id="hide-zero">
        <label for="hide-zero">Hide Small Balances</label>
      </div>
      <div class="convert">
        <img src="@/assets/profile/convert.svg" alt="convert" width="18" height="18">
        <span>Convert Small Balance to BNB</span>
      </div>
    </div>
    <div class="table">
      <div class="table-container">
        <table>
          <tr class="tr-head">
            <td>Coin</td>
            <td >total</td>
            <td class="tablet" >amount</td>
            <td class="tablet">in Order</td>
            <td class="tablet">BTC value</td>
            <td>Action</td>
          </tr>
          <tr class="tr-body" v-for="(item,index) in tableContents" :key="index">
            <td class="img-cont"><img :src="require(`@/assets/Coins/${item.coin}.png`)" alt="" width="38" height="38">  <span class="coin" ><span>{{item.coin}}</span>  <span>{{$coin2Snake[item.coin].toLowerCase()}} </span> </span></td>
            <td >{{item.total}}</td>
            <td class="tablet" >{{item.amount}}</td>
            <td class="tablet">{{item.inOrder}}</td>
            <td class="tablet">{{item.btcValue}}</td>
            <td class="actions">
              <a href="">Buy</a>
              <a href="">Deposit</a>
              <a href="">Withdraw</a>
            </td>
          </tr>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Cryptodiposit",
  data(){
    return{
      tableContents:[
        {
          coin: 'BTC',
          total: '0.13623600 BTC',
          amount: '0.13623600 BTC',
          inOrder: '0.13623600 BTC',
          btcValue: '$6460.4737104',
        },
        {
          coin: 'ETH',
          total: '0.13623600 ETH',
          amount: '0.13623600 ETH',
          inOrder: '0.13623600 ETH',
          btcValue: '$6460.4737104',
        },
        {
          coin: 'TRON',
          total: '0.13623600 TRON',
          amount: '0.13623600 TRON',
          inOrder: '0.13623600 TRON',
          btcValue: '$6460.4737104',
        },

        {
          coin: 'BTC',
          total: '0.13623600 BTC',
          amount: '0.13623600 BTC',
          inOrder: '0.13623600 BTC',
          btcValue: '$6460.4737104',
        },
        {
          coin: 'BTC',
          total: '0.13623600 BTC',
          amount: '0.13623600 BTC',
          inOrder: '0.13623600 BTC',
          btcValue: '$6460.4737104',
        },
        {
          coin: 'ETH',
          total: '0.13623600 ETH',
          amount: '0.13623600 ETH',
          inOrder: '0.13623600 ETH',
          btcValue: '$6460.4737104',
        },
        {
          coin: 'TRON',
          total: '0.13623600 TRON',
          amount: '0.13623600 TRON',
          inOrder: '0.13623600 TRON',
          btcValue: '$6460.4737104',
        },
        {
          coin: 'BTC',
          total: '0.13623600 BTC',
          amount: '0.13623600 BTC',
          inOrder: '0.13623600 BTC',
          btcValue: '$6460.4737104',
        },
        {
          coin: 'ETH',
          total: '0.13623600 ETH',
          amount: '0.13623600 ETH',
          inOrder: '0.13623600 ETH',
          btcValue: '$6460.4737104',
        },
        {
          coin: 'TRON',
          total: '0.13623600 TRON',
          amount: '0.13623600 TRON',
          inOrder: '0.13623600 TRON',
          btcValue: '$6460.4737104',
        },


      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.titles{
  display: flex;
  justify-content: space-between;
}
.main{
  display: flex;
  flex-direction: column;
  gap: 36px;
}
.title{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  p{
    display: flex;
    gap: 12px;


  }
}

.amounts{
  display: flex;
  gap: 40px;
  //text-align: center;
  span{
    font-size: clamp(10px, 1.2vw, 14px);
    color: #AFB0B1;
  }
  p{
    font-size: clamp(14px, 2vw, 18px);
  }
}
.search{
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  .search-container{
    flex: 1 1 auto;
    max-width: 303px;
    max-height: 40px;
    display: flex;
    padding:0 10px;
    gap: 10px;
    //flex-wrap: wrap;
    background: #1D1F22;
    border: 1px solid #272A2E;
    border-radius: 3px;
  }
  .hide-zero{
    display: flex;
    gap: 10px;
    align-items: center;
    input{
      width: 20px;
      height: 20px;
    }
  }
  .convert{
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    gap: 10px;
    span{
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */

      letter-spacing: -0.03em;

      color: #A1792B;
    }
  }
  .table-container{
    overflow: scroll;
    max-height: 500px;
    position: relative;
  }


}

table{
  border-collapse: collapse;
}
.tr-head{
  position: sticky;
  top: 0;
  height: 38px;
  background: #1D1F22;
td{
  color: #7A8083;
  font-size:clamp(8px, 1.2vw, 12px);

}
}
.tr-body{
  height: 55px;
  border-bottom: 1px solid #272A2E;
  td:last-child{
    display: flex;
    justify-content: space-between;
    a{
      color: #A1792B;
    }
  }
}
.img-cont{
  display: flex;
  align-items: center;
  gap: 12px;
  .coin{
    display: flex;
    flex-direction: column;
    span:last-child{
      color: #7A8083;
      font-size: clamp(8px, 1.2vw, 12px);
    }
  }
}
@media (max-width: 768px) {
  .actions{
    flex-direction: column;
  }

  .tablet{
    display: none;
  }
  .img-cont{
    align-items: center;
    justify-content: center;
    img{
      width: 18px;
      height: 18px;
    }
  }


  .search , .titles ,.title{
    .search-container{
      justify-content: unset;
    }
    flex-direction: column;
  }
}
</style>
