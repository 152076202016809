<template>
  <div class="footer">
<div class="container">
  <img src="../../assets/Public/Logo.png" alt="" width="140" height="40">
  <div class="contents">
    <div class="col">
      <h6 class="title">
Contact Us
      </h6>
<div class="list" v-for="(item,index) in contactUs" :key="index">
  <a  href="" @click.prevent="$go(item.link)">{{item.title}}</a>
</div>



    </div>
    <div class="col">
      <h6 class="title">
Products
      </h6>
      <div class="list" v-for="(item,index) in products" :key="index">
        <a  href="" @click.prevent="$go(item.link)">{{item.title}}</a>
      </div>



    </div>
    <div class="col">
      <h6 class="title">
Service
      </h6>
      <div class="list" v-for="(item,index) in services" :key="index">
        <a  href="" @click.prevent="$go(item.link)">{{item.title}}</a>
      </div>


    </div>
    <div class="col">
      <h6 class="title">
Business
      </h6>
      <div class="list" v-for="(item,index) in Business" :key="index">
        <a  href="" @click.prevent="$go(item.link)">{{item.title}}</a>
      </div>



    </div>
    <div class="col">
      <h6 class="title">
Legal
      </h6>
      <div class="list" v-for="(item,index) in Legal" :key="index">
        <a  href="" @click.prevent="$go(item.link)">{{item.title}}</a>
      </div>



    </div>
    <div class="col">
      <h6 class="title">
Tools
      </h6>
      <div class="list" v-for="(item,index) in Tools" :key="index + 'tools'">
        <a  href="" @click.prevent="$go(item.link)">{{item.title}}</a>
      </div>


      <h6 class="title">
Developer
      </h6>
      <div class="list" v-for="(item,index) in Developer" :key="index">
        <a  href="" @click.prevent="$go(item.link)">{{item.title}}</a>
      </div>



    </div>


  </div>
</div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      contactUs: [
        {
          title: 'About Us',
          link: ''
        },
        {
          title: 'Join Us',
          link: ''
        },
        {
          title: 'Media Kit',
          link: ''
        },
        {
          title: 'Omani Affiliate Program',
          link: ''
        },
        {
          title: 'Bug Bounty',
          link: ''
        },
        {
          title: 'Technical Support',
          link: ''
        }

      ],
      products: [
        {
          title: 'Spot ',
          link: ''
        },
        {
          title: 'Trade',
          link: ''
        },
        {
          title: 'Contract  (Futures)',
          link: ''
        },
        {
          title: 'KuCoin Earn',
          link: ''
        },
        {
          title: 'Trading Bot',
          link: ''
        },
      ],
      services: [
        {
          title: 'Support Center',
          link: ''
        },
        {
          title: 'FAQ',
          link: ''
        },
        {
          title: 'Submit a Ticket',
          link: ''
        },
        {
          title: 'Fee',
          link: ''
        },
        {
          title: 'Identity Verification',
          link: ''
        },
        {
          title: 'KuCoin Blog',
          link: ''
        }

      ],
      Business: [
        {
          title: 'Token Listing',
          link: ''
        },
        {
          title: 'Join Us',
          link: ''
        },
        {
          title: 'Media Kit',
          link: ''
        },
        {
          title: 'Omani Affiliate Program',
          link: ''
        },
        {
          title: 'Bug Bounty',
          link: ''
        },
        {
          title: 'Technical Support',
          link: ''
        }

      ],
      Legal: [
        {
          title: 'Terms of Use',
          link: ''
        },
        {
          title: 'Privacy Policy',
          link: ''
        },
        {
          title: 'Risk Disclosure ',
          link: ''
        },
        {
          title: 'AML&CFT',
          link: ''
        },
        {
          title: 'Special Treatment Area',
          link: ''
        },
        {
          title: 'Plus Trading Area',
          link: ''
        }

      ],
Tools:[
  {
    title: 'Beginner\'s Guide',
    link: ''
  },
  {
    title: 'Official Media Verification',
    link: ''
  }
],
      Developer:[
        {
          title: 'API Document',
          link: ''
        },
        {
          title: 'SDK',
          link: ''
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>




h6{
  font-size: (14px,1vw,18px);
  font-weight: 500;
}
.footer{
  display: flex;
  width: 100%;
  background:#0C0E12;
flex-direction: column;
}

.container{
  padding: 1em;
display: flex;
  flex-direction: column;
  gap: 1rem;
}
.contents{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.col{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .list{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    a{
      color:#7A8083;
      font-size: clamp(.6rem, 1vw, .8rem);
    }
  }
}

</style>
