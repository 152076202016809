<template>
  <div class="header">
        <span  v-for="(item , index) in items" :key='index' :class="{'selected' : select === item , 'disable' : item === 'Stop - Limit'}"
               @click= "$emit('selectitem' , item) ; select = item">{{ item }}</span>


  </div>
</template>

<script>
export default {
  name: "the-headerTable",
  props:["items" , "selected" , "allhistory" , "disable"],
  data(){
    return{
      select:this.selected,
      width:0,
    }
  },

  mounted() {
    this.width = screen.width;
  }
}
</script>

<style lang="scss" scoped>
.disable{
  cursor: not-allowed;
}
.header {
  background: #1D1F22 !important;
  display: flex;
  gap: 30px;
  border-bottom: 1px solid rgba(242, 242, 242, 0.21);
  position: relative;
  margin: 0 auto;
height: 40px;
  padding: 0 16px;


  span {
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: rgba(189, 189, 189, 1);
    align-self: center;
    font-size: clamp(12px, 1vw, 16px);
  }

  .all-history{
    //color: var(--main-background-color);
    opacity: 0.5;
    margin-left: auto;
    margin-right: 3%;
    text-decoration: underline;
    cursor: pointer;
    font-size: clamp(12px, 1vw, 14px);
  }

  .selected {
    height: 100%;
    align-self: center;
    font-size: clamp(12px, 1vw, 14px);
    position: relative;
    //color: var(--main-background-color) !important;
    color: #FFBB38 !important;
    border-bottom: 1px solid #FFBB38;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      //background-color: var(--main-background-color);
      height: 3px;
      bottom: 0;
      top: 26px;
      right: 0;
      border-radius: 20px;
    }
  }
}



</style>