<template>
<div class="">
  <div class="market">
    <div class="container">
      <div class="table-container">
        <vue-skeleton-loader class="skeleton" v-if="loading" >

        </vue-skeleton-loader>


        <table>
          <tr class="tr-head">
            <td v-for="(item,index) in tableHead" :key="index" :id="'td'+index" :class="item.class">{{item.title}}
              <svg :style="{alignSelf :'center'}" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.3996 6L7.99961 3.6L5.59961 6" stroke="#7A8083" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.3996 8.7998L7.99961 11.1998L5.59961 8.7998" stroke="#7A8083" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>


            </td>
          </tr>
          <tr class="tr-body" v-for="(content,index) in tableContent">
            <td class="image-container">
                   <img class="mobile" :src="require('../../assets/Coins/'+$coin2Snake[content.relatedCoin]+ '.png')" alt="" width="24" height="24"></img>
              <span>{{content.relatedCoin}}</span>
              <small class="tablet">{{$coin2Snake[content.relatedCoin].split('_').join(' ')}}</small>
         
            </td>
            <td>{{$toLocal(content.lastPrice, $decimal['TETHER'])}}</td>
            <td class="tablet" :class="{'red' : content.last24HourChange<0 , 'green':content.last24HourChange>0 }">

              <!-- <span>{{content.last24HourChange<0?'-': '+'}}</span> -->
              {{content.last24HourChange.toLocaleString()}}
              %

              <svg v-if="content.last24HourChange<0" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 8.63623L7.54545 3.18168L5.36364 5.3635L1 0.999867" stroke="#F6465D" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.0007 5.90896V8.63623H10.2734" stroke="#F6465D" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              <svg v-if="content.last24HourChange>0" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 1L7.54545 6.45455L5.36364 4.27273L1 8.63636" stroke="#0CCC80" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.0007 3.72727V1H10.2734" stroke="#0CCC80" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>




            </td>
            <td class="tablet" >


              {{ $toLocal(content.last24HourVolume, $decimal['TETHER']) }}</td>
            <td class="mobile">{{$toLocal(content.lastPrice, $decimal['TETHER'])}}</td>
            <td class="buttons-table">
              <button class="btn-primary" @click.prevent="$go('/otc')">Buy</button>
              <button class="btn-white"  @click.prevent="$go(`/trade/${$coin2Snake[content.relatedCoin]}`)">Trade</button>


            </td>
          </tr>
        </table>
      </div>
    </div>

  </div>
  <FOOTER/>
</div>
</template>

<script>
import FOOTER from "@/components/Public/Footer";
export default {
  name: "Market",
  components: {FOOTER},

  data() {
    return {
      tableContent:[],
      loading:true,
      tableHead:[
        { title:"Coins" },
        { title:"Price"},
        { title:"24H Change", class:'mobile'},
        { title:"24H Volume"  ,class: "tablet"},
        { title:"Market Cap" ,class: "tablet"},
        { title:`  `},
          ]
    };
  },
  methods:{

    async getTableContent(){
      let res = await this.$axios('/trades/market-info?weeklyDataIncluded=true')
      // res = res.content
      // res = res.filter(item=> item.marketType.includes('TETHER'))
      this.tableContent = res.content
      this.tableContent = this.tableContent.filter(item=> item.marketType.includes('TETHER'))
this.loading=false;
    }
  },
  mounted() {
    this.getTableContent()
  }
}
</script>

<style lang="scss" scoped>
table{
  border-collapse: collapse;
}
.container{
  max-width: 1600px;
}
small{
  font-size: 0.7em;
  color: var(--gray);
}

.loader{
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.12);
filter: blur(4px);
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
}


.table-container{
  position: relative;
  max-height: 600px;
  min-height: 300px;
  overflow-y: scroll;

}
#td5{
  svg{
    display: none;
  }
}
.tr-head{
  padding: 0.5rem;
  position: sticky;
  top: 0;
  height: 40px;
  background: #1D1F22;
  z-index: 1;
}
.market {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 4em 1em;
}
.image-container{
  align-self: center;
  display:flex;
  gap: 9px;
  align-items: center;
}
.tr-body{

  height: 60px;
border-bottom: 1px solid var(--gray);

td{
  align-self: center;
  margin-top: 0.9rem;
}
}

.buttons-table{
  display: flex;
  gap: 10px;
  justify-content: center;
  button{
    //flex: 1 1 75px;
    max-width: 75px;

  }
}



@media (max-width: 700px) {
  .tablet{
    display: none;
  }

}
@media(max-width: 500px) {
  .mobile {
    display: none;
  }
  .buttons-table {
    .btn-primary, .btn-white {
      flex: 1;
      width: 50px;
      font-size: 8px;
    }
  }
}
</style>