<template>
<div class="fund-container" v-if="isOpen">
<div class="title">
<div class="guide">
  <p>Fund Your Account</p>
  <a href=""  @click.prevent="$emit('skip',isOpen)"  >Skip Guide</a>
</div>
  <span>Omani supports multiple options to fund your account. Find a method that suits you the most.</span>
</div>
<div class="actions-box">
  <div class="box">
    <div class="titles">
      <img src="../../../../assets/profile/cards.png" alt="" width="50" height="42">
   <div class="text">
     <p>Buy Crypto</p>
     <span class="texts">
       Deposit fiat currencies (e.g. EUR) from your bank account to buy crypto.
     </span>
   </div>
    </div>
    <button @click.prevent="$router.push('/trade')" class="btn-primary">Buy</button>
  </div>
  <div class="box">
    <div class="titles">
      <img src="../../../../assets/profile/safebox.png" alt="" width="50" height="42">
      <div class="text">
        <p>Deposit Crypto</p>
        <span class="texts">
     If you already have crypto currency, you can use deposit function to transfer them.
        </span>
      </div>
    </div>
    <button class="btn-primary" @click.prevent="$router.push('/profile/wallet/deposit')">Deposit</button>
  </div>
  <div class="box">
    <div class="titles">
      <img src="../../../../assets/profile/atm.png" alt="" width="50" height="42">
      <div class="text">
        <p>Withdraw Crypto</p>
        <span class="texts">
          Deposit fiat currencies (e.g. EUR) from your bank account to buy crypto.
     </span>
      </div>
    </div>
    <button class="btn-primary" @click.prevent="$router.push('/profile/wallet/withdraw')">Withdraw</button>
  </div>

</div>
<div class="assets">
<div class="titles">
  <p>My Assets</p>
  <span>Fiat & Spot</span>
  <span>Funding</span>
</div>
  <div class="amounts">
    <span>
      <input type="checkbox">
      Hide 0 Balance wallets

    </span>
    <span>0.13623600</span>
    <span>0.13623600</span>
  </div>
</div>
</div>
</template>

<script>
export default {
  name: "Fund",
  data(){
    return{
      isOpen:true,
    }
  }
}
</script>

<style lang="scss" scoped>
.fund-container{
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.title{
  display: flex;
  flex-direction: column;
  gap: 8px;
  span{
    font-size: clamp(10px,1vw,14px);
    color: #7A8083;
  }
  .guide{
    display: flex;
    justify-content: space-between;
    p{

      font-size: clamp(14px,1.2vw,20px);
      font-weight: bold;
    }
    a{
      color: #A1792B;
      font-size: clamp(10px,1vw,14px);
    }
  }
}
.actions-box{
  display: flex;
  flex-direction: column;
  gap: 40px;

  .box{
    border-bottom: 1px solid #1D1F22;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    //height: 60px;
    display: flex;
    justify-content: space-between;
    .titles{
      display: flex;
      gap: 26px;
      text{
        display: flex;
        flex-direction: column;
        gap: 8px;

      }
    }
  }
  .texts{
    color: #7A8083;
    font-size: clamp(8px,1vw,12px);

  }
}

.assets{
  display: flex;
  justify-content: space-between;
  .titles , .amounts{
    p{
      font-size:clamp(16px,1.5vw,20px);
      font-weight: bold;
    }
    display: flex;
    flex-direction: column;
    gap: 22px;

    input[type="checkbox"]{
      align-self: center;
      width: 20px;
      height: 20px;
      border: 1px solid #1D1F22;
      border-radius: 50%;
      background-color: #1D1F22 !important;
      color: black;
      margin-right: 10px;
    }
  }
  .amounts{
    span{
      text-align: right;
    }
    span:first-child{
      display: flex;
      align-items: center;
    }
  }
}
</style>