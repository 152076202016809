<template>
  <div class="home-page">
    <div class="main">
      <banner />
      <market-view />
      <support-vue />
      <blog />
      <FAQ/>
      <joinus/>
    </div>
    <footer-vue />
  </div>
</template>

<script>
import Banner from "./banner/Banner.vue";
import MarketView from "./MarketView.vue";
import SupportVue from "./support/Support.vue";
import Blog from "./Bys.vue";
import FooterVue from "../Public/Footer.vue";

import Support from "./support/Support"
import FAQ from "@/components/Home-page/FAQ";
import Joinus from "@/components/Home-page/support/Joinus";

export default {
  name: "HomePage",
  components: {
    Joinus,
    FAQ,
    Banner,
    MarketView,
    SupportVue,
    Blog,
    FooterVue,
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/Public/banner.svg");
  background-repeat: no-repeat;
  background-size: cover;


}
.home-page::after{
  top: 0;
  right: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  content: "";

  z-index: -1;
}
.main {
  display: flex;
  flex-direction: column;
  row-gap: 104px;
}
</style>
