<template>
  <div class="notifications">
    <h1>Notifiactions</h1>
    <div class="buttons">
      <button :class="{'activate': all , 'not-active' : all===false }" @click.prevent="all =true , unread=false" >{{'ALL notifications'}}</button>
      <button :class="{'activate': unread , 'not-active' : unread===false }" @click.prevent="unread =true , all=false">{{'Unread Notificaitons'}}</button>
    </div>
    <div class="notification-container" v-if="all">
      <div v-for="(item,index) in notifications" :key="index" class="notification"  @click="item.complete=!item.complete,item.checked=!item.checked" >
        <div class="header">
          <div class="title-container" >
            <span @click="item.checked=!item.checked" :class="{'checked': item.checked}" class="checkbox" name="terms"></span>
            <h4>{{  item.title }}</h4>
          </div>
          <div @click="item.complete=!item.complete" class="time-container">
            <div class="time">
              <span>{{item.hours}}:{{item.minute}}</span>
              <span>-</span>
              <span>{{item.year}}/{{item.mounth}}/{{item.day}}</span>
              <img src="../../../assets/Public/selector.svg" alt="">
            </div>

          </div>
        </div>
        <transition name="view">
          <p v-if="item.complete==true" class="text">{{item.text[1]}}</p>
        </transition>
      </div>
    </div>
    <div class="notification-container" v-if="unread">
      <div v-for="(item,index) in notification" :key="index" class="notification">
        <div class="header">
          <div class="title-container">
            <span @click="item.checked=!item.checked" :class="{'checked': item.checked}" class="checkbox" name="terms"></span>
            <h4>{{  item.title }}</h4>
          </div>
          <div class="time-container">
            <div class="time">
              <span>{{item.hours}}:{{item.minute}}</span>
              <span>-</span>
              <span>{{item.year}}/{{item.mounth}}/{{item.day}}</span>
              <img src="../../../assets/Public/selector.svg" alt="">
            </div>

          </div>
        </div>
        <transition name="view">
          <p v-if="item.complete==true" class="text">{{item.text[1]}}</p>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'Notifications',
  data () {
    return{
      termscheck:false,
      all:true,
      unread:false,
      notifications:[
        {
          title:'Login Summary',
          text: [
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد'
            ,
        'your Login was successful'

          ] ,
          year:1400,
          mounth:11,
          day:12,
          hours:13,
          minute:13,
          checked:false,
          complete:false,
        },
        {
          title:'Login Summary',
          text: [
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد'
            ,
'your Login Were unsuccessful'          ] ,
          year:1400,
          mounth:11,
          day:12,
          hours:13,
          minute:13,
          checked:false,
          complete:false,
        },
        {
          title:'Login summary',
          text: [
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد'
            ,
         'please Complete your Authorization to access the system'
          ] ,
          year:1400,
          mounth:11,
          day:12,
          hours:13,
          minute:13,
          checked:false,
          complete:false,
        },
      ],
      notification:[
        {
          title:'Login Summary',
          text: [
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد'
            ,
            'your Login was successful'

          ] ,
          year:1400,
          mounth:11,
          day:12,
          hours:13,
          minute:13,
          checked:false,
          complete:false,
        },
        {
          title:'Login Summary',
          text: [
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد'
            ,
            'your Login Were unsuccessful'          ] ,
          year:1400,
          mounth:11,
          day:12,
          hours:13,
          minute:13,
          checked:false,
          complete:false,
        },
        {
          title:'Login summary',
          text: [
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد'
            ,
            'please Complete your Authorization to access the system'
          ] ,
          year:1400,
          mounth:11,
          day:12,
          hours:13,
          minute:13,
          checked:false,
          complete:false,
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

.activate{
  color: white;
  background: rgba(211, 211, 211, 0.48);
  border-radius: 20px;
}

.not-active{
  border-radius: 20px;
  border:1px solid rgba(61, 61, 61, 0.98);
  background: transparent;
  color:rgba(61, 61, 61, 0.98) ;

}

p{
  color: white;
  font-weight: 400;
  font-size: clamp(10px,3vw,14px);
}
h4,span{
  font-weight: 400;
  font-size: clamp(10px,3vw,14px);
}
h1{
  align-self: flex-start;
  font-weight: 700;
  font-size: clamp(18px,3vw,24px);
}
.view-leave-active {
  transition: height .6s ease-in-out, transform .6s ease;
}

.view-enter-active {
  transition: height .6s ease-in-out, transform .6s ease;
}

.view-enter, .view-leave-to {
  height: 0;
}

.view-enter-to, .view-leave {
  height: 40px;
}


//buttons
.buttons{
  display: flex;
  gap: 15px;
  button{
    min-width: 44px;
  }
}

.title-container{
  display: flex;
  column-gap: 16px !important;
}
.notifications{


  //background: #191730;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  box-sizing: border-box;
  padding: 24px;
  width: 100%
}
.notification-container{
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.title-container,.time-container{
  display: flex;
  flex-direction: row;
  width: 20%;
  align-items: center;
  column-gap: 5px;

}
.time-container{
  justify-content: flex-end;
  img{
    cursor: pointer;
  }
}
.notification{
  //background: var(--primary-color);
  width: 100%;
  cursor: pointer;
  border-radius:4px ;
  box-sizing: border-box;
  padding: 10px;
  overflow: hidden;
}
.time{
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  color: var(--gray);
}
.header{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.checkbox{
  //margin: 0 16px;
  cursor: pointer;
  width: clamp(10px,1.5vw,16px);
  height: clamp(10px,1.5vw,16px);
  background: linear-gradient(81.26deg, #FFBB38 0%, #FF5454 100%);
  border-radius: 50%;

}
.checked{
  background: var(--gray);
  border: none !important;
  width: clamp(10px,1.5vw,16px);
  height: clamp(10px,1.5vw,16px);

}
.complete{
  transform: rotate(180deg);
}
@media only screen and(max-width:750px){
  .title-container,.time-container{
    width: 40%;
  }
}
@media only screen and(max-width:400px){
  .time-container{
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .title-container{
    width: 50%;
  }
}
</style>