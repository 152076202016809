<template>
  <div class="">
    account is here

  </div>
</template>

<script>
export default {
  name: "Account"
}
</script>

<style scoped>

</style>