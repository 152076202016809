<template>
<div class="">
  <div class="guide">
    <div class="guide-icon">
      <img  src="../../assets/Public/guideicon.svg" alt="">
    </div>

    <div class="title">
      <h4>How Can we help you?</h4>
      <div class="search-container">
        <img src="../../assets/Public/searchicon.svg" alt="">
        <input type="search" placeholder="Search Guide">
      </div>

    </div>
    <div class="content">
      <div class="cards">
        <div class="row">
          <div class="card">
            <img src="../../assets/Public/helpchat.png" alt="" width="96" height="96">
            <p class="titles">Beginner's Guide</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>

            <p></p>
          </div>
          <div class="card">
            <img src="../../assets/Public/helpchat.png" alt="" width="96" height="96">
            <p class="titles">Beginner's Guide</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>

            <p></p>
          </div>
          <div class="card">
            <img src="../../assets/Public/helpchat.png" alt="" width="96" height="96">
            <p class="titles">Beginner's Guide</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>

            <p></p>
          </div>
        </div>
        <div class="row">
          <div class="card">
            <img src="../../assets/Public/helpchat.png" alt="" width="96" height="96">
            <p class="titles">Beginner's Guide</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>

            <p></p>
          </div>
          <div class="card">
            <img src="../../assets/Public/helpchat.png" alt="" width="96" height="96">
            <p class="titles">Beginner's Guide</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>

            <p></p>
          </div>
          <div class="card">
            <img src="../../assets/Public/helpchat.png" alt="" width="96" height="96">
            <p class="titles">Beginner's Guide</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>

            <p></p>
          </div>
        </div>

      </div>
      <div class="faq">
        <p>FAQs</p>
        <div
            class="accordian"
            v-for="(item, index) in questions"
            :key="index"
        >
          <button  @click.prevent="activeAccordian(index)">
            {{ item.title }}
            <img src="../../assets/Public/selector.svg" />
          </button>

          <transition name="fade">
            <div class="answer" v-if="activeIndex == index">
              <div v-html="item.html">

              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>



  </div>
  <footer-component/>
</div>
</template>

<script>
import FooterComponent from "@/components/Public/Footer";
export default {
  name: "Guide",
  components: {FooterComponent},
  data(){
    return{
      crypto:crypto,
      questions:[],
      activeIndex: null,

    }
  },
  methods: {
    activeAccordian(Index) {
      this.activeIndex = this.activeIndex == Index ? null : Index;

    },
    async getFaq (){
      this.state.loading=true;
      const res = await this.$axios('/user-manual')
//  console.log(res)
      this.questions = res
      this.state.loading=false;

    },
  },
  mounted() {
    this.getFaq()
  },
}
</script>

<style lang="scss" scoped>

.guide{
  position: relative;
  background-image: url("../../assets/Public/guidebg.png");
  background-size: cover;
  //background-repeat: no-repeat;
  //height: 100vh;
  display: flex;
  gap: 2em;
  flex-direction: column;


}
.guide-icon{
  position: absolute;
  //left: 0;
  right: 0;


}

.search-container{
  display: flex;
  background: #1D1F22;
  border: 1px solid #272A2E;
  max-width: 300px;
  padding: 0 12px;
}

.title{
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin: 3.5em;
}

.content{

  display: flex;
  flex-direction: column;
  margin-top: clamp(12px,1vw,31px);
  margin-bottom: clamp(20px,1vw,144px);
  gap: clamp(30px,4vw,88px);
}
.cards{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 31px;
  .row{
    flex-wrap: wrap;
    display: flex;
    gap: 31px;
    //flex-grow: 1;
    //flex-basis: 100px;
    .card{
      //flex-grow: 1;
      //
      //flex-basis: 100px;
      flex-wrap: wrap;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 16px;
      gap: 12px;
      background: linear-gradient(115.97deg, #191B1D 0%, #1B1C1F 106.55%);


      border-radius: 8px;
      .titles{
        font-size: clamp(20px,2vw,24px);
        font-weight: 700;
      }
      p{
        max-width: 325px;
        font-size: clamp(12px,1vw,16px);

      }
    }
  }
}

.faq{
  padding: 12px;
  //border-radius: 12px;
  //background: #191730;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.accordian{
  display: flex;
  flex-direction: column;
  //gap: 24px;

  button{
    align-items: center;
    background: #191730;background: linear-gradient(115.97deg, #191B1D 0%, #1B1C1F 106.55%);


    border-radius: 4px;
    display: flex;
    padding: 0 25px;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    //background: #1C1A3E;
    border-radius: 4px;
  }
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media(max-width: 600px){
  .cards {
    width: 90%;
    align-self: center;
  }
}
</style>