var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"crypto-deposit"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"deposit-box"},[_c('div',{staticClass:"card-detail"},[_c('div',{staticClass:"currency"},[_c('span',[_vm._v("Select currency")]),_c('div',{staticClass:"coin-box"},[_c('button',{staticClass:"drop-btn",on:{"click":function($event){_vm.dropdown = !_vm.dropdown}}},[_c('span',{staticClass:"coin-info"},[_c('img',{attrs:{"src":require(("@/assets/Coins/" + (_vm.sourceCoin.code) + ".png")),"width":"20","height":"20"}}),_vm._v(" "+_vm._s(_vm.sourceCoin.code))]),_c('svg',{attrs:{"width":"14","height":"8","viewBox":"0 0 14 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.49497 0.505025C1.22161 0.231658 0.778392 0.231658 0.505025 0.505025C0.231658 0.778392 0.231658 1.22161 0.505025 1.49497L1.49497 0.505025ZM7 7L6.50503 7.49497C6.77839 7.76834 7.22161 7.76834 7.49497 7.49497L7 7ZM13.495 1.49497C13.7683 1.22161 13.7683 0.778392 13.495 0.505025C13.2216 0.231658 12.7784 0.231658 12.505 0.505025L13.495 1.49497ZM0.505025 1.49497L6.50503 7.49497L7.49497 6.50503L1.49497 0.505025L0.505025 1.49497ZM7.49497 7.49497L13.495 1.49497L12.505 0.505025L6.50503 6.50503L7.49497 7.49497Z","fill":"#7A8083"}})])]),(_vm.dropdown)?_c('div',{staticClass:"dropdown"},_vm._l((_vm.coins.filter(
                  function (coin) { return coin.coin !== 'TOMAN'; }
                )),function(coin,index){return _c('span',{key:index},[_c('img',{attrs:{"src":require(("@/assets/Coins/" + (coin.code) + ".png")),"alt":"","width":"18","height":"18"}}),_c('span',{on:{"click":function () {
                      _vm.getQR(coin.coin);
                      _vm.isNetSelect = true;
                      _vm.dropdown = !_vm.dropdown;
                      _vm.sourceCoin = coin;
                    }}},[_vm._v(" "+_vm._s(coin.code)+" ")])])}),0):_vm._e()])]),_vm._m(0)]),_c('div',{staticClass:"deposit"},[_vm._m(1),_c('button',{staticClass:"btn-primary",on:{"click":function($event){_vm.isOrder=true}}},[_vm._v("Deposit")])])])]),_c('div',{staticClass:"table"},[_c('p',[_vm._v("Recent Deposits")]),_c('div',{staticClass:"table-container"},[_c('table',[_vm._m(2),_vm._l((_vm.tableContents),function(item,index){return _c('tr',{staticClass:"tr-body"},[_c('td',{staticClass:"tablet"},[_vm._v(_vm._s(item.time))]),_c('td',[_vm._v(_vm._s(item.type))]),_c('td',[_vm._v(_vm._s(item.amount))]),_c('td',{staticClass:"tablet"},[_vm._v(_vm._s(item.DepositWallet))]),_c('td',{staticClass:"mobile"},[_vm._v(_vm._s(item.Destination))]),_c('td',{staticClass:"tablet"},[_vm._v(_vm._s(item.TxID.slice(0, 9) + "..."))]),_c('td',{class:{
              red: item.Status === 'Failed',
              green: item.Status === 'Completed',
              yellow: item.Status === 'Pending',
            }},[_vm._v(" "+_vm._s(item.Status)+" ")])])})],2)])]),(_vm.isOrder)?_c('shop-modal',{on:{"close":function($event){_vm.isOrder=false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-section"},[_c('span',[_vm._v("Card Details")]),_c('div',{staticClass:"cards-info"},[_c('div',{staticClass:"name"},[_c('input',{attrs:{"type":"text","placeholder":"Name on Card"}})]),_c('div',{staticClass:"number"},[_c('input',{attrs:{"type":"text","placeholder":"Card Number"}}),_c('img',{attrs:{"src":require("@/assets/profile/visa.png"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/profile/master.png"),"alt":""}})]),_c('div',{staticClass:"cvv"},[_c('input',{attrs:{"type":"text","placeholder":"Expiry date"}}),_c('input',{attrs:{"type":"text","placeholder":"Security Code (CVV)"}})])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('p',[_vm._v("You receive:")]),_c('h4',[_vm._v("120.79 USD")]),_c('span',{staticClass:"trans"},[_c('span',{staticClass:"gray"},[_vm._v("Transaction Fee: ")]),_vm._v(" 2.21 USD")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"tr-head"},[_c('td',{staticClass:"tablet"},[_vm._v("Time")]),_c('td',[_vm._v("Type")]),_c('td',[_vm._v("Amount")]),_c('td',{staticClass:"tablet"},[_vm._v("Deposit Wallet")]),_c('td',{staticClass:"mobile"},[_vm._v("Destination")]),_c('td',{staticClass:"tablet"},[_vm._v("TxID")]),_c('td',[_vm._v("Status")])])}]

export { render, staticRenderFns }