<template>
  <div class="contents">
    <div class="first-row">
      <div class="title">
        <div class="title-text">
          <h4> Create Your Account </h4>
          <!--          <p class="deactive">{{ this.state.lan.login.GoogleTitle }}</p>-->
          <!--          <button>-->
          <!--&lt;!&ndash;            <inline-svg :src="require('../../assets/Authentication/google.svg')"/>&ndash;&gt;-->
          <!--&lt;!&ndash;            <span>{{ this.state.lan.login.GoogleBtn }}</span>&ndash;&gt;-->
          <!--          </button>-->
        </div>
      </div>
    </div>
    <div class="login">

      <div class="second-row">
        <div class="mail">
          <label >Email:</label>
<div class="input-container">
  <input placeholder="Your email address" name="email" v-model="data.email">
</div>
        </div>
        <div class="pass">
          <label >password:</label>
<div class="input-container">
  <input placeholder="Your password" name="password" :type="type" v-model="data.password">
  <img src="../../assets/profile/eye.svg" alt="" width="18" height="18" @click="showingPass">
</div>
        </div>
        <div class="mail">
          <label >Referral (Optional):</label>
          <input placeholder="Submit Referral Code" name="email" v-model="data.referralCode">
        </div>
      </div>
      <button class="btn-primary" @click="registerUser" :disabled="!data.email || !data.password || !check">
        Sign Up
      </button>


      <div class="forget">
        <span>Have an Account? <a class="yellow" @click.prevent="$go('/login')">Login</a></span>



      </div>


    </div>
  </div>

</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      type: "password",
      data: {
        email: "",
        password: "",
        osType: "",
        deviceType: "",
        browser: "",
        referralCode: "",
      },
      check: true,
    };
  },
  methods: {
    showingPass() {
      if (this.type === "password") {
        this.type = "text";
      } else if (this.type === "text") {
        this.type = "password";
      }
    },
    async checkEmail() {
      if (!this.$validEmail(this.data.email) || !this.data.email) {
        if (this.state.lang == "fa") {
          this.emailError = "ایمیل صحیح وارد نمایید";
          this.$error("اطلاعات وارد شده اشتباه است", "ایمیل صحیح وارد نمایید");
        } else {
          (this.emailError = "Please Enter a correct password"),
              this.$error(
                  "The information entered is incorrect",
                  "Please Enter a correct password"
              );
        }
        return Promise.reject("repeat failed : " + this.data.email);
      } else {
        this.emailError = "";
      }
    },
    async checkPassword() {
      if (
          !this.$validPass(this.data.password) ||
          this.data.password.length < 8
      ) {
        if (this.state.lang === "fa") {
          this.passError =
              "رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد";
          this.$error(
              "اطلاعات وارد شده اشتباه است",
              "رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد"
          );
        } else {
          this.passError =
              "The password must be at least 8 characters long and contain uppercase, lowercase, and numbers";
          this.$error(
              "The information entered is incorrect",
              "The password must be at least 8 characters long and contain uppercase, lowercase, and numbers"
          );
        }

        return Promise.reject("wrong pass : " + this.data.password);
      } else {
        this.passError = "";
      }
    },
    //axios functions placed in here
    googleSso() {
      window.location.href = this.$axios.defaults.baseURL + "/users/google-sso";
    },
    async registerUser() {
      await this.checkEmail();
      await this.checkPassword();
      // this.state.loading=true;
      const res = await this.$axios.post("users/register", this.data);
      console.log(res);
      if (
          res.message === "user created successfully , verification email sent"
      ) {
        if (res.baseDTO.towStepAuthenticationType === "EMAIL") {
          this.$cookies.set("username", this.data.email);
          this.state.userInfo.email = this.data.email;
          await this.$router.push("/otpSignup");
        }
      } else if (res.token) {

        this.$setToken(res.token, this.state.userInfo.remember);
        await this.$router.push("/otpSignup");
      }
      console.log("success");
    },
  },
  mounted() {
    //mount platform and etc.
    this.data.osType = this.$detectOs();
    this.data.browser = this.$detectBrowser() || "CHROME";
    this.data.deviceType = this.$detectDevice();
    //save refferal in the localstorage
    console.log(
        "os type",
        this.data.osType,
        "browser type",
        this.data.browser,
        "device type",
        this.data.deviceType
    );
    if (this.$route.query.ref || localStorage.referralCode) {
      localStorage.referralCode =
          this.$route.query.ref || localStorage.referralCode;
      this.data.referralCode =
          this.$route.query.ref || localStorage.referralCode;
    }
  },
}
</script>

<style lang="scss" scoped>
.contents{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
}
.forget{
  display: flex;
  justify-content: space-between;
  font-size: clamp(.5em, 1.5vw, .8em);
  .yellow{
    color:#A1792B;
  }
}
.btn-primary{
  width: 100%;
  height: 50px;
}

.login {
  width: 100%;
  max-width: 465px;
  padding: 2em;
  background: #181A1D;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  //padding: 12px 24px;
  gap: 2em;

  //first row contains title and google login
  .first-row {
    //main title
    .title {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .title-text {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      button {
        display: flex;
        border-radius: 4px !important;
        align-items: center;
        font-size: clamp(10px, 1vw, 12px);
        font-weight: 400;
        background: #181A1D;
        border: 1px solid rgba(178, 176, 176, 0.5);
        min-height: 40px;
        padding: 0 12px;
      }

      span {
        margin-right: 27%;
      }
    }

    //texts of title
    .text {

    }
  }

  //second row contains inputs
  .second-row {
    display: flex;
    flex-direction: column;
    gap: 2.5em;
    .mail, .pass {
      font-weight: 500;
      gap: .7em;
      display: flex;
      flex-direction: column;
      //row-gap: 1.5em;
      .input-container{
        border: 1px solid #272A2E;
        border-radius: 3px;
        padding: 0 1em;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        input{
          width: 60%;
          height: 100%;
        }
      }
      input::placeholder{
        color:var(--gray);
      }
    }
  }



}


//passed some style to deactive text
.deactive {
  font-size: clamp(10px, 1vw, 13px);
}
</style>