<template>
  <div class="container">

<TwoFa/>
    <RestePassword/>
    <LoginHistory/>
  </div>
</template>

<script>
import TwoFa from './2FA.vue'
import RestePassword from "@/components/Profile/Settings/RestePassword";
import LoginHistory from "@/components/Profile/Settings/LoginHistory";
export default {
  name: "Settings",
  components: {LoginHistory, RestePassword, TwoFa}

}
</script>

<style lang="scss" scoped>

.container{
  max-width: 1240px;
  padding: 4.5em 10px ;
  display: flex;
  flex-direction: column;
  gap: clamp(1em, 2vw, 2em);
}


</style>