<template>
<transition>
    <div class="modal-backdrop">
        <div class="modal">
           <div class="wrapper">
        <div class="pulse-spinner">
            <img class="pic" src="@/assets/Public/Support-icon2.png" alt="" width="80" height="80">
           
        </div>
    </div>
    </div>
<p>Loading</p>
    </div>
</transition>
</template>

<script>
export default {
    name: 'DeleteModal',
    components: {

    },

    data() {
        return {
            open: true,

        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        remove() {
            this.$emit('remove');
            this.$emit('close');
        }
    },
    created: function () {
        window.addEventListener("click", (event) => {
            if (!event.target.closest('.modal , .delete-with')) {
                this.$emit('close')
            } else {
                return true
            }

        })
    },
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {


.wrapper{
    width: 100%;
    height: 100vh;
    // background-color: #1A181B;
    display: grid;
    place-items: center;
    
}
.pulse-spinner{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 150px;
    border-radius: 50%;
   background: var(--primary);
   p{
       color: var(--white);
       font-size: 1.5rem;
       font-weight: bold;
   }
}
.pulse-spinner::after{
    content: "";
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: var(--primary);
    z-index: 1;
    position: absolute;
    animation: pulse 1s ease       infinite;
}
@keyframes pulse {
    from{
        transform: scale(1);
        opacity: 1;
    }
    to{
        transform: scale(2);
        opacity: 0;
    }
    
}


    display: flex;
    flex-grow: 1;
    max-width: 200px;
    max-height: 180px;
    // background: var(--primary);
    // box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 8px;
    position: relative;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
}









.pic{
  -webkit-animation: rotating 5s linear infinite;
  -moz-animation: rotating 5s linear infinite;
  -ms-animation: rotating 5s linear infinite;
  -o-animation: rotating 5s linear infinite;
  animation: rotating 5s linear infinite;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}










</style>
