<template>

  <div id="app">
    <navbar/>
 <transition 
 name="out-in"
 enter-active-class="animate__animated animate__fadeIn"
 leave-active-class = "animate__animated animate__fadeOut"
 >
     <router-view/>
 
 
 
 
 </transition>
    <LoadingModal v-if="state.loading"/>
  </div>
</template>

<script>
import Navbar from "./components/Public/Navbar.vue";
import LoadingModal from "@/components/tools/LoadingModal";
export default {
  components: {
    Navbar,LoadingModal
  },

};
</script>
<style lang="scss">


</style>
