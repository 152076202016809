<template>

  <div class="history">
    <p>Security Settings Logs</p>
    <p>For better security, we recommend always checking your login history and login attempt emails that are sent to your address.</p>
    <div class="table-container">
      <table>
        <tr class="tr-head">
          <td>Login Region</td>
          <td>Login Device</td>
          <td class="tablet">Time	</td>
          <td>IP Address</td>
          <td class="tablet">Status</td>

        </tr>
        <tr class="tr-body" v-for="(item ,index) in table">
          <td>{{item.region}}</td>
          <td>{{item.platform}}</td>
          <td class="tablet">{{item.time}}</td>
          <td>{{item.ip}}</td>
          <td class="tablet">{{item.status}}</td>

        </tr>
      </table>
    </div>
  </div>


</template>

<script>
export default {
  name: "LoginHistory",
  data(){
    return{
            table: [
        {
          time: "13:20:47 12/22/2022",
          ip: "(138.**.102)",
          platform: "macOS Chrome 100",
          status: "offline",
          region:'USA miami'

        },
              {
                time: "13:20:47 12/22/2022",
                ip: "(138.**.102)",
                platform: "macOS Chrome 100",
                status: "offline",
                region:'USA miami'

              },
              {
                time: "13:20:47 12/22/2022",
                ip: "(138.**.102)",
                platform: "macOS Chrome 100",
                status: "offline",
                region:'USA miami'

              },
              {
                time: "13:20:47 12/22/2022",
                ip: "(138.**.102)",
                platform: "macOS Chrome 100",
                status: "offline",
                region:'USA miami'

              },     {
                time: "13:20:47 12/22/2022",
                ip: "(138.**.102)",
                platform: "macOS Chrome 100",
                status: "offline",
                region:'USA miami'

              },     {
                time: "13:20:47 12/22/2022",
                ip: "(138.**.102)",
                platform: "macOS Chrome 100",
                status: "offline",
                region:'USA miami'

              },     {
                time: "13:20:47 12/22/2022",
                ip: "(138.**.102)",
                platform: "macOS Chrome 100",
                status: "offline",
                region:'USA miami'

              },     {
                time: "13:20:47 12/22/2022",
                ip: "(138.**.102)",
                platform: "macOS Chrome 100",
                status: "offline",
                region:'USA miami'

              },     {
                time: "13:20:47 12/22/2022",
                ip: "(138.**.102)",
                platform: "macOS Chrome 100",
                status: "offline",
                region:'USA miami'

              },















      ],
     }
  }
}
</script>

<style lang="scss" scoped>

td{
  font-size: clamp(13px,1vw,16px);
}
td:last-child{
  text-align: right;
}
td:nth-child(4){
  text-align: center;
}
.table-container{
  position: relative;
  max-height: 400px;
  min-height: 300px;
  overflow-y: scroll;

}
table{
  border-collapse: collapse;
}
.tr-head{
  padding: 0.5rem;
  position: sticky;
  top: 0;
  height: 40px;
  background: #1D1F22;
  z-index: 1;
}

.tr-body{

  height: 60px;
  border-bottom: 1px solid var(--gray);

  td{
    align-self: center;
    margin-top: 0.9rem;
  }
}

.history {
  max-width: 1200px;
  display:flex;
  flex-direction: column;
  gap: 16px;
  p:first-child{
    font-size: .9em;
    font-weight: bold;

  }
}
@media (max-width: 700px) {
  .tablet{
    display: none;
  }

}


</style>