<template>
  <div class="crypto-deposit">
    <div class="main">
      <div class="deposit-box">
        <div class="card-detail">
          <div class="currency">
            <span>Select currency</span>
            <div class="coin-box">
              <button class="drop-btn" @click="dropdown = !dropdown">
                <span class="coin-info">
                  <img
                    :src="require(`@/assets/Coins/${sourceCoin.code}.png`)"
                    width="20"
                    height="20"
                  />
                  {{ sourceCoin.code }}</span
                >

                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.49497 0.505025C1.22161 0.231658 0.778392 0.231658 0.505025 0.505025C0.231658 0.778392 0.231658 1.22161 0.505025 1.49497L1.49497 0.505025ZM7 7L6.50503 7.49497C6.77839 7.76834 7.22161 7.76834 7.49497 7.49497L7 7ZM13.495 1.49497C13.7683 1.22161 13.7683 0.778392 13.495 0.505025C13.2216 0.231658 12.7784 0.231658 12.505 0.505025L13.495 1.49497ZM0.505025 1.49497L6.50503 7.49497L7.49497 6.50503L1.49497 0.505025L0.505025 1.49497ZM7.49497 7.49497L13.495 1.49497L12.505 0.505025L6.50503 6.50503L7.49497 7.49497Z"
                    fill="#7A8083"
                  />
                </svg>

                <!--      <img src="@/assets/Public/selector.svg" alt="">-->
              </button>
              <div class="dropdown" v-if="dropdown">
                <span
                  v-for="(coin, index) in coins.filter(
                    (coin) => coin.coin !== 'TOMAN'
                  )"
                  :key="index"
                >
                  <img
                    :src="require(`@/assets/Coins/${coin.code}.png`)"
                    alt=""
                    width="18"
                    height="18"
                  />
                  <span
                    @click="
                      () => {
                        getQR(coin.coin);
                        isNetSelect = true;
                        dropdown = !dropdown;
                        sourceCoin = coin;
                      }
                    "
                  >
                    {{ coin.code }}
                    <!--                  <img-->
                    <!--                      :src="require(`@/assets/Coins/${$coin[coin.englishName].code}.png`)"-->
                    <!--                    width="20"-->
                    <!--                    height="20"-->
                    <!--                  />-->
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="card-section">
            <span>Card Details</span>
            <div class="cards-info">
              <div class="name">
                <input type="text" placeholder="Name on Card" />
              </div>
              <div class="number">
                <input type="text" placeholder="Card Number" />
                <img src="@/assets/profile/visa.png" alt="" />
                <img src="@/assets/profile/master.png" alt="" />
              </div>
              <div class="cvv">
                <input type="text" placeholder="Expiry date" />
                <input type="text" placeholder="Security Code (CVV)" />
              </div>
            </div>
          </div>
        </div>
        <div class="deposit">
          <div class="">
            <p>You receive:</p>
            <h4>120.79 USD</h4>
            <span class="trans"
              ><span class="gray">Transaction Fee: </span> 2.21 USD</span
            >
          </div>
          <button @click ='isOrder=true' class="btn-primary">Deposit</button>
        </div>
      </div>
    </div>

    <div class="table">
      <p>Recent Deposits</p>

      <div class="table-container">
        <table>
          <tr class="tr-head">
            <td class="tablet">Time</td>
            <td>Type</td>
            <td>Amount</td>
            <td class="tablet">Deposit Wallet</td>
            <td class="mobile">Destination</td>
            <td class="tablet">TxID</td>
            <td>Status</td>
          </tr>
          <tr class="tr-body" v-for="(item, index) in tableContents">
            <td class="tablet">{{ item.time }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.amount }}</td>
            <td class="tablet">{{ item.DepositWallet }}</td>
            <td class="mobile">{{ item.Destination }}</td>
            <td class="tablet">{{ item.TxID.slice(0, 9) + "..." }}</td>
            <td
              :class="{
                red: item.Status === 'Failed',
                green: item.Status === 'Completed',
                yellow: item.Status === 'Pending',
              }"
            >
              {{ item.Status }}
            </td>
          </tr>
        </table>
      </div>
    </div>


    <shop-modal v-if="isOrder" @close="isOrder=false"/>
  </div>



</template>

<script>

import ShopModal from '@/components/Profile/Trade/RightCol/shop/ShopModal.vue';

export default {
  name: "forSpot",
  components: { ShopModal },
  data() {
    return {
      dropdown: false,
      isOrder:false,
      isQRCODE: false,
      selectNet: "",
      networkSelector: false,
      coin: "",
      coins: [],
      isSource: false,
      sourceCoin: "BTC",
      isGuide: true,
      address: "",
      qrCode: "",
      isNetSelect: false,
      tag: "",
      network: [
        {
          title: "BTC",
        },
        {
          title: "BEP",
        },
        {
          title: "ERC20",
        },
        {
          title: "TRC20",
        },
      ],
      tableContents: [
        {
          time: "2019-01-01",
          type: "BTC/USD",
          amount: "0.0001",
          DepositWallet: "887654156213216",
          Destination: "324ion425589X2",
          TxID: "0x8d12a197cb00d4747a1fe03395095ce2a5cc6819",
          Status: "Pending",
        },
        {
          time: "2019-01-01",
          type: "BCH/USD",
          amount: "0.0001",
          DepositWallet: "887654156213216",
          Destination: "324ion425589X2",
          TxID: "0x8d12a197cb00d4747a1fe03395095ce2a5cc6819",
          Status: "Completed",
        },
        {
          time: "2019-01-01",
          type: "OMANI/USD",
          amount: "0.0001",
          DepositWallet: "887654156213216",
          Destination: "324ion425589X2",
          TxID: "0x8d12a197cb00d4747a1fe03395095ce2a5cc6819",
          Status: "Failed",
        },
        {
          time: "2019-01-01",
          type: "BTC/USD",
          amount: "0.0001",
          DepositWallet: "887654156213216",
          Destination: "324ion425589X2",
          TxID: "0x8d12a197cb00d4747a1fe03395095ce2a5cc6819",
          Status: "Pending",
        },
        {
          time: "2019-01-01",
          type: "BCH/USD",
          amount: "0.0001",
          DepositWallet: "887654156213216",
          Destination: "324ion425589X2",
          TxID: "0x8d12a197cb00d4747a1fe03395095ce2a5cc6819",
          Status: "Completed",
        },
        {
          time: "2019-01-01",
          type: "OMANI/USD",
          amount: "0.0001",
          DepositWallet: "887654156213216",
          Destination: "324ion425589X2",
          TxID: "0x8d12a197cb00d4747a1fe03395095ce2a5cc6819",
          Status: "Failed",
        },
        {
          time: "2019-01-01",
          type: "BTC/USD",
          amount: "0.0001",
          DepositWallet: "887654156213216",
          Destination: "324ion425589X2",
          TxID: "0x8d12a197cb00d4747a1fe03395095ce2a5cc6819",
          Status: "Pending",
        },
        {
          time: "2019-01-01",
          type: "BCH/USD",
          amount: "0.0001",
          DepositWallet: "887654156213216",
          Destination: "324ion425589X2",
          TxID: "0x8d12a197cb00d4747a1fe03395095ce2a5cc6819",
          Status: "Completed",
        },
        {
          time: "2019-01-01",
          type: "OMANI/USD",
          amount: "0.0001",
          DepositWallet: "887654156213216",
          Destination: "324ion425589X2",
          TxID: "0x8d12a197cb00d4747a1fe03395095ce2a5cc6819",
          Status: "Failed",
        },
      ],

      guide: [
        {
          number: 1,
          title: "Copy Address",
          content:
            "Choose the crypto and its network on this page, and copy the deposit address.",
        },
        {
          number: 2,
          title: "Initiate a withdrawal",
          content: "Initiate a withdrawal on the withdrawal platform.",
        },
        {
          number: 3,
          title: "Network confirmation",
          content: "Wait for the blockchain network to confirm your transfer.",
        },
        {
          number: 4,
          title: "Deposit successful",
          content:
            "After the network confirmation, Binance will credit the crypto for you.",
        },
      ],
    };
  },
  methods: {
    async getCoinsInfoFromServer() {
      let coins = await this.$axios("/coins/details");
      this.coins = coins.content;
      console.log(this.coins);
      this.sourceCoin = coins.content[0];
    },
    async getWallets() {
      this.state.loading = true;
      // const coinsList="ETHEREUM,BITCOIN_CASH,TRON,RIPPLE,TETHER,TOMAN,BITCOIN"
      this.loading = true;
      const [res] = await this.$http.get(
        "/wallets/customer-addresses?walletType=P2P"
      );

      if (res) {
        let entireRes = Object.entries(res);
        const sortCoins = this.$walletSortCoins;
        entireRes.sort(
          (a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0])
        );
        this.wallets = entireRes;
        // this.tomancash = res["TOMAN"].credit;
        console.log("response", res);
      }
    },
    async getQR(coin) {
      this.state.loading = true;
      const token = this.selectNet;
      let relatedCoin = this.$coin2Snake[this.sourceCoin];
      console.log("related", relatedCoin);
      let params = {
        relatedCoin: coin,
      };
      if (this.sourceCoin[0] === "TETHER") {
        params.tokenType = token;
      }
      const res = await this.$axios(
        "/wallets/customer/wallet-address?walletType=" +
          "P2P" +
          "&tokenType=" +
          token,
        {
          params,
        }
      ).catch(() => {
        // this.closeModale()
      });
      if (res) {
        this.address = res.baseDTO.address;
        this.qrCode = res.baseDTO.qrCode;
        this.tag = res.baseDTO.tag;
      }
    },
  },
  mounted() {
    this.getCoinsInfoFromServer();
    this.getWallets();
    this.selectNet = this.network[0].title;
    this.getQR("BITCOIN");
  },
};
</script>

<style lang="scss" scoped>
.green {
  color: green !important;
}

.red {
  color: red !important;
}

.yellow {
  color: #a1792b !important;
}

.drop-btn {
  background: #1d1f22;
  border: 1px solid #272a2e;
  border-radius: 3px;
}

.crypto-deposit {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //align-items: center;
  .deposit-box {
    //position: relative;
    //max-width: 550px;
    //width: 100%;
    //min-width: 270px;
    //align-self: center;
    //display: flex;
    //flex-direction: column;
    //background: #181a1d;
    //border-radius: 8px;
    display: flex;
    flex-direction: column;
    max-width: 550px;
    background: #181a1d;
    align-self: center;
    //padding: 0 clamp(24px, 1.2vw, 32px);
    gap: 32px;
    .card-detail {
      position: relative;
      //max-width: 550px;
      width: 100%;
      min-width: 270px;
      align-self: center;
      display: flex;
      flex-direction: column;
      //background: #181a1d;
      border-radius: 8px;
      padding: 0 clamp(18px, 1.2vw, 24px);
      gap: 32px;
    }
  }
}

.dropdown {
  z-index: 3;

  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5em;
  }

  position: absolute;
  //top: 3.4em;
  //left: 0;
  //left: 67%;
  //width: 120px;
  left: 0;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  overflow: scroll;
  max-height: 200px;
  background: #1d1f22;
  border: 1px solid #272a2e;
  border-radius: 3px;
}

.drop-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;

  .coin-info {
    display: flex;
    align-items: center;
    gap: 12px;
    height: 100%;
  }
}

.currency,
.card-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.currency {
  margin-top: clamp(12px, 1.2vw, 24px);
}
.cards-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.name,
.number {
  background: #1d1f22;
  border: 1px solid #272a2e;
  border-radius: 3px;
  height: 50px;
  padding: 0 16px;
  align-items: center;
  input {
    height: 100%;
  }
}

.number {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.cvv {
  display: flex;
  gap: 24px;
  input {
    background: #1d1f22;
    padding: 0 16px;
    border: 1px solid #272a2e;
    border-radius: 3px;
    height: 50px;
    flex: 1 1 100px;
  }
}

.deposit {
  background: #1f2125 !important;
  align-self: flex-start;
  width: 100%;
  flex-direction: row !important;
  display: flex;
  gap: 30px;
  padding: clamp(30px, 1.2vw, 40px);
  justify-content: space-between;
  align-items: center;
}

.table-container {
  max-height: 300px;
  overflow: scroll;

  table {
    td {
      height: 40px;
      font-size: clamp(8px, 1.2vw, 12px);
      color: #7a8083;
    }
  }
}

.gray {
  color: #7a8083;
  font-size: clamp(8px, 1.2vw, 12px);
}
.trans {
  font-size: clamp(10px, 1.2vw, 12px);
}

@media (max-width: 768px) {
  .tablet {
    display: none;
  }
}

@media (max-width: 576px) {
  .mobile {
    display: none;
  }
}
</style>