<template>
  <div class="tfa">
    <p>2 Factor Authentication</p>
    <p>
      Used for verification in account login, withdrawal, API creation, and
      other functions.
    </p>
    <div class="box-container">
      <div class="box">
        <div class="">
          <input type="radio" />
        </div>
        <div class="">
          <img
            src="../../../assets/profile/ga.svg"
            alt=""
            width="48"
            height="48"
          />
        </div>
        <div class="texts">
          <p class="title">Google Verification</p>
          <p class="text">Recieve code on Google Authenticator App</p>
        </div>
      </div>
      <div class="box">
        <div class="">
          <input type="radio" />
        </div>
        <div class="sms">
          <img
            
            src="../../../assets/profile/sms.svg"
            alt=""
            width="48"
            height="48"
          />
        </div>
        <div class="texts">
          <p class="title">SMS</p>
          <p class="text">Recieve OTP via Phone Number</p>
        </div>
      </div>
      <div class="box">
        <div class=""><input type="radio" /></div>
        <div class="texts">
          <img
            src="../../../assets/profile/mail.svg"
            alt=""
            width="48"
            height="48"
          />
        </div>
        <div class="texts">
          <p class="title">Email</p>
          <p class="text">Recieve OTP via Email Address</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "2FA",
};
</script>

<style lang="scss" scoped>
img {
  align-self: center;
}
#sms {
  align-self: center;
}
.tfa {
  display: flex;
  flex-direction: column;
  gap:12px ;

  p:first-child{
    font-size: .9em;
    font-weight: bold;
    margin-bottom: 1em;
  }

  
}
.box-container {

  display: flex;
  flex-direction: row;
  gap: clamp(1em, 2vw, 1.3em);
  flex-wrap: wrap;
}
.box {
  display: flex;
  gap: 24px;
  flex: 1 1 130px;
  max-width: 381px;
  justify-content: center;
  background: #1d1f22;
  height: 131px;
  padding: clamp(0.6em, 1vw, 1.1em);
  border-radius: 12px;
div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;

}
}
.sms {
  display: flex;
  justify-content: center;
  align-items: center;
}
.texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  gap: 0.5rem;
  .title {
    font-size: clamp(14px, 1.3vw, 18px);
  }
}
</style>
