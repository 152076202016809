var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"crypto-deposit"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"main-box"},[_c('div',{staticClass:"deposit-box"},[_c('div',{},[_c('span',[_vm._v("Select Coin:")]),_c('div',{staticClass:"coin-box"},[_c('button',{staticClass:"drop-btn",on:{"click":function($event){_vm.dropdown = !_vm.dropdown}}},[_c('span',{staticClass:"coin-info"},[_c('img',{attrs:{"src":require(("@/assets/Coins/" + (_vm.sourceCoin.code) + ".png")),"width":"20","height":"20"}}),_vm._v(" "+_vm._s(_vm.sourceCoin.code))]),_c('svg',{attrs:{"width":"14","height":"8","viewBox":"0 0 14 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.49497 0.505025C1.22161 0.231658 0.778392 0.231658 0.505025 0.505025C0.231658 0.778392 0.231658 1.22161 0.505025 1.49497L1.49497 0.505025ZM7 7L6.50503 7.49497C6.77839 7.76834 7.22161 7.76834 7.49497 7.49497L7 7ZM13.495 1.49497C13.7683 1.22161 13.7683 0.778392 13.495 0.505025C13.2216 0.231658 12.7784 0.231658 12.505 0.505025L13.495 1.49497ZM0.505025 1.49497L6.50503 7.49497L7.49497 6.50503L1.49497 0.505025L0.505025 1.49497ZM7.49497 7.49497L13.495 1.49497L12.505 0.505025L6.50503 6.50503L7.49497 7.49497Z","fill":"#7A8083"}})])]),(_vm.dropdown)?_c('div',{staticClass:"dropdown"},_vm._l((_vm.coins.filter(function (coin){ return coin.coin!=='TOMAN'; })),function(coin,index){return _c('span',{key:index},[_c('img',{attrs:{"src":require(("@/assets/Coins/" + (coin.code) + ".png")),"alt":"","width":"18","height":"18"}}),_c('span',{on:{"click":function () {
                      _vm.getQR(coin.coin)
                      _vm.isNetSelect =true;
                      _vm.dropdown = !_vm.dropdown;
                      _vm.sourceCoin = coin;

                    }}},[_vm._v(" "+_vm._s(coin.code)+" ")])])}),0):_vm._e()])]),_vm._m(0),_c('div',{},[_c('span',[_vm._v("Network:")]),_c('div',{staticClass:"networks"},[_c('button',{staticClass:"drop-btn",on:{"click":function($event){_vm.networkSelector = !_vm.networkSelector}}},[_c('span',[_vm._v(_vm._s(_vm.selectNet))]),_c('svg',{attrs:{"width":"14","height":"8","viewBox":"0 0 14 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.49497 0.505025C1.22161 0.231658 0.778392 0.231658 0.505025 0.505025C0.231658 0.778392 0.231658 1.22161 0.505025 1.49497L1.49497 0.505025ZM7 7L6.50503 7.49497C6.77839 7.76834 7.22161 7.76834 7.49497 7.49497L7 7ZM13.495 1.49497C13.7683 1.22161 13.7683 0.778392 13.495 0.505025C13.2216 0.231658 12.7784 0.231658 12.505 0.505025L13.495 1.49497ZM0.505025 1.49497L6.50503 7.49497L7.49497 6.50503L1.49497 0.505025L0.505025 1.49497ZM7.49497 7.49497L13.495 1.49497L12.505 0.505025L6.50503 6.50503L7.49497 7.49497Z","fill":"#7A8083"}})])]),(_vm.networkSelector)?_c('div',{staticClass:"network"},_vm._l((_vm.network),function(net,index){return _c('span',{key:index,on:{"click":function () {
                      _vm.networkSelector = !_vm.networkSelector;
                      _vm.selectNet = net.title;
                    }}},[_vm._v(" "+_vm._s(net.title))])}),0):_vm._e()])]),_c('div',{staticClass:"address"},[_vm._m(1),_c('div',{staticClass:"input-container"},[_c('input',{attrs:{"type":"text","placeholder":"Address"}}),_c('span',[_vm._v(_vm._s(_vm.sourceCoin.code))])])]),_vm._m(2)]),_vm._m(3)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"address"},[_c('span',[_vm._v("Address")]),_c('div',{staticClass:"input-container"},[_c('input',{attrs:{"type":"text","placeholder":"Address"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"titles"},[_c('span',[_vm._v("Amount")]),_c('span',{staticClass:"note"},[_vm._v(" 8,000,000.00 BUSD/8,000,000.00 BUSD "),_c('span',{staticClass:"gray"},[_vm._v("24h remaining limit")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{attrs:{"action":""}},[_c('div',{staticClass:"select-withdraw-type"},[_c('div',{staticClass:"row"},[_c('span',[_c('label',{staticClass:"form-control"},[_c('input',{attrs:{"type":"radio","name":"radio"}}),_vm._v(" Spot Wallet ")])]),_vm._v(" 0.123431234 ")]),_c('div',{staticClass:"row"},[_c('span',[_c('label',{staticClass:"form-control"},[_c('input',{attrs:{"type":"radio","name":"radio"}}),_vm._v(" Funding Wallet ")])]),_vm._v(" 0.123431234 ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"deposit"},[_c('div',{},[_c('p',[_vm._v("Receive Amount:")]),_c('h4',[_vm._v("120.79 USD")]),_c('span',{staticClass:"gray"},[_vm._v("0.0005 BTC network fee included")])]),_c('button',{staticClass:"btn-primary"},[_vm._v("Withdraw")])])}]

export { render, staticRenderFns }