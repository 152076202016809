//import vue and vue-router
import Vue from "vue";
import VueRouter from "vue-router";
//this is where we import our components for Routing
import Home from "./../components/Home-page/Home.vue";
import Aboutus from "@/components/Public-pages/Aboutus";
import Coinslist from "@/components/Public-pages/Coinslist";
import Fees from "@/components/Public-pages/Fees";
import Guide from "@/components/Public-pages/Guide";
import Market from "@/components/Public-pages/Market";
import Terms from "@/components/Public-pages/Terms";
import Authentication from "@/components/Authentication/Authentication";
import Profile from "@/components/Profile/Profile";
import Dashboard from "@/components/Profile/Dashboard/Dashboard";
import Account from "@/components/Profile/Account/Account";
import Wallet from "@/components/Profile/Wallet/Wallet";
import History from "@/components/Profile/History/History";
import Invite from "@/components/Profile/Invite/Invite";
import Notif from "@/components/Profile/Notifications/Notif";
import OTC from "@/components/Profile/OTC/OTC";


import Settings from "@/components/Profile/Settings/Settings";
import P2P from "@/components/Profile/Trade/P2P";
import KYC from "@/components/Authorization/Account";
import Cryptodiposit from "@/components/Profile/Wallet/Fiat";
import Cryptowithdrawl from "@/components/Profile/Wallet/over";
import Tomandiposit from "@/components/Profile/Wallet/Deposit";
import Tomanwithdrawl from "@/components/Profile/Wallet/Withdraw";
import Fino from "@/components/Authorization/Fiontech";
const that = Vue.prototype;
//this is where our router is
Vue.use(VueRouter);

const routes = [
  //home path
  { path: "*", redirect: "/" },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    name:'HomeOTC',
    path: "/OTC",
    component:()=> import('@/components/Public-pages/OTC')
  },
  //Authorization is here
  // {
  //   path: "/kyc",
  //   name: "KYC",
  //   component: KYC,
  // },
  //p2p is here & there is no trade page in profile page
  {
    path: "/trade",
    redirect: "/trade/TETHER/BITCOIN",
    name: "Trade",
    component: P2P,
  },
  {
    path: "trade/TETHER/TETHER",
    redirect: "/trade/TOMAN/TETHER",
  },
  {
    path: "trade/TOMAN/TOMAN",
    redirect: "/trade/TOMAN/TETHER",
  },
  {
    path: "/trade/:tradeTo/:tradeFrom",
    component: P2P,
    name: "trade",
    props: true,
  },

  //public pages are here
  {
    path: "/about-us",
    name: "Aboutus",
    component: Aboutus,
  },
  {
    path: "/coins",
    name: "Coins",
    component: Coinslist,
  },
  {
    path: "/fees",
    name: "Fees",
    component: Fees,
  },
  {
    path: "/guide",
    name: "Guide",
    component: Guide,
  },
  {
    path: "/market",
    name: "Market",
    component: Market,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/fino",
    name: "Fino",
    component: Fino,
  },
  //authentication pages are here
  {
    path: "/auth",
    name: "Auth",
    component: Authentication,
  },
  {
    path: "/login",
    name: "Login",
    component: Authentication,
  },
  {
    path: "/sign-up",
    name: "Signup",
    component: Authentication,
  },
  {
    path: "/otpSignup",
    name: "SignupOTP",
    component: Authentication,
  },
  {
    path: "/otp",
    name: "OTP",
    component: Authentication,
  },
  {
    path: "/otp-mobile",
    name: "OTPMobile",
    component: Authentication,
  },
  {
    path: "/forget",
    name: "Forget",
    component: Authentication,
  },
  {
    path: "/newpass",
    name: "Newpass",
    component: Authentication,
  },
  //profile routes
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: Dashboard,
      },
      // //kyc button is placed in dashboard
      {
        path: 'kyc',
        name: 'KYC',
        component: KYC,
      },
      {
        path: "account",
        name: "Account",
        component: Account,
      },
      // {
      //   path: 'kyc',
      //   name: 'KYC',
      //   component: KYC
      // },
      {
        path: "history",
        name: "History",
        component: History,
      },
      {
        path: "invite",
        name: "Invite",
        component: Invite,
      },
      {
        path: "notif",
        name: "Notif",
        component: Notif,
      },
      {
        path: "otc",
        name: "OTC",
        component: OTC,
      },
      {
        path: "settings",
        name: "Settings",
        component: Settings,
      },
      {
        path: "wallet",
        name: "Wallet",
        component: Wallet,
        children:[
          {
            path: '',
            name:'WalletOverview',
            component:()=>import('../components/Profile/Wallet/over')
          },
          {
            path: 'fiat',
            name:"Fiat",
            component:()=>import('../components/Profile/Wallet/Fiat')
          },

          {
            path: 'deposit',
            name:"Deposit",
            component:()=>import('../components/Profile/Wallet/Deposit')
          },
          {
            path: 'withdraw',
            name:"Withdraw",
            component:()=>import('../components/Profile/Wallet/Withdraw')
          },
          {
            path: 'walleth',
            name: 'WalletHistory',
            component:()=>import('../components/Profile/Wallet/WalletHistory')
          }
        ]


      },
      //diposit and withdrawl in wallet are here

    ],
  },
];

//this is vue router config
//and make routerview in History mode
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

//the routes that disabled in default
// const disabledRoutes = ["otc", "Trade"];
/*
 * this is the guard for routes that shouldn't
 * load without Login
 * remember to use your routes with their names
 * */
const forLogin = [
  "Profile",
  "Dashboard",
  "KYC",
  "Trade",
  "trade",
  "Account",
  "History",
  "Wallet",
  "Invite",
  "Settings",
  "Notif",
];
//Users that did not authorized must not access these pages
const unAuthorUser = ["History", "Wallet", "dashboard", "Trade", "trade"];
const otp = [
  // "SendPhone", "SendMail"
];
const auth = [
  // 'Login',
  // "SignUp",
  // "Forget",
];

router.beforeEach(async (to, from, next) => {
  that.state.loading = true;

  if (!that.state.coins) {
    await that.$getCoins();
  }

  if (that.state.token) {
    if (!that.state.gotten) {
      that.state.loading = true;
      await that.$getuserInfo();
      that.state.loading = true;
      await that.$getFees();
    }

    that.state.loading = true;
    if (that.state.userInfo.supervisor && disabledRoutes.includes(to.name)) {
      that.$error(
        "",
        "با عرض پوزش قابلیت مورد نظر برای بازار گردان غیر فعال است"
      );
      next(from);
    }
    if (from.name === "Blog") {
      await window.open(to.fullPath, "_self");
    } else if (otp.includes(to.name) || auth.includes(to.name)) {
      next({ name: "HomePage" });
    } else if (to.name === "Trade") {
      if (!to.params.tradeFrom || !to.params.tradeTo) {
        next("/profile/trade/TOMAN/BITCOIN");
      } else {
        try {
          that.state.loading = true;
          await that.$axios(
            `/markets/${to.params.tradeFrom}_${to.params.tradeTo}`
          );
          next();
        } catch (e) {
          if (e.response.data.message === "MARKET_IS_NOT_ACTIVE") {
            if (otp.includes(from.name)) {
              next({ name: "HomePage" });
            } else {
              await that.$error("بازار غیرفعال است");
              next(from);
            }
          }
        }
      }
    } else {
      if (that.state.userInfo.authorized) {
        next();
      } else {
        if (otp.includes(from.name) && to.name !== "Account") {
          next({ name: "Account" });
        } else if (unAuthorUser.includes(to.name)) {
          that.state.loading = false;
          const res = await that.$error(
            "عدم دسترسی",
            "برای دسترسی به این بخش نیاز است ابتدا حساب شما تکمیل و تایید شود",
            "error",
            "حساب کاربری"
          );
          if (res.isConfirmed) {
            next("/profile/kyc");
          } else {
            next(from);
          }
        } else next();
      }
    }
  } else {
    if (
      (otp.includes(to.name) && !Vue.$cookies.isKey("username")) ||
      forLogin.includes(to.name)
    )
      next({ name: "Login" });
    else {
      next();
    }
  }
  that.state.loading = false;
});

export default router;
