<template>
  <div class="dashboard-info">
    <div class="referral-box">
      <p class="data">Referral Status</p>
      <div class="box">
        <img src="../../../assets/profile/reward.svg" alt="" />
        <h6>{{ profit }} USDT</h6>
        <p @click.prevent="$go('/profile/Invite')">{{ invited }} Friends</p>
        <button class="btn-white">Send Invitation</button>
      </div>
    </div>
    <div class="login-table">
      <p class="data">Security Settings Logs</p>
      <div class="table-container">
        <table>
          <tr class="tr-head">
            <td>Login Region</td>
            <td>Login Device</td>
            <td class="tablet">Time</td>
            <td>IP Address</td>
            <td class="tablet">Status</td>
          </tr>
          <tr class="tr-body" v-for="(item, index) in table" :key="index">
            <td>{{ item.region }}</td>
            <td>{{ item.platform }}</td>
            <td class="tablet">{{ item.time }}</td>
            <td>{{ item.ip }}</td>
            <td class="tablet">{{ item.status }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      profit: "",
      invited: "",
      table: [
        {
          time: "13:20:47 12/22/2022",
          ip: "(138.**.102)",
          platform: "macOS Chrome 100",
          status: "offline",
          region: "USA miami",
        },
        {
          time: "13:20:47 12/22/2022",
          ip: "(138.**.102)",
          platform: "macOS Chrome 100",
          status: "offline",
          region: "USA miami",
        },
        {
          time: "13:20:47 12/22/2022",
          ip: "(138.**.102)",
          platform: "macOS Chrome 100",
          status: "offline",
          region: "USA miami",
        },
        {
          time: "13:20:47 12/22/2022",
          ip: "(138.**.102)",
          platform: "macOS Chrome 100",
          status: "offline",
          region: "USA miami",
        },
        {
          time: "13:20:47 12/22/2022",
          ip: "(138.**.102)",
          platform: "macOS Chrome 100",
          status: "offline",
          region: "USA miami",
        },
        {
          time: "13:20:47 12/22/2022",
          ip: "(138.**.102)",
          platform: "macOS Chrome 100",
          status: "offline",
          region: "USA miami",
        },
        {
          time: "13:20:47 12/22/2022",
          ip: "(138.**.102)",
          platform: "macOS Chrome 100",
          status: "offline",
          region: "USA miami",
        },
        {
          time: "13:20:47 12/22/2022",
          ip: "(138.**.102)",
          platform: "macOS Chrome 100",
          status: "offline",
          region: "USA miami",
        },
        {
          time: "13:20:47 12/22/2022",
          ip: "(138.**.102)",
          platform: "macOS Chrome 100",
          status: "offline",
          region: "USA miami",
        },
      ],
    };
  },
  methods: {
    async getCode() {
      const res = await this.$axios("/users/invites");
      console.table(res);
      // this.refferalsCode = res.baseDTO.referralCode
      // this.reffreals = this.reffreals + this.refferalsCode
      this.invited = res.baseDTO.countInvitees;
      this.profit = res.baseDTO.profit;
    },
  },
  mounted() {
    this.getCode();
  },
};
</script>

<style lang="scss" scoped>
.data {
  font-size: clamp(18px, 2vw, 24px);
}
.dashboard-info {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  .referral-box {
    max-width: 392px;
    display: flex;
    flex-direction: column;
    gap: 36px;
    flex: 1 1 auto;
    .box {
      h6 {
        font-size: clamp(18px, 2vw, 24px);
      }
      background: #1d1f22;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 30px;
      gap: 30px;
    }
  }
  .login-table {
    flex: 2 2 auto;
    max-width: 816px;
  }
}
button {
  width: 168px;
  min-height: 40px;
  color: #a1792b;
}



td{
  font-size: clamp(12px,1vw,14px);
}
td:last-child{
  text-align: right;
}
td:nth-child(4){
  text-align: center;
}
.table-container{
  position: relative;
  max-height: 400px;
  min-height: 300px;
  overflow-y: scroll;

}
table{
  border-collapse: collapse;
}
.tr-head{
  padding: 0.5rem;
  position: sticky;
  top: 0;
  height: 40px;
  background: #1D1F22;
  z-index: 1;
  td{ 
    font-size: clamp(10px,1vw,12px);
    color: #7A8083;
  }
}

.tr-body{

  height: 40px;
  border-bottom: 1px solid var(--gray);

  td{
    align-self: center;
    margin-top: 0.9rem;
  }
}

.history {
  max-width: 1200px;
  display:flex;
  flex-direction: column;
  gap: 16px;
  p:first-child{
    font-size: .9em;
    font-weight: bold;

  }
}
@media (max-width: 700px) {
  .tablet{
    display: none;
  }

}





</style>
