<template>
  <div class="crypto-deposit">
    <div class="main">
   <div class="main-box">
     <div class="deposit-box">
       <div class="">
         <span>Select Coin:</span>
         <div class="coin-box">
           <button class="drop-btn" @click="dropdown = !dropdown">
              <span class="coin-info">
                <img
                    :src="require(`@/assets/Coins/${sourceCoin.code}.png`)"
                    width="20"
                    height="20"
                />
                {{ sourceCoin.code }}</span
              >

             <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                   d="M1.49497 0.505025C1.22161 0.231658 0.778392 0.231658 0.505025 0.505025C0.231658 0.778392 0.231658 1.22161 0.505025 1.49497L1.49497 0.505025ZM7 7L6.50503 7.49497C6.77839 7.76834 7.22161 7.76834 7.49497 7.49497L7 7ZM13.495 1.49497C13.7683 1.22161 13.7683 0.778392 13.495 0.505025C13.2216 0.231658 12.7784 0.231658 12.505 0.505025L13.495 1.49497ZM0.505025 1.49497L6.50503 7.49497L7.49497 6.50503L1.49497 0.505025L0.505025 1.49497ZM7.49497 7.49497L13.495 1.49497L12.505 0.505025L6.50503 6.50503L7.49497 7.49497Z"
                   fill="#7A8083"/>
             </svg>


             <!--      <img src="@/assets/Public/selector.svg" alt="">-->
           </button>
           <div class="dropdown" v-if="dropdown">
              <span v-for="(coin, index) in coins.filter(coin=> coin.coin!=='TOMAN')" :key="index">
                          <img :src="require(`@/assets/Coins/${coin.code}.png`)" alt="" width="18" height="18">
                <span

                    @click="
                    () => {
                      getQR(coin.coin)
                      isNetSelect =true;
                      dropdown = !dropdown;
                      sourceCoin = coin;

                    }"


                >

                  {{ coin.code }}
                  <!--                  <img-->
                  <!--                      :src="require(`@/assets/Coins/${$coin[coin.englishName].code}.png`)"-->
                  <!--                    width="20"-->
                  <!--                    height="20"-->
                  <!--                  />-->
                </span>
              </span>
           </div>


         </div>
       </div>
       <div class="address">
         <span>Address</span>
         <div class="input-container">
           <input type="text" placeholder="Address">
         </div>
       </div>
       <div class="">
         <span>Network:</span>
         <div class="networks">
           <button class="drop-btn" @click="networkSelector = !networkSelector">
             <span>{{ selectNet }}</span>

             <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                   d="M1.49497 0.505025C1.22161 0.231658 0.778392 0.231658 0.505025 0.505025C0.231658 0.778392 0.231658 1.22161 0.505025 1.49497L1.49497 0.505025ZM7 7L6.50503 7.49497C6.77839 7.76834 7.22161 7.76834 7.49497 7.49497L7 7ZM13.495 1.49497C13.7683 1.22161 13.7683 0.778392 13.495 0.505025C13.2216 0.231658 12.7784 0.231658 12.505 0.505025L13.495 1.49497ZM0.505025 1.49497L6.50503 7.49497L7.49497 6.50503L1.49497 0.505025L0.505025 1.49497ZM7.49497 7.49497L13.495 1.49497L12.505 0.505025L6.50503 6.50503L7.49497 7.49497Z"
                   fill="#7A8083"/>
             </svg>
             <!--      <img src="@/assets/Public/selector.svg" alt="">-->
           </button>
           <div class="network" v-if="networkSelector">
              <span v-for="(net,index) in network" :key="index" @click="
                    () => {
                      networkSelector = !networkSelector;
                      selectNet = net.title;
                    }
                  ">
                 {{ net.title }}</span>


           </div>
         </div>


       </div>

       <div class="address">
         <div class="titles">
           <span>Amount</span>
           <span class="note"> 8,000,000.00 BUSD/8,000,000.00 BUSD <span class="gray">24h remaining limit</span></span>
         </div>
         <div class="input-container">
           <input type="text" placeholder="Address">
           <span>{{ sourceCoin.code }}</span>
         </div>
       </div>
       <form action="">
       <div class="select-withdraw-type">
         <div class="row">
<span>

    <label class="form-control">
    <input type="radio" name="radio" />
    Spot Wallet
  </label>


</span>
           0.123431234

         </div>
         <div class="row">
<span>
    <label class="form-control">
    <input type="radio" name="radio" />
    Funding Wallet
  </label>




</span>
           0.123431234

         </div>
       </div>
       </form>
     </div>

     <div class="deposit">
       <div class="">
         <p>Receive Amount:</p>
         <h4>120.79 USD</h4>
         <span class="gray">0.0005 BTC network fee included</span>
       </div>
       <button class="btn-primary">Withdraw</button>
     </div>
   </div>
    </div>


  </div>
</template>

<script>


export default {
  name: "withdraw",

  data() {
    return {
      dropdown: false,
      isQRCODE: false,
      selectNet: '',
      networkSelector: false,
      coin: "",
      coins: [],
      isSource: false,
      sourceCoin: "BTC",
      isGuide: true,
      address: "",
      qrCode: '',
      isNetSelect: false,
      tag: '',
      network: [
        {
          title: "BTC",
        },
        {
          title: "BEP",
        },
        {
          title: "ERC20",
        },
        {
          title: "TRC20",
        },
      ],
    };
  },
  methods: {
    async getCoinsInfoFromServer() {
      let coins = await this.$axios("/coins/details");
      this.coins = coins.content;
      console.log(this.coins)
      this.sourceCoin = coins.content[0]

    },
    async getWallets() {
      this.state.loading = true;
      // const coinsList="ETHEREUM,BITCOIN_CASH,TRON,RIPPLE,TETHER,TOMAN,BITCOIN"
      this.loading = true;
      const [res] = await this.$http.get(
          "/wallets/customer-addresses?walletType=P2P"
      );


      if (res) {
        let entireRes = Object.entries(res);
        const sortCoins = this.$walletSortCoins;
        entireRes.sort(
            (a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0])
        );
        this.wallets = entireRes;
        // this.tomancash = res["TOMAN"].credit;
        console.log("response", res);


      }
    },

  },
  mounted() {
    this.getCoinsInfoFromServer();
    this.getWallets()
    this.selectNet = this.network[0].title;
  },
};
</script>

<style lang="scss" scoped>

input[type="radio"] {
  background: black;
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}


.yellow {
  color: #A1792B !important;
}


.drop-btn {
  background: #1D1F22;
  border: 1px solid #272A2E;
  border-radius: 3px;

}


.network {
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 15px;
  left: 0;
  right: 0;
  width: 100%;
  height: 150px;
  overflow: scroll;
  max-height: 200px;
  background: #1d1f22;
  border: 1px solid #272a2e;
  border-radius: 3px;
}

.crypto-deposit {
  display: flex;
  flex-direction: column;
  gap: 20px;


}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //align-items: center;

}


.main-box {
  max-width: 550px;
  width: 100%;
  min-width: 270px;
  align-self: center;
  display: flex;
  flex-direction: column;
  background: #181a1d;
  border-radius: 8px;

}


.deposit-box {
  position: relative;
  max-width: 550px;
  width: 100%;
  min-width: 270px;
  align-self: center;
  display: flex;
  flex-direction: column;
  background: #181a1d;
  border-radius: 8px;
  padding: clamp(24px, 1.2vw, 32px);
  gap: 32px;
}



.input-container {
  display: flex;
  align-items: center;
  background: #1D1F22;
  border: 1px solid #272A2E;
  border-radius: 3px;
  padding: 0 10px;
  height: 40px;

  input {
    height: 100%;
  }
}

.dropdown {
  z-index: 3;

  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5em;
  }

  position: absolute;
  //top: 3.4em;
  //left: 0;
  //left: 67%;
  //width: 120px;
  left: 0;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  overflow: scroll;
  max-height: 200px;
  background: #1d1f22;
  border: 1px solid #272a2e;
  border-radius: 3px;
}

.drop-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;

  .coin-info {
    display: flex;
    align-items: center;
    gap: 12px;
    height: 100%;
  }
}

.address {
  span:first-child {
    font-size: clamp(12px, 1.2vw, 16px);
  }

  display: flex;
  gap: 12px;
  flex-direction: column;

}

.gray {
  font-size: 12px !important;
  color: #7A8083 !important;
}

.titles {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .note {
    font-size: 12px;
  }
}


.row{
  span{
    display: flex;
gap: 8px;
    align-items: center;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.deposit{
  background: #1F2125 !important;
  align-self: flex-start;
  width: 100%;
  flex-direction: row !important;
  display: flex;
  gap: 30px;
  padding: clamp(30px, 1.2vw, 40px);
  justify-content: space-between;
  align-items: center;
}

.form-control {
  font-family: system-ui, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.form-control + .form-control {
  margin-top: 1em;
}

.form-control:focus-within {
  color: var(--form-control-color);
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: #FFBB38;
  width: 1rem;
  height: 1rem;
  border: 1px solid #FFBB38;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #FFBB38;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:focus {
  outline: 1px solid #FFBB38;
  outline-offset: 0;
}







.yellow {
  color: #A1792B;
}


</style>