<template>
  <div class="personal-info">
    <label class="input-container">
      <label>First Name:</label>
      <input
        class="normal-input account-input"
        @input="data.firstName = $S2Fa(data.firstName)"
        type="text"
        placeholder="First Name"
        v-model="data.firstName"
        :disabled="readonly"
      />
    </label>
    <label class="input-container">
      <label>Last Name</label>
      <input
        class="normal-input account-input"
        type="text"
        @input="data.lastName = $S2Fa(data.lastName)"
        placeholder="Last Name"
        v-model="data.lastName"
        :disabled="readonly"
      />
    </label>
    <label class="input-container">
      <label>National Code:</label>
      <input
        class="normal-input account-input"
        type="text"
        @input="data.nationalCode = $A2N(data.nationalCode)"
        placeholder="National Code"
        v-model="data.nationalCode"
        :disabled="readonly"
        maxlength="10"
      />
    </label>

    <div class="input-container">
      <label>Birthday</label>
      <date-picker
        class="main-box"
        v-model="data.birthDay"
        :format="$dateFormat['en']"
        :display-format="$dateFormat['en']"
        :max="$jmoment().format($dateFormat['en'])"
        :clearable="!state.userInfo.authorized"
        :disabled="readonly"
        type="date"
      />
    </div>

    <label class="input-container">
      <label>Wired telephone:</label>
      <input
        class="normal-input account-input"
        type="text"
        @input="data.telephone = $A2N(data.telephone)"
        placeholder="Wired telephone"
        v-model="data.telephone"
        maxlength="11"
      />
    </label>
    <label class="input-container">
      <label>Mobile Number</label>
      <input
        class="normal-input account-input"
        @input="data.mobileNumber = $A2N(data.mobileNumber)"
        type="text"
        placeholder="Mobile Number"
        v-model="data.mobileNumber"
        maxlength="11"
      />
    </label>
    <label class="input-container">Address:</label>
    <label class="textarea-lable">
      <textarea
        class="textarea"
        name="address"
        id="address"
        style="resize: none"
        placeholder="Address"
        v-model="data.address"
      />
    </label>
  </div>
</template>

<script>
import "@/style/birthdayPicker.scss";

export default {
  name: "PersonalInfo",
  props: ["data"],
  computed: {
    readonly() {
      return [
        "AUTHORIZED",
        "INFORMATION_CHANGED",
        "INFORMATION_CHANGED_PENDING",
      ].includes(this.state.userInfo.authenticationType);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
  flex-direction: column;
  label {
    font-size: clamp(10px, 1vw, 12px);
  }
}

.textarea:focus {
  outline: none !important;
  border: 0.5px solid #279bc0 !important;
}

.personal-info {
  background: #1d1f22;
  //border-radius: 16px;
  width: 100%;
  min-height: 372px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 115px;
  row-gap: 40px;
  padding: 20px;
}

/* label {
  max-height: 36px !important;
} */
.account-input {
  max-width: 351px;
  min-width: 351px;
  flex-grow: 1;
}

.textarea-lable {
  width: 100%;
  height: 80px;
}

.textarea {
  outline: none !important;
  color: white;
  padding: 10px 15px;
  width: 100%;
  min-height: 36px;
  border: 0.5px solid var(--gray) !important;
  border-radius: 4px;
  background: transparent;
  height: 100%;
}

@media (max-width: 846px) {
  .normal-input {
    min-width: unset;
    width: 100%;
  }
}

@media (max-width: 607px) {
  label {
    width: 100%;
  }
  .normal-input {
    min-width: unset;
    width: 100%;
  }
}
</style>
