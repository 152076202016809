<template>
  <div class="container">
    <div class="dashboard">
<div class=""><DashboardOv/></div>
<div class=""><DashboardInfo/></div>
    </div>
  </div>
</template>

<script>
import DashboardOv from './DashboardOv.vue';
import DashboardInfo from './DashboardInfo.vue';



export default {
    name: "Dashboard",
    components: { DashboardOv, DashboardInfo }
}
</script>

<style lang="scss" scoped>
.container {
  max-width: 1240px;
  padding: 4.5em 10px;
  display: flex;
  flex-direction: column;
  gap: clamp(1em, 2vw, 2em);
}

.dashboard {
  display: flex;
  flex-direction: column;
  gap: clamp(20px, 2vw, 50px);
}
</style>