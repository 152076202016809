<template>
 <div class="container">
   <div class="banner">


     <div class="banner-view">

       <div class="title">
        <span> Find the Next <span class="gradient-text">Crypto Gem</span> on Omani</span>
         <p>1 Out of 4 Crypto Holders Worldwide Is with Omani</p>
       </div>

       <button class="btn-primary" @click.prevent="$go('/sign-up')">Sign Up</button>

     </div>
     <div class="banner-picture">
       <div class="first"><img src="../../../assets/Public/banner-first.svg" alt=""></div>
       <div class="sec"><img src="../../../assets/Public/banner-sec.svg" alt=""></div>
       <div class="third"><img src="../../../assets/Public/banner-third.svg" alt=""></div>
       <div class="forth"><img src="../../../assets/Public/banner-forth.svg" alt=""></div>
     </div>

   </div>
 </div>
</template>

<script>
// import BannerCards from "./BannerCards.vue";
export default {
  name: "HomeBanner",
  components: {
    // BannerCards,
  },
};
</script>

<style lang="scss" scoped>





@keyframes scale-up-right {
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    //-webkit-transform-origin: 100% 50%;
    //transform-origin: 100% 50%;
  }
  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    //-webkit-transform-origin: 100% 50%;
    //transform-origin: 100% 50%;
  }
  100%{
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    //-webkit-transform-origin: 100% 50%;
    //transform-origin: 100% 50%;
  }

}

.title{
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: clamp(32px,4vw,68px);
  line-height: 75px;
  max-width: 431px;


  p{
    line-height: 40px;
    margin: 0;
    font-size: clamp(18px,1.5vw,22px);
    color:var(--gray)
  }

}



.banner{
  margin-top: 5%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  clear: both;
  z-index: 1;

}
.btn-primary{
  width: 178px;
  height: 50px;
}
.banner::after{


}
.banner-picture{
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex:1;
  background-image: url("../../../assets/Public/banner.svg");
  background-position: right;
  background-repeat: no-repeat;
  .first{
    align-self: flex-end;
  }
  .sec{
    align-self: center;
    //-webkit-animation: scale-up-right 5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    //animation: scale-up-right 5s   infinite;
  }
  .third{
    align-self: center;
  }
  .forth{
    display: none;
  }
}

.banner-view{
  display: flex;
  flex-direction: column;
  gap: clamp(24px,2vw,48px);

}

.banner-picture{
  background-image: url("../../../assets/Public/chart.png");
  background-repeat: no-repeat;
  background-size: cover;

}

.gradient-text{
  background: linear-gradient(81.26deg, #FFBB38 0%, #FF5454 100%);
background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 1000;
}
@media (max-width:850px){
  .banner{
    gap: 40px;
  }
  .banner-picture{

    .first, .third, .sec{

    }
    .forth{
      display: block;
      align-self: flex-start;
    }
    .sec{
      display: none;
    }
  }
}
</style>
