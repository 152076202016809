<template>
  <div class="right-col">
    <div class="header">
      <div class="coin-selector">
        <img
          id="coin-img"
          src="../../../../assets/Coins/BTC.png"
          alt=""
          width="20"
          height="20"
        />
        <span>{{ tradeFrom }} / {{ tradeTo }}</span>
        <img src="../../../../assets/Public/selector.svg" alt="" />
      </div>

      <div class="info">
        <div class="cap">
          <span>Price:</span>
          <span class="light-green"
            >43,382.4 <small class="green">+13.13</small></span
          >
        </div>

        <div class="cap">
          <span>24H High:</span>
          <span>44,210.4</span>
        </div>

        <div class="cap">
          <span>24H Low:</span>
          <span>37,928.4</span>
        </div>

        <div class="cap">
          <span>24H Amount:</span>
          <span>14,767.4</span>
        </div>

        <div class="cap">
          <span>24H Volume:</span>
          <span>612,617,853.30</span>
        </div>
      </div>
    </div>
    <div class="tables">
      <order-book class="cont" />
      <TradesTable class="cont" />
    </div>
    <div class="shop">
      <Shop />
    </div>
  </div>
</template>

<script>
import OrderBook from "@/components/Profile/Trade/RightCol/orderbook&trades/OrderBook.vue";
import TradesTable from "@/components/Profile/Trade/RightCol/orderbook&trades/TradesTable.vue";
import Shop from "@/components/Profile/Trade/RightCol/shop/Shop";
import { tradeFrom, tradeTo } from "@/library/reuseableFunction";
export default {
  name: "RightColumn",
  components: { Shop, TradesTable, OrderBook },
  computed: {
    tradeTo,
    tradeFrom,
  },
};
</script>

<style lang="scss" scoped>
.info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .cap {
    display: flex;
    gap: 10px;
    span:first-child {
      color: #7a8083;
    }
  }
}

.right-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.tables {
  display: flex;
  flex-direction: row;

  gap: 8px;
  .cont {
    //width: 100%;
    max-width: 256px;
    flex: 1 1 auto;
  }
}

.header {
  display: none;
}

@media (max-width: 600px) {
  .tables {
    .cont {
      max-width: unset;
      width: 100% !important;
    }
  }

  .shop {
    order: 1;
  }
  .tables {
    flex-wrap: wrap;
    order: 2;
  }

  .header {
    display: flex;
    flex-direction: column;
    //height:53px;
    background: #1d1f22;
    align-items: flex-start;
    gap: 15%;
    padding: 3%;
    .coin-selector {
      display: flex;
      gap: 8px;
    }
  }
}
</style>