<template>
<div class="w100">
  <div class="invite">
    <div class="contents">
      <h3>Invite New Users to <span>Earn Reward</span></h3>
      <p>You can invite your friends to the Omani with the dedicated link below. Currently, based on this program, you
        will be paid 30% of the transaction fees of those registered with this link.</p>
      <div class="box">

        <div class="first-row">
          <span v-if="alertLink" class="copy">Copied</span>
          <div class="col">
            <p>
              Referral Code
            </p>
            <p class="links" @click="copyCode">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 4V16C8 16.5304 8.21071 17.0391 8.58579 17.4142C8.96086 17.7893 9.46957 18 10 18H18C18.5304 18 19.0391 17.7893 19.4142 17.4142C19.7893 17.0391 20 16.5304 20 16V7.242C20 6.97556 19.9467 6.71181 19.8433 6.46624C19.7399 6.22068 19.5885 5.99824 19.398 5.812L16.083 2.57C15.7094 2.20466 15.2076 2.00007 14.685 2H10C9.46957 2 8.96086 2.21071 8.58579 2.58579C8.21071 2.96086 8 3.46957 8 4V4Z" stroke="#F2F2F2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 18V20C16 20.5304 15.7893 21.0391 15.4142 21.4142C15.0391 21.7893 14.5304 22 14 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V9C4 8.46957 4.21071 7.96086 4.58579 7.58579C4.96086 7.21071 5.46957 7 6 7H8" stroke="#F2F2F2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              {{ referralCode }}
            </p>
          </div>
          <div class="col">
            <p>
              Invitation Link:
            </p>
            <p class="links" @click="copyLink">
              <svg :style="{alignSelf:'center'}" width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.9167 9.66675H4.83341C3.50062 9.66675 2.41675 10.7506 2.41675 12.0834V24.1667C2.41675 25.4995 3.50062 26.5834 4.83341 26.5834H16.9167C18.2495 26.5834 19.3334 25.4995 19.3334 24.1667V12.0834C19.3334 10.7506 18.2495 9.66675 16.9167 9.66675Z" fill="#F2F2F2"/>
                <path d="M24.1667 2.41675H12.0834C11.4425 2.41675 10.8278 2.67136 10.3746 3.12457C9.92136 3.57779 9.66675 4.19247 9.66675 4.83341V7.25008H19.3334C19.9744 7.25008 20.589 7.50469 21.0423 7.95791C21.4955 8.41112 21.7501 9.02581 21.7501 9.66675V19.3334H24.1667C24.8077 19.3334 25.4224 19.0788 25.8756 18.6256C26.3288 18.1724 26.5834 17.5577 26.5834 16.9167V4.83341C26.5834 4.19247 26.3288 3.57779 25.8756 3.12457C25.4224 2.67136 24.8077 2.41675 24.1667 2.41675Z" fill="#F2F2F2"/>
              </svg>


              <span :style="{fontSize:'clamp(10px,1.1vw,14px)'}">  {{ link }}</span>
            </p>
          </div>
        </div>
        <div class="second-row">
          <div class="icons">
            <p>Share via:</p>
            <div class="icons-container">
              <img src="../../../assets/Public/twitter.svg" alt="">
              <img src="../../../assets/Public/whatsapp.png" alt="">
              <img src="../../../assets/Public/facebook.png" alt="">
              <img src="../../../assets/Public/instagram.svg" alt="">
              <img src="../../../assets/Public/telegram.svg" alt="">

            </div>
          </div>

        </div>

      </div>


    </div>
    <div class="hint-container">
      <div class="hint-box">
        <img src="../../../assets/Public/invite-1.svg" alt="" width="80" height="80">
        <p>Share your referral link, invite friends to register on Alpha Exchange.</p>
      </div>
      <img  class="pointer" src="../../../assets/Public/pointer.svg" alt="" >
      <div class="hint-box">
        <img src="../../../assets/Public/invite-2.svg" alt="" width="80" height="80">
        <p>Friends sign up with your referral and start trading</p>
      </div>
      <img  class="pointer" src="../../../assets/Public/pointer-2.svg" alt="" >
      <div class="hint-box">
        <img src="../../../assets/Public/invite-3.svg" alt="" width="80" height="80">
        <p>obtain tx fee as referral reward</p>
      </div>
    </div>


  </div>
</div>
</template>

<script>
import Footer from "@/components/Public/Footer";


export default {
  name: "Invite",
  components: {Footer},

  data() {
    return {
      referralCode: 558366,
      link:'https://omani.com/signup/?refcode=558366',
      alertLink:false
    }
  },
  methods:{
    copyLink() {
      navigator.clipboard.writeText(this.link);
      this.alertLink = true;
      setTimeout(() => {
        this.alertLink = false;
      }, 1500);
    },
    copyCode() {
      navigator.clipboard.writeText(this.referralCode);
      this.alertLink = true;
      setTimeout(() => {
        this.alertLink = false;
      }, 1500);
    },
  }
}
</script>

<style lang="scss" scoped>
.container{
  margin: 0 auto;
}
.invite {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3em;
  margin-top: 2.5em;

  .contents {
    display: flex;
    flex-direction: column;
    gap: 1.8em;
    max-width: 604px;

    h3 {
      font-size: clamp(1.5em, 2.5vw, 2em);
      line-height: clamp(1.5em, 2.5vw, 3em);
      font-weight: 500;

      span {
        font-weight: 500;
        color: transparent;
        background: linear-gradient(81.26deg, #FFBB38 0%, #FF5454 100%), #FFBB38;
        background-clip: text;
      }
    }

    p {
      font-size: clamp(.8em, 1.5vw, 1.2em);
    }

    .box {

      position: relative;
      width: 100%;
      background: #1D1F22;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-end;
      padding: clamp(1em, 2vw, 1.3em);
      gap: 2.2em;

      .first-row {
        display: flex;
        flex-wrap: wrap;
        gap: 5em;
        .col{
          display: flex;
          flex-direction: column;
          gap: 24px;

        }
        col:first-child{
          flex-grow: 1;
        }

        col:last-child{


        }
      }

      .second-row {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: flex-start;
      }
    }
.links{
  display: flex;
  gap: 10px;
}

  }
}

.icons{
  display: flex;
  gap:2rem ;
  align-self: flex-start;
  .icons-container{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
}

.w100{
  width: 100%;
}
.hint-container{
  max-width: 1000px;
  margin-bottom: 5em;
  display: flex;
width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  .hint-box{
    max-width: 158px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    p{
      text-align: center;
      font-size: clamp(10px,1vw,14px);
    }
    >svg{
      width: 80px;
      height: 80px;
    }
  }
}



.copy{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  top: 100px;
}




@media (max-width: 500px) {
  .contents{
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 90%;

  }
  .pointer{
    display: none;
  }
  .hint-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

</style>