<template>
<div class="main">
  <div class="title">
    <p>Estimated Balance <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="26" height="26" rx="3" fill="#1D1F22"/>
      <path d="M13 8.5C8 8.5 6 13 6 13C6 13 8 17.5 13 17.5C18 17.5 20 13 20 13C20 13 18 8.5 13 8.5Z" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13 15.5C14.3807 15.5 15.5 14.3807 15.5 13C15.5 11.6193 14.3807 10.5 13 10.5C11.6193 10.5 10.5 11.6193 10.5 13C10.5 14.3807 11.6193 15.5 13 15.5Z" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    </p>
    <h4>0.13623600 BTC <span class="gray">≈ $6460.4737104</span></h4>
  </div>

  <div class="overview">

    <div class="fund" v-if="isGuide"><Fund   @skip="skipGuide" /></div>
    <div class="table" :class="{'w-100':isGuide == false}"><OviewTable :isGuide="isGuide" /></div>

  </div>
</div>
</template>

<script>
import Fund from "@/components/Profile/Wallet/overview/Fund";
import OviewTable from "@/components/Profile/Wallet/overview/OviewTable";
export default {
  name: "Cryptowithdrawl",
  components: {OviewTable, Fund},
  data(){
    return{
      isGuide:true,
    }
  },
  methods:{
    skipGuide(value){
      this.isGuide = value;
      this.isGuide =false;
    }
  }
}
</script>

<style lang="scss" scoped>
.w-100{
  width:100% !important;
  max-width: unset !important;
}
.main{
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.gray{

    color: #AFB0B1
}

.title{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  p{
    display: flex;
    gap: 12px;


  }
}


.overview{
  display: flex;
  gap: 41px;
  flex-wrap: wrap;
  .fund{
    flex:2 2 auto;
    max-width: 720px;
  }
  .table{
    flex:1 1 auto;
  }
}
</style>