<template>
  <div class="">
    <div class="container">
      <div class="text">
        <h3>Trading Fee Level</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid cum delectus, eaque eius error expedita
          explicabo hic pariatur repellendus voluptatum! Atque doloremque eos esse eum explicabo facilis itaque iusto
          magni necessitatibus officia. Ducimus id, voluptatibus. Aliquid, est, id! Architecto autem dolor dolore in
          labore laborum nisi placeat porro sequi vero. Atque autem culpa delectus deleniti, doloribus eveniet explicabo
          illum magnam obcaecati officiis porro quis, reprehenderit rerum sint voluptate voluptates, voluptatum.
          Accusamus asperiores aut consectetur corporis cupiditate doloribus ea esse harum magnam minima, minus placeat
          quas quod recusandae reprehenderit saepe soluta temporibus unde! Accusamus, amet deserunt dolor, expedita ipsa
          libero maxime nulla obcaecati provident quasi quo tempora veritatis vitae. Ab beatae blanditiis dolorem
          doloremque dolores enim eos eveniet, fugiat fugit illo laborum laudantium libero minima necessitatibus odit
          optio quae quasi quia recusandae saepe sed tenetur ullam unde veniam veritatis? Delectus ducimus eaque enim id
          laborum minima nesciunt quis reiciendis sapiente vitae!
        </p>
      </div>
      <div class="table-content">
        <p>Trading fee level is based on user’s last three month trading volume calculated according to the following
          table:</p>
        <div class="table-container">
          <table>
            <tr class="tr-head">
              <td>Trading Level</td>
              <td>3 Month Trading volume (BTC)</td>
              <td>Transaction Fee</td>
            </tr>
            <tr class="tr-body" v-for="item in levels">
              <td>{{ item.lvl }}</td>
              <td>{{ item.vol }}</td>
              <td>{{ item.fee }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <footer-component/>
  </div>
</template>

<script>
import FooterComponent from "@/components/Public/Footer";
export default {
  name: "Fees",
  components: {FooterComponent},
  data() {
    return {
      levels: [
        {
          lvl: '1',
          vol: '>=50',
          fee: '0.1%'
        },
        {
          lvl: '2',
          vol: '>=50',
          fee: '0.1%'
        },
        {
          lvl: '3',
          vol: '>=50',
          fee: '0.1%'
        },
        {
          lvl: '4',
          vol: '>=50',
          fee: '0.1%'
        },

      ]
    };
  },
}
</script>

<style lang="scss" scoped>
.container {
  margin:3em auto;
  display: flex;
  flex-direction: column;
  gap: 4em;
}

.table-content {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  font-size: clamp(.6rem, 1.5vw, 1rem);
}

.tr-head {
  background: #1D1F22;
  height: 3em;
  td{
    padding: 0 .5rem;
  }

  td:last-child {
    text-align: right;
  }

  td:nth-child(2) {
    text-align: center;
  }

}

.tr-body {
  height: 3.6em;
  td{
    padding: 0 .5rem;
  }
  td:last-child {
    text-align: right;
  }

  td:nth-child(2) {
    text-align: center;
  }
}
</style>