<template>
  <div class="table-section">
    <div class="title">
      <p>Recent Transactions</p>
      <span @click.prevent="$router.push('/profile/wallet/walleth')">View All</span>
    </div>
    <div class="table-container" :class="{'w-100' :isGuide==false}">
<table>
  <tr class="tr-head">
    <td class="tablet">time</td>
  <td>type</td>
  <td>amount</td>
  <td>Deposit wallet</td>
  </tr>
  <tr class="tr-body" v-for="(item,index) in tableContents" :key="index">
    <td class="tablet">{{item.time}}</td>
<td>{{item.type}}</td>
<td>{{item.amount}}</td>
<td>{{item.depositWallet}}</td>

  </tr>
</table>
    </div>
  </div>
</template>

<script>
export default {
  name: "OviewTable",
  props:['isGuide'],
  data() {
    return {
      tableContents: [
        {
          time: '2019-01-01',
          type: 'BTC/USD',
          amount: '09.00',
          depositWallet: '97213849758050'
        },
        {
          time: '2019-01-01',
          type: 'BTC/USD',
          amount: '09.00',
          depositWallet: '97213849758050'
        },

        {
          time: '2019-01-01',
          type: 'BTC/USD',
          amount: '09.00',
          depositWallet: '97213849758050'
        },

        {
          time: '2019-01-01',
          type: 'BTC/USD',
          amount: '09.00',
          depositWallet: '97213849758050'
        },
        {
          time: '2019-01-01',
          type: 'BTC/USD',
          amount: '09.00',
          depositWallet: '97213849758050'
        },

          {
          time: '2019-01-01',
          type: 'BTC/USD',
          amount: '09.00',
          depositWallet: '97213849758050'
        },
        {
          time: '2019-01-01',
          type: 'BTC/USD',
          amount: '09.00',
          depositWallet: '97213849758050'
        },

        {
          time: '2019-01-01',
          type: 'BTC/USD',
          amount: '09.00',
          depositWallet: '97213849758050'
        },

        {
          time: '2019-01-01',
          type: 'BTC/USD',
          amount: '09.00',
          depositWallet: '97213849758050'
        },

        {
          time: '2019-01-01',
          type: 'BTC/USD',
          amount: '09.00',
          depositWallet: '97213849758050'
        },

        {
          time: '2019-01-01',
          type: 'BTC/USD',
          amount: '09.00',
          depositWallet: '97213849758050'
        },

        {
          time: '2019-01-01',
          type: 'BTC/USD',
          amount: '09.00',
          depositWallet: '97213849758050'
        },


      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.w-100{
  width:100% !important;
  max-width: unset !important;
}



.table-container{
  max-width: 620px;
  max-height: 600px;
  overflow: scroll;
  position: relative;
  td{
    height: 35px;
    color: #AFB0B1;
    font-size: clamp(8px, 1.2vw, 12px);
  }
  .tr-head{
    position: sticky;
    top: 0;
    background-color: #131518;
  }
}
.table-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: clamp(14px, 1.5vw, 20px);
    font-weight: bold;
  }

  span {
    font-size: clamp(10px, 1.5vw, 14px);
    color: #A1792B;
  }
}
</style>