<template>
  <div class="trendChart-parent">

    <TrendChart :key="test" :datasets="data" :grid="{
        verticalLines: true,
        horizontalLines: true,
      }">
    </TrendChart>
  </div>
</template>

<script>
export default {
  name: "TradingChart",
  components: {},
  props: ["trend"],
  data() {
    return {
      data: [],
      test: 0,
      chartData: []
    };
  },
  methods: {},
  mounted() {
    if (this.trend) {
      for (var i = 0; i < 20; i++) {
        this.chartData[i] = this.trend[i] / 10
      }
      this.data = [{
        data: this.chartData,
        smooth: false,
        fill: true,
      }, ]
      ++this.test
    } else {
      this.chartData = [0, 0, 0, 0, 0]
    }
  },
};
</script>

<style scoped>
.trendChart-parent {
  display: flex;
  justify-content: center;
}
</style>
<style lang="scss">
.vtc {
  height: 50px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.vtc * {
  stroke-width: 3px;
  stroke: rgba(255, 176, 7, 1);

}

.vertical {
  display: none;
}

.curve {
  height: 100%;
  display: flex;
  flex-grow: 1;

}

.curve path:first-of-type {
  display: none;
}
</style>
