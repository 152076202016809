<template>
  <div class="terms">
    <h1>Terms of Use</h1>
    <div class="terms-text" v-html="privacyPolicy.privacyPolicy">

    </div>

    </div>
</template>

<script>
export default {
  name: "Terms",
  data() {
    return {
      privacyPolicy: "",
    };
  },
  methods: {
    async getInfo() {
      const res = await this.$axios.get('/exchange-info')
      this.privacyPolicy = res.baseDTO
    }
  },

  mounted() {
  this.getInfo()
  }
}
</script>

<style lang="scss" scoped>
.terms{
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: clamp(1rem,2vw, 2rem);
}
</style>