<template>
  <div class="join-us">
    <h1>{{ title }}</h1>
    <span>{{ text }}</span>
    <button class="btn-primary" @click.prevent="$router.push('/sign-up')">
      {{ button }}
    </button>
  </div>
</template>

<script>
export default {
  name: "Joinus",
  data() {
    return {
      title: "Start Your Crypto Journey Now!",
      text: "Buy coins with a credit card, bank transfer, Apple Pay, or other options.",
      button: "Sign Up",
    };
  },
};
</script>

<style lang="scss" scoped>
.join-us {
  min-height: 28.9em;
  background: rgb(0, 0, 0);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 39%,
    rgba(255, 104, 48, 1) 59%,
    rgba(20, 8, 4, 1) 72%,
    rgba(255, 104, 48, 1) 99%
  );
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url("../../../assets/Public/joinusbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-box-shadow: 5px 7px 50px -2px rgba(255, 124, 43, 0.19);
  box-shadow: 5px 7px 50px -2px rgba(255, 124, 43, 0.19);

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    text-align: center;
  }

  h1 {
    font-weight: 600;
    width: 40%;
    text-align: center;
    font-size: clamp(2em, 4vw, 3.5em);
  }
  span {
  }
}
</style>