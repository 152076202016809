import { render, staticRenderFns } from "./OTPMail.vue?vue&type=template&id=45246f5c&scoped=true&"
import script from "./OTPMail.vue?vue&type=script&lang=js&"
export * from "./OTPMail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45246f5c",
  null
  
)

export default component.exports