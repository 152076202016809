<template>
  <div class="bank-info">
    <DeleteBankModal v-if="opendelet" @close="opendelet=false" @remove="deleteBank"/>
    <div class="add-bank" @click="addaccount">Add New Bank Account</div>
    <div class="add_form">
      <label>
        <input
            class="normal-input number"
            @input="dash2"
            type="text"
            v-model="bankAccount.cardNumber"
            maxlength="19"
            placeholder="Card Number"
        />
        <p
            class="error"
            v-if="
            error.includes(bankAccount.cardNumber) &&
            bankAccount.cardNumber !== ''"
        >
       Card is Exists
        </p>
      </label>
      <label>
        <input
            class="normal-input number"
            @input="
            bankAccount.shebaCode = bankAccount.shebaCode.replace(
              /[^\d.]|\.\./gi,
              ''
            );
            error = '';
          "
            type="text"
            maxlength="24"
            v-model="bankAccount.shebaCode"
            placeholder="IBAN"
        />

        <p
            class="error"
            v-if="
            error.includes(bankAccount.shebaCode) &&
            bankAccount.shebaCode !== ''"
        >
          IBAN Exists
        </p>
      </label>
      <label>
        <input
            class="normal-input name"
            type="text"
            placeholder="Bank Name"
            v-model="bankAccount.bankName"
            readonly
        />

      </label>
    </div>
    <div class="exist-banks">
      <p>Previous Accounts:</p>
      <div class="banks-list" v-for="(bank, index) in data.bankAccounts" :key="index">
        <div class="bank-number">
          <span> Card Number </span>
          <span>
                <input class="no-bg" type="text" v-model="bank.cardNumber" disabled>
          </span>
        </div>
        <div class="bank-number">
          <span> IBAN Number </span>
          <span>
            <input class="no-bg" type="text" v-model="bank.shebaCode" disabled>
          </span>
        </div>
        <div class="bank-name">
          {{
            bank.bankName
          }}
        </div>
        <img
            @click.prevent="opendelet=true"
            class="delete-icon"
            src="@/assets/Public/bin.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
const banks = {
  '603799': 'بانک ملی ایران',
  '589210': 'بانک سپه',
  '627648': 'بانک توسعه صادرات',
  '207177': 'بانک توسعه صادرات',
  '627353': 'بانک تجارت',
  '585983': 'بانک تجارت',
  '627961': 'بانک صنعت و معدن',
  '603770': 'بانک کشاورزی',
  '639217': 'بانک کشاورزی',
  '628023': 'بانک مسکن',
  '627760': 'پست بانک ایران',
  '502908': 'بانک توسعه تعاون',
  '627412': 'بانک اقتصاد نوین',
  '622106': 'بانک پارسیان',
  '639194': 'بانک پارسیان',
  '627884': 'بانک پارسیان',
  '502229': 'بانک پاسارگاد',
  '639347': 'بانک پاسارگاد',
  '639599': 'بانک قوامین',
  '504706': 'بانک شهر',
  '502806': 'بانک شهر',
  '603769': 'بانک صادرات',
  '610433': 'بانک ملت',
  '991975': 'بانک ملت',
  '589463': 'بانک رفاه',
  '502938': 'بانک دی',
  '639607': 'بانک سرمایه',
  '627381': 'بانک انصار',
  '505785': 'بانک ایران زمین',
  '636214': 'بانک آینده',
  '636949': 'بانک حکمت ایرانیان',
  '621986': 'بانک سامان',
  '639346': 'بانک سینا',
  '606373': 'بانک قرض الحسنه مهر',
  '627488': 'بانک کارآفرین',
  '502910': 'بانک کارآفرین',
  '505416': 'بانک گردشگری',
  '639370': 'بانک مهر اقتصاد',
  '628157': 'موسسه اعتباری توسعه',
  '505801': 'موسسه اعتباری کوثر',
}
import DeleteBankModal from "./DeleteBankModal.vue";

export default {
  name: "BankInfo",
  props: ['data', 'error', 'change'],
  components: {
    DeleteBankModal
  },
  data() {
    return {
      bankAccount: {
        shebaCode: '',
        cardNumber: '',
        bankName: ''
      },
      opendelet: false,
      bankIndex: ""
    }
  },
  methods: {
    addaccount() {
      if (this.bankAccount.shebaCode && this.bankAccount.cardNumber) {
        this.data.bankAccounts.push(this.bankAccount)
  if(this.state.userInfo.authorized){
    this.$emit('addBank', this.bankAccount)
    console.log('hi')
  }
        this.bankAccount = {
          shebaCode: '',
          cardNumber: '',
          bankName: ''
        }
      }

    },
    removeaccount(e) {
      this.opendelet = true
      this.bankIndex = e
    },
    deleteBank() {
      this.data.bankAccounts.splice(this.bankIndex, 1)
      this.$emit('confirmDeleteBank')
    },
    dash(e) {
      this.data.bankAccounts[e].bankName = banks[this.data.bankAccounts[e].cardNumber ? this.data.bankAccounts[e].cardNumber.replace(/-/ig, '').slice(0, 6) : ''] || 'کارت نامعتبر'
      // this.data.bankAccounts[e].cardNumber = this.$separator(this.data.bankAccounts[e].cardNumber)
      this.error = ''
    },

    dash2() {
      this.bankAccount.bankName = banks[this.bankAccount.cardNumber ? this.bankAccount.cardNumber.replace(/-/ig, '').slice(0, 6) : ''] || 'کارت نامعتبر'
      this.bankAccount.cardNumber = this.$A2N(this.bankAccount.cardNumber)
      // this.bankAccount.cardNumber = this.$separator(this.bankAccount.cardNumber)
      this.error = ''
    }
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    for (let [index, item] of this.data.bankAccounts.entries()) {
      this.dash(index)
    }
  }
};
</script>

<style lang="scss" scoped>
.no-bg {
  background: transparent !important;
  border: 0 !important;
  height: 100%;
  color: white;
  width: 100%;
}

.bank-info {
  background: #1D1F22;
  //border-radius: 16px;
  width: 100%;
  min-height: 265px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  flex-wrap: wrap;

  .add-bank {
    color: rgba(254, 188, 0, 1);
    text-align: left;
    cursor: pointer;
  }

  .add_form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .number {
      width: 291px;
    }

    .name {
      width: 168px;
    }
  }
}

.exist-banks {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  flex-wrap: wrap;

  .banks-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .delete-icon {
      cursor: pointer;
    }

    .bank-number {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 4px;
      width: 100%;
      max-width: 370px;
      height: 40px;
      padding: 0 12px;
    }
  }
}

@media (max-width: 1000px) {
  .add_form {
    label {
      width: 100%;
    }

    flex-direction: column;
    align-self: stretch;
    gap: 20px;

    .normal-input {
      width: 100% !important;
    }
  }
}

@media (max-width: 500px) {
  .banks-list {
    flex-direction: column;
    gap: 20px;
  }
}
</style>
