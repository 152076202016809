<template>
  <div class="">otc is here</div>
</template>

<script>
export default {
  name: "OTC"
}
</script>

<style scoped>

</style>