var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"box"},[(_vm.loading)?_c('vue-skeleton-loader',{staticClass:"skeleton",attrs:{"border":30}}):_vm._e(),_c('div',{staticClass:"buttons"},[_c('a',{staticClass:"btn",class:{ focused: _vm.select === 'trendingcoins' },on:{"click":function($event){_vm.select = 'trendingcoins'}}},[_vm._v(" Trending Coins "),_c('div',{staticClass:"underline"})]),_c('a',{staticClass:"btn",class:{ focused: _vm.select === 'newcoins' },on:{"click":function($event){_vm.select = 'newcoins'}}},[_vm._v("New Coins "),_c('div',{staticClass:"underline"})]),_c('a',{staticClass:"btn",class:{ focused: _vm.select === 'topgainers' },on:{"click":function($event){_vm.select = 'topgainers'}}},[_vm._v("Top Gainers "),_c('div',{staticClass:"underline"})])]),_c('div',{staticClass:"table-container"},[(_vm.select === 'trendingcoins')?[_c('div',{staticClass:"table-container"},[_c('table',[_c('tr',{staticClass:"tr-head"},_vm._l((_vm.tableHead),function(head,index){return _c('td',{key:index,class:head.class},[_vm._v(" "+_vm._s(head.title)+" ")])}),0),_vm._l((_vm.tableContent.slice(0, 5)),function(content,index){return _c('tr',{key:index,staticClass:"tr-body"},[_c('td',{staticClass:"image-container"},[_c('img',{staticClass:"mobile",attrs:{"src":require('../../assets/Coins/' +
                        _vm.$coin2Snake[content.relatedCoin] +
                        '.png'),"alt":"","width":"24","height":"24"}}),_c('span',{staticClass:"tablet"},[_vm._v(_vm._s(_vm.$coin2Snake[content.relatedCoin].split("_").join(" ")))]),_c('span',[_vm._v(_vm._s(content.relatedCoin))])]),_c('td',[_vm._v(_vm._s(_vm.$toLocal(content.lastPrice, _vm.$decimal["TETHER"])))]),_c('td',{staticClass:"tablet"},[_vm._v(" "+_vm._s(_vm.$toLocal(content.last24HourVolume, _vm.$decimal["TETHER"]))+" ")]),_c('td',{staticClass:"tablet",class:{
                    red: content.last24HourChange < 0,
                    green: content.last24HourChange > 0,
                  }},[_vm._v(" "+_vm._s(content.last24HourChange.toLocaleString())+" % "),(content.last24HourChange < 0)?_c('svg',{attrs:{"width":"14","height":"10","viewBox":"0 0 14 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M13 8.63623L7.54545 3.18168L5.36364 5.3635L1 0.999867","stroke":"#F6465D","stroke-width":"1.4","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M13.0007 5.90896V8.63623H10.2734","stroke":"#F6465D","stroke-width":"1.4","stroke-linecap":"round","stroke-linejoin":"round"}})]):_vm._e(),(content.last24HourChange > 0)?_c('svg',{attrs:{"width":"14","height":"10","viewBox":"0 0 14 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M13 1L7.54545 6.45455L5.36364 4.27273L1 8.63636","stroke":"#0CCC80","stroke-width":"1.4","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M13.0007 3.72727V1H10.2734","stroke":"#0CCC80","stroke-width":"1.4","stroke-linecap":"round","stroke-linejoin":"round"}})]):_vm._e()]),_c('td',{staticClass:"tablet"},[_c('apexchart',{attrs:{"height":"60","width":"70%","type":"area","options":_vm.chartOptions,"series":[
                      {
                        data: content.lastWeekData,
                      } ]}})],1),_c('td',{staticClass:"buttons-table"},[_c('button',{staticClass:"btn-primary",on:{"click":function($event){$event.preventDefault();return _vm.$go('/otc')}}},[_vm._v(" Buy ")]),_c('button',{staticClass:"btn-white",on:{"click":function($event){$event.preventDefault();return _vm.$go(("/trade/" + (_vm.$coin2Snake[content.relatedCoin])))}}},[_vm._v(" Trade ")])])])})],2)])]:_vm._e(),(_vm.select === 'newcoins')?[_vm._v("newcoins")]:_vm._e(),(_vm.select === 'topgainers')?[_vm._v("topgainers")]:_vm._e()],2),_c('p',{staticClass:"gradient-text",on:{"click":function($event){$event.preventDefault();return _vm.$go('/market')}}},[_vm._v(" View all markets > ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }