<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="closeModale">
      <div class="modal">
        <div class="message">
          <div class="msg">
<p>Scan the code on the withdrawal page of the trading platform APP or wallet APP</p>
          </div>


          <div class="qr">
            <img :src="'data:image/png;base64,'+qrCode" alt="" width="200" height="200"/>
          </div>




          <div class="warning" >
            <ul>
              <li>
                Send Only {{sourceCoin.code}} to this Address
              </li>
              <li>
                Ensure the network is {{selectNet}}
              </li>
              <li>
                Do not send NFT to this Address. <span class="yellow">learn how to deposit NFT</span>
              </li>
            </ul>


          </div>
        </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ExitModal",
  props: {
    qrCode:'',
    sourceCoin:'',
    relatedCoin: {
      default: ''
    },
    exchange: {
      default: false
    },

  },


  data() {
    return {
      amount: '',
      address: '',
      // qrCode: '',
      selectNet: 'BTC',
      tag: '',
      txid: '',
      token: false,
      alertLink: false,
      drop: false,
      selected_net: "",
      txIdIsNeeded: false,
      enable: false
    };
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.address);
      this.alertLink = true
      setTimeout(() => {
        this.alertLink = false;
      }, 1500);
    },
    closeModale() {
      this.$emit('closeModaleEmit', this.close)
    },
    changeNetwork(e) {
      this.token = e
      this.getQR()
    },
    Copy(e) {
      navigator.clipboard.writeText(e)
      alert('آدرس کپی شد')
    },
    async checkWallet() {
      this.state.loading = true
      // const token = this.token ? 'ERC20' : 'TRC20'
      let query = {
        relatedCoin: this.relatedCoin[0],
        tokenType: this.selected_net,
        walletType: (this.exchange ? 'EXCHANGE' : 'P2P')
      }
      if (this.txIdIsNeeded) {
        query.txid = this.txid
      }
      const res = await this.$axios.get('/wallets/check-confirmed-transaction', {
        params: query
      })
      let text
      if (res.baseDTO.amount) {
        text = `مقدار ${this.$toLocal(res.baseDTO.amount) || 0} ${this.$coinLabel[this.relatedCoin[0]]} واریز شده و تایید ${res.baseDTO.transactionIsDone ? 'شده' : 'نشده'}`
      } else {
        text = 'مقدار واریزی شناسایی نشد'
      }
      this.$error('', text, res.baseDTO.transactionIsDone ? 'success' : 'info')
    },
    selectFun(index) {
      this.selected_net = this.nets[index].tokenType
      this.txIdIsNeeded = this.nets[index].txIdIsNeeded
      this.enable = this.nets[index].isDepositEnable || true
      this.drop = false
      if (this.enable) {
        this.getQR()
      }


    },
    async getQR() {
      this.state.loading = true
      const token = this.selected_net
      let params = {
        relatedCoin: this.relatedCoin[0]
      }
      if (this.relatedCoin[0] === 'TETHER') {
        params.tokenType = token
      }
      const res = await this.$axios('/wallets/customer/wallet-address?walletType=' + (this.exchange ? 'EXCHANGE' : 'P2P') + '&tokenType=' + token, {
        params
      }).catch(() => {
        this.closeModale()
      })
      if (res) {
        this.address = res.baseDTO.address
        this.qrCode = res.baseDTO.qrCode
        this.tag = res.baseDTO.tag
      }
    }
  },
  // mounted() {
  //   this.getQR()
  // }


  // created: function () {
  //   window.addEventListener("click", (event) => {
  //     if (!event.target.closest(".modal , .buy")) {
  //       this.$emit("closeModal", this.close);
  //     } else {
  //       return true;
  //     }
  //   });
  // },
}
</script>

<style lang="scss" scoped>
p{color: black}
.transaction {
  width: 100% !important;
}

.btn {
  margin-top: 5px;
}

.qr {
  img {

    border-radius: 4px;
  }
}

.check {
  button {
    align-self: center;
  }
}

.selectd-net-lable {
  position: relative;
  z-index: 2;


}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(111, 111, 112, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}

.modal {
  max-width: 350px;
  overflow: auto;
  display: flex;
  row-gap: 15px;
  flex-direction: column;
  align-items: center;

  min-height: 276px;
  background: white;
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 16px;
}

.close-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.message-title {
  font-weight: bold;
  font-size: 16px;
  color: #febc00;
}

.message {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 28px;

  .msg {
    > p {
      line-height: 22px;
      font-size: 14px;
    }
  }


  .qr {
    display: flex;
    justify-content: center;
    margin: 10px 0px;
  }

  .yellow {
    color: #A1792B;
  }

  .warning {
    align-self: flex-start;
    font-size: 14px;

    ul {
      li {
        color: black;
        list-style-type: disc;
      }
    }
  }
}




@media screen and (max-width: 768px) {
  .modal {
    overflow: auto;
    width: 90%;
    min-width: 300px;
    height: 90%;
  }
}
</style>
