<template>
  <div class="">
    <div class="container">
      <div class="box">
        <vue-skeleton-loader class="skeleton" v-if="loading" :border="30">
        </vue-skeleton-loader>

        <div class="buttons">
          <a
            class="btn"
            @click="select = 'trendingcoins'"
            :class="{ focused: select === 'trendingcoins' }"
          >
            Trending Coins
            <div class="underline"></div>
          </a>

          <a
            class="btn"
            @click="select = 'newcoins'"
            :class="{ focused: select === 'newcoins' }"
            >New Coins
            <div class="underline"></div
          ></a>

          <a
            class="btn"
            @click="select = 'topgainers'"
            :class="{ focused: select === 'topgainers' }"
            >Top Gainers
            <div class="underline"></div
          ></a>
        </div>
        <div class="table-container">
          <template v-if="select === 'trendingcoins'">
            <div class="table-container">
              <table>
                <tr class="tr-head">
                  <td
                    v-for="(head, index) in tableHead"
                    :key="index"
                    :class="head.class"
                  >
                    {{ head.title }}
                  </td>
                </tr>
                <tr
                  class="tr-body"
                  v-for="(content, index) in tableContent.slice(0, 5)"
                  :key="index"
                >
                  <td class="image-container">
                                        <img
                      class="mobile"
                      :src="
                        require('../../assets/Coins/' +
                          $coin2Snake[content.relatedCoin] +
                          '.png')
                      "
                      alt=""
                      width="24"
                      height="24"
                    />
                    <span class="tablet">{{
                      $coin2Snake[content.relatedCoin].split("_").join(" ")
                    }}</span>
                    <span>{{ content.relatedCoin }}</span>

                  </td>
                  <td>{{ $toLocal(content.lastPrice, $decimal["TETHER"]) }}</td>
                  <td class="tablet">
                    {{ $toLocal(content.last24HourVolume, $decimal["TETHER"]) }}
                  </td>
                  <td
                    class="tablet"
                    :class="{
                      red: content.last24HourChange < 0,
                      green: content.last24HourChange > 0,
                    }"
                  >
                    <!-- <span>{{content.last24HourChange<0?'-': '+'}}</span> -->
                    {{ content.last24HourChange.toLocaleString() }}
                    %

                    <svg
                      v-if="content.last24HourChange < 0"
                      width="14"
                      height="10"
                      viewBox="0 0 14 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 8.63623L7.54545 3.18168L5.36364 5.3635L1 0.999867"
                        stroke="#F6465D"
                        stroke-width="1.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.0007 5.90896V8.63623H10.2734"
                        stroke="#F6465D"
                        stroke-width="1.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <svg
                      v-if="content.last24HourChange > 0"
                      width="14"
                      height="10"
                      viewBox="0 0 14 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 1L7.54545 6.45455L5.36364 4.27273L1 8.63636"
                        stroke="#0CCC80"
                        stroke-width="1.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.0007 3.72727V1H10.2734"
                        stroke="#0CCC80"
                        stroke-width="1.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </td>
                  <td class="tablet">
                    <apexchart
                      height="60"
                      width="70%"
                      type="area"
                      :options="chartOptions"
                      :series="[
                        {
                          data: content.lastWeekData,
                        },
                      ]"
                    ></apexchart>
                  </td>
                  <td class="buttons-table">
                    <button class="btn-primary" @click.prevent="$go('/otc')">
                      Buy
                    </button>
                    <button
                      class="btn-white"
                      @click.prevent="
                        $go(`/trade/${$coin2Snake[content.relatedCoin]}`)
                      "
                    >
                      Trade
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </template>
          <template v-if="select === 'newcoins'">newcoins</template>
          <template v-if="select === 'topgainers'">topgainers</template>
        </div>
        <p class="gradient-text" @click.prevent="$go('/market')">
          View all markets >
        </p>
      </div>
    </div>
  </div>
</template>


<script>
import TrendChart from "../../components/tools/TrendChart";
export default {
  name: "MarketView",
  components: { TrendChart },
  data() {
    return {
      loading: false,
      select: "trendingcoins",
      tableContent: [],
      tableHead: [
        { title: "Coins" },
        { title: "Last Price" },
        { title: "24H Change", class: "mobile" },
        { title: "Markets", class: "tablet" },
        { title: "Chart", class: "tablet" },
        { title: `  ` },
      ],
      chartOptions: {
        chart: {
          height: "30",
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          width: 2,
          // curve: 'smooth',
          colors: ["#eb3f00"],
          gradient: {
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 0,
            stops: [0, 50, 100],
          },
        },
        tooltip: {
          enabled: false,
        },

        // colors: ['#fecf4c','#ffa900','#ff5c01', ],

        fill: {
          colors: ["rgba(255,187,56,0.38)", "black"],
          type: "gradient",
          gradient: {
            opacityFrom: 1,
            opacityTo: 0,
            stops: [0, 100],
          },
        },
      },
    };
  },
  methods: {
    async getTableContent() {
      this.loading = true;
      let res = await this.$axios(
        "/trades/market-info?weeklyDataIncluded=true"
      );
      // res = res.content
      // res = res.filter(item=> item.marketType.includes('TETHER'))
      this.tableContent = res.content;
      this.tableContent = this.tableContent.filter((item) =>
        item.marketType.includes("TETHER")
      );
      // console.log(this.tableContent , res)
      this.loading = false;
    },
  },
  mounted() {
    this.getTableContent();
  },
};
</script>


<style lang="scss" scoped>
.gradient-text {
  align-self: center;
  background: linear-gradient(81.26deg, #ffbb38 0%, #ff5454 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  //font-weight: 1000;
  cursor: pointer;
}

.btn {
  font-weight: 500;
  font-size: clamp(10px, 3vw, 22px);
  color: var(--gray);
  border: none;
  //background: linear-gradient(81.26deg, #FFBB38 0%, #FF5454 100%), #FFBB38;
  padding-bottom: 2px;
  transition: none !important;
}

.underline {
  width: 100%;
  height: 2px;
  background: linear-gradient(81.26deg, #ffbb38 0%, #ff5454 100%), #ffbb38;
  margin-top: 10px;
  margin-bottom: 10px;
  display: none;
}

.focused .underline {
  display: block;
}
.focused {
  color: white;
  transition: all 2s ease;
}
.buttons-table {
  display: flex;
  gap: 10px;
  justify-content: center;
  button {
    //flex: 1 1 75px;
    max-width: 75px;
  }
}
.box {
  z-index: 3;
  position: relative;
  display: flex;
  width: 100%;
  background-color: var(--black);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: clamp(12px, 4vw, 32px);
  -webkit-box-shadow: 5px 7px 50px -2px rgba(255, 124, 43, 0.19);
  box-shadow: 5px 7px 50px -2px rgba(255, 124, 43, 0.19);
}
.buttons {
  transition: all 1s ease-out;
  display: flex;
  width: 100%;
  gap: clamp(20px, 4vw, 70px);
}
.image-container {
  display: flex;
  gap: 9px;
  align-items: center;
}

.tr-head {
  width: 100%;
  color: var(--gray);
  font-size: clamp(10px, 1vw, 12px);
  td {
    height: 40px;
  }
  td:nth-child(5) {
    text-align: center;
  }
}
.tr-body {
  td {
    height: 40px;
  }
}

@media (max-width: 700px) {
  .tablet {
    display: none;
  }
}
@media (max-width: 500px) {
  .mobile {
    display: none;
  }
  .buttons-table {
    .btn-primary,
    .btn-white {
      flex: 1;
      width: 50px;
      font-size: 8px;
    }
  }
}

.loader {
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.12);

  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
}
</style>
<style>
</style>