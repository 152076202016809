<template>
  <div class="overview">
   <div class="user-info">
<div class="card">
  <div class="data">User Info</div>
  <div class="kyc-info">
<div class="email">{{$hiddenMail(state.userInfo.email)}}</div>
<div class="info">
<span>Trading Fee Level:</span>
<span>{{state.userInfo.fee}} %</span>
</div>
<div class="info">
<span>KYC Verification:</span>
<span>{{state.userInfo.authenticationType.toLowerCase()}}</span>
</div>
<button class="btn-white" @click.prevent="$go('/profile/kyc')">Verify Account</button>
  </div>
</div>
<div class="history">
  <div class="data">Recent Activity</div>
  <div class="table-container">
<table>
      <tr class="tr-head">
      <td class="mobile">Time</td>
      <td class="tablet">All pairs</td>
      <td class="mobile">Market Order</td>
      <td>Buy/Sell</td>
      <td>Price</td>
      <td class="mobile">Amount</td>
      <td class="tablet">Filled</td>
      <td class="tablet">Unfilled</td>
      <td>Operation</td>
    </tr>
    <tr class="tr-body" v-for="(item,index) in tableContents" :key="index">
      <td class="mobile">{{item.time}}</td>
      <td class="tablet">{{item.pairs}}</td>
      <td class="mobile">{{item.market}}</td>
      <td>{{item.buysell}}</td>
      <td>{{item.price}}</td>
      <td class="mobile">{{item.amount}}</td>
      <td class="tablet">{{item.filled}}</td>
      <td class="tablet">{{item.unfilled}}</td>
      <td>Cancel</td>
    </tr>
</table>
  </div>
</div>

   </div> 
   <div class="donaut-chart">
     <p class="data">Assets Overview</p>
     <img src="../../../assets/profile/chart.svg" alt="" width="238" height="238">
     <div class="coins">
       <span class="coin-container">
         <span class="img">
            <img src="../../../assets/Coins/BTC.png" alt="" width="40" height="40">
            <span class="coin-amount">40%</span>
         </span>
         <span class="amounts">
           0.004 BTC= <small>12345 USD</small>
         </span>
       </span>
       <span class="coin-container">
         <span class="img">
            <img src="../../../assets/Coins/BTC.png" alt="" width="40" height="40">
            <span class="coin-amount">40%</span>
         </span>
         <span class="amounts">
           0.004 BTC= <small>12345 USD</small>
         </span>
       </span>
              <span class="coin-container">
         <span class="img">
            <img src="../../../assets/Coins/BTC.png" alt="" width="40" height="40">
            <span class="coin-amount">40%</span>
         </span>
         <span class="amounts">
           0.004 BTC= <small>12345 USD</small>
         </span>
       </span>
              <span class="coin-container">
         <span class="img">
            <img src="../../../assets/Coins/BTC.png" alt="" width="40" height="40">
            <span class="coin-amount">40%</span>
         </span>
         <span class="amounts">
           0.004 BTC= <small>12345 USD</small>
         </span>
       </span>



     </div>

   </div>
    
    
    
    </div>
</template>

<script>
export default {
name:'DashboardOv',
data(){
  return {
tableContents:[ 
  {
    time:'2019-01-01',
    pairs:'BTC/USDT',
    market:'Market Order',
    buysell:'Buy',
    price:'0.000001',
    amount:'0.000001',
    filled:'0.000001',
    unfilled:'0.000001',
    operation:'Cancel'
  },
  {
    time:'2019-01-01',
    pairs:'BTC/USDT',
    market:'Market Order',
    buysell:'Buy',
    price:'0.000001',
    amount:'0.000001',
    filled:'0.000001',
    unfilled:'0.000001',
    operation:'Cancel'
  },
  {
    time:'2019-01-01',
    pairs:'BTC/USDT',
    market:'Market Order',
    buysell:'Buy',
    price:'0.000001',
    amount:'0.000001',
    filled:'0.000001',
    unfilled:'0.000001',
    operation:'Cancel'
  },
  {
    time:'2019-01-01',
    pairs:'BTC/USDT',
    market:'Market Order',
    buysell:'Buy',
    price:'0.000001',
    amount:'0.000001',
    filled:'0.000001',
    unfilled:'0.000001',
    operation:'Cancel'
  },
  {
    time:'2019-01-01',
    pairs:'BTC/USDT',
    market:'Market Order',
    buysell:'Buy',
    price:'0.000001',
    amount:'0.000001',
    filled:'0.000001',
    unfilled:'0.000001',
    operation:'Cancel'
  },
  {
    time:'2019-01-01',
    pairs:'BTC/USDT',
    market:'Market Order',
    buysell:'Buy',
    price:'0.000001',
    amount:'0.000001',
    filled:'0.000001',
    unfilled:'0.000001',
    operation:'Cancel'
  },
    {
    time:'2019-01-01',
    pairs:'BTC/USDT',
    market:'Market Order',
    buysell:'Buy',
    price:'0.000001',
    amount:'0.000001',
    filled:'0.000001',
    unfilled:'0.000001',
    operation:'Cancel'
  },
  {
    time:'2019-01-01',
    pairs:'BTC/USDT',
    market:'Market Order',
    buysell:'Buy',
    price:'0.000001',
    amount:'0.000001',
    filled:'0.000001',
    unfilled:'0.000001',
    operation:'Cancel'
  },
  {
    time:'2019-01-01',
    pairs:'BTC/USDT',
    market:'Market Order',
    buysell:'Buy',
    price:'0.000001',
    amount:'0.000001',
    filled:'0.000001',
    unfilled:'0.000001',
    operation:'Cancel'
  },
  {
    time:'2019-01-01',
    pairs:'BTC/USDT',
    market:'Market Order',
    buysell:'Buy',
    price:'0.000001',
    amount:'0.000001',
    filled:'0.000001',
    unfilled:'0.000001',
    operation:'Cancel'
  },
  {
    time:'2019-01-01',
    pairs:'BTC/USDT',
    market:'Market Order',
    buysell:'Buy',
    price:'0.000001',
    amount:'0.000001',
    filled:'0.000001',
    unfilled:'0.000001',
    operation:'Cancel'
  },
  {
    time:'2019-01-01',
    pairs:'BTC/USDT',
    market:'Market Order',
    buysell:'Buy',
    price:'0.000001',
    amount:'0.000001',
    filled:'0.000001',
    unfilled:'0.000001',
    operation:'Cancel'
  },

]
  }
},
}
</script>

<style lang="scss" scoped>

.data{
  padding: 24px;
  font-size: clamp(18px,1.5vw,24px);
 }
.overview{
  display: flex;
  gap: clamp(20px,3vw,50px);
  flex-wrap: wrap;

.user-info{
  flex: 3 3 280px;
  display: flex;
  flex-direction: column;
  gap: clamp(20px,3vw,50px);
  max-width: 816px;
}
.donaut-chart{
  flex: 1 1 200px;
  display: flex;
  flex-direction: column;
  gap: clamp(20px,3vw,50px);
}

}

.card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 313px;
background-image: url('../../../assets/profile/dash-info.png');
// padding: clamp(12px,2vw,24px);
position: relative;


.kyc-info{ 
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  padding: 1.2em;
  // height: 140px;
  background: linear-gradient(180deg, rgba(96, 96, 96, 0.23) 0%, #1B1B1B 100%);
  .email{
    font-size: 24px;
    font-weight: bolder;
    flex: 1 1 auto;
   }
.info{ 
  display: flex;
  flex-direction: column;
  gap: .8em;
   flex:1 1 auto;
   align-self: center;
}
button{ 
  flex:1 1 auto;  
  align-self: center;
  max-width: 128px;
  color: #A1792B;
  font-size: clamp(10px,3vw,14px);
}
}

}

.donaut-chart{ 
  display: flex;
  flex-direction: column;
  gap: clamp(1.2em,0.5vw,2.3em);
}

.table-container{
  overflow: scroll;
  max-height: 260px;
table{ 
    border-collapse: collapse;
}
.tr-head{
  height: 32px;
  width: 100%;
  position: sticky;
  top: 0;
  background: #1D1F22;
  td{
    font-size: clamp(8px,1.5vw,12px);
    color:#7A8083;
  &:last-child{
    color: #A1792B;
  }
  &:first-child{
text-align: center;
  }
  }
}
.tr-body{
  width: 100%;

  border-bottom: 1px solid rgba(255, 255, 255, 0.142);
  td{
    height: 2.3rem;
    font-size: clamp(8px,1.5vw,12px);
    // color:#7A8083;
    &:last-child{
      color: #A1792B;
    }

    &:nth-child(4){
       color: #A1792B;
       text-align: center;
    }
      &:first-child{
text-align: center;
  }
  }
}




}



.coins{ 


  display: flex;
 flex-direction: column;
 gap: 12px;
  .coin-container{ 
      background: #1D1F22;
border-radius: 2px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: .5rem;
 small{ 
   font-size: clamp(8px,1.5vw,12px);
   color: #BDBDBD;
 }


.img{ 
  display: flex;
  gap: 4px;
  align-items: center;
}
}
.amounts{ 
  font-size: (12px,1.5vw,14px);
}
}

@media (max-width:768px){
  .tablet{ 
    display: none;
  }
}

@media (max-width:450px){
  .mobile{ 
    display: none;
  }

.tr-body,.tr-head{ 
  td:first-child{
    text-align: center;
  }
  td:last-child{ 
    text-align: right;
  }
  td:nth-child(2){
    text-align: center;
  }

}

  .kyc-info {
    flex-direction: column;
    gap: 30px;
.info {
  align-self: flex-start;
}
  }
}


</style>