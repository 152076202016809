<template>
  <div class="document">
    <div class="first-col">
      <div>
        <p>

          Dear user, to speed up the authentication process, place the national card image next to your face with a handwriting.
          Send perfectly legible just like the sample image. Note that handwritten text should not be typed. also
          Note when preparing the image, ensure the sharpness of the handwritten text and national card information by enlarging the image
          And also note that the submitted image is not recorded in reverse and in a mirror
        </p>
      </div>
      <form class="forms" @submit.prevent="upload" @dragover.prevent @dragenter="hovered=!hovered">
        <label for="picture" class="input-file-btn">
          <div class="input-file-container" @dragleave="hovered=!hovered"
               @drop.stop.prevent="hovered=false;drop($event);" :style="{borderColor : hovered? 'blue' : ''}">
            <img v-if="path" class="img" :src="path" alt="" width="280" height="280">
            <img v-else class="img" src="@/assets/profile/cloud.png">
            <input type="file" name="picture" id="picture" style="display: none" @change="upload($event.target)">
            <span v-if="!path"> Upload</span>
          </div>
          <div class="btn btn-primary">Upload</div>
        </label>
      </form>

    </div>
    <div class="second-col">
      <p>

        Dear user, to speed up the authentication process, put the national card image next to your face along with a handwritten
        Send a perfectly legible face exactly like the sample image. Note that handwritten text should not be typed. Also pay attention
        Make sure the format of the photos is png or jpg, and when preparing the image, make sure that the handwritten text and national card information are clear by enlarging the image.
        Also note that the submitted image is not recorded in reverse and in a mirror
      </p>
      <div class="pictures">
        <img src="@/assets/profile/female.png" alt="">
        <img src="@/assets/profile/male.png" alt="">
      </div>

      <div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Documents",
  components: {},
  props: ['data'],

  data() {
    return {
      path: '',
      loadedpicture: '',
      hovered: '',
    }
  },
  watch: {
    'data.image'() {
      if (this.data.image)
          // if (typeof this.data.image === 'string')
        this.path = this.data.image
    }
  },
  methods: {
    drop(e) {
      let dt = e.dataTransfer
      this.upload(dt)
    },
    upload(evt) {
      let file = evt.files[0]
      this.data.file = file
      let fileReader = new FileReader();
      fileReader.addEventListener("loadstart", (event) => {
        this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
      })
      fileReader.addEventListener("progress", (event) => {
        this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
      });
      fileReader.addEventListener('load', (event) => {
        this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
        this.path = event.target.result
      });
      fileReader.readAsDataURL(file)
    },
    hover() {
      this.hovered = true
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.picture {
  height: 200px;
}

.img {
  height: 100%;
  width: 100%;
  max-width: 100px !important;
  max-height: 100px !important;

}

.forms {
  display: flex;
  flex-direction: column;
  gap: 16px;
}


.file {
  width: 100%;
  height: 100%;
}

.btn {
  width: 100%;
  min-height: 40px;
  border-radius: 6px;
}

.pictures {
  display: flex;

  gap: 16px;

  > img {
    width: 50%;
  }
}

.first-col, .second-col {
  display: flex;
  flex-direction: column;
  row-gap: 64px;
}

.input-file-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 21px 0px;
  border: 1px dashed white;
  border-radius: 6px;
}

.document {
  //background: #0F1640;
  //border-radius: 16px;
  width: 100%;
  background: #1D1F22;
  min-height: 465px;
  display: flex;

  column-gap: 20%;
  padding: 33px 48px;

  div {
    min-width: 200px;
  }
}

@media (max-width: 650px) {
  .pictures {
    flex-direction: column;
    align-items: center;
  }
  .document {
    flex-direction: column;
    padding: 10px;
  }
}
</style>
