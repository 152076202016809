<template>
  <div class="trade">
    <div class="left-col">
      <left-column />
    </div>
    <div class="right-col">
      <right-column />
    </div>
  </div>
</template>

<script>
import LeftColumn from "@/components/Profile/Trade/LeftCol/LeftColumn.vue";
import RightColumn from "@/components/Profile/Trade/RightCol/RightColumn.vue";
export default {
  name: "P2P",
  components: { RightColumn, LeftColumn },
};
</script>

<style lang="scss" scoped>
.trade {
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 8px;
  .left-col {
    flex: 2 2 auto;
  }
  .right-col {
    flex: 1 1 auto;
    // max-width: 520px;
  }
}

@media (max-width: 600px) {
  .trade {
    flex-direction: column;
  }
  .right-col {
    order: 1;
  }
  .left-col {
    order: 2;
  }
}
</style>