<template>
  <div class="shop">
    <div class="asset">
      <div class="title">
        Assets Overview
        <img src="@/assets/profile/eye.svg" alt="" />
        <img src="@/assets/Public/seperator.svg" alt="" />
      </div>
      <div class="balance">
        <div class="">
          <span class="gray"> BTC Balance:</span> <span>0.0004</span>
        </div>
        <div class="">
          <span class="gray">USD Balance:</span> <span>3020$</span>
        </div>
      </div>
    </div>
    <div class="shop-header">
      <div class="types">
        <span
          class="gray"
          :class="{ selected: select === 'Limit' }"
          @click="select = 'Limit'"
          >Limit</span
        >
        <span
          class="gray"
          :class="{ selected: select === 'Market' }"
          @click="select = 'Market'"
          >Market</span
        >
        <span
          class="gray"
          :class="{ selected: select === 'Stop-Limit' }"
          @click="select = 'Stop-Limit'"
          >Stop-Limit</span
        >
        <span
          class="gray"
          :class="{ selected: select === 'Stop-Market' }"
          @click="select = 'Stop-Market'"
          >Stop-Market</span
        >
      </div>
      <span>%</span>
    </div>
    <div class="shop-menu">
      <Buy :select="select" class="cont" />
      <Sell :select="select" class="cont" />
    </div>
  </div>
</template>

<script>
import Buy from "@/components/Profile/Trade/RightCol/shop/Buy.vue";
import Sell from "@/components/Profile/Trade/RightCol/shop/Sell.vue";

export default {
  name: "Shop",
  components: { Buy, Sell },
  data() {
    return {
      select: "Market",
    };
  },
};
</script>

<style lang="scss" scoped>
.shop {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.shop-header {
  display: flex;
  justify-content: space-between;
  height: 53px;
  align-items: center;
  padding: 0 9px;
  font-size: clamp(10px, 1.5vw, 14px);
  border-bottom: 1px solid rgba(230, 230, 230, 0.24);

  background: #1d1f22;
  .types {
    display: flex;
    //align-items: center;
    height: 100%;
    gap: 2em;
    span {
      display: flex;
      align-items: center;
    }
  }
}
.selected {
  color: #ffbb38;
  border-bottom: #ffbb38 solid 2px;
}

.asset {
  display: flex;
  height: 48px;
  background: #1d1f22;
  padding: 0 10px;
  align-items: center;
  gap: 10px;

  .title {
    display: flex;
    gap: 8px;
  }

  .balance {
    display: flex;
    width: 60%;
    justify-content: space-between;
    font-size: clamp(10px, 1.2vw, 12px);
  }
}

.shop-menu {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .cont {
    flex: 1 1 auto;
  }
}

@media (max-width: 600px) {
  .shop-menu {
    flex-wrap: wrap;
  }
}
</style>