<template>
<div class="reset">
 <p>Reset Password</p>
 <p>Used for account login.</p>
 <div class="box-container">
<div class="box">  
   <input type="text" placeholder="Old Password" >
<img src="../../../assets/profile/eye.svg" alt="">
</div>
<div class="box">  
   <input type="text" placeholder="New Password" >
<img src="../../../assets/profile/eye.svg" alt="">
</div>
<div class="box">  
   <input type="text" placeholder="Confirm Password" >
<img src="../../../assets/profile/eye.svg" alt="">
</div>

   <button class="btn-primary">Change password</button>
 </div>
</div>
</template>

<script>
export default {
  name: "RestePassword"
}
</script>

<style lang="scss" scoped>
.box-container {
  display: flex;
  flex-wrap: wrap;
  gap:clamp(12px,1vw,24px)
}
.reset {
  display: flex;
  flex-direction: column;
  gap: 12px;
    p:first-child{
    font-size: .9em;
    font-weight: bold;
    margin-bottom: 1em;
  }
  .box{
    flex:1 1 140px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding:16px;
    background: #1D1F22;
border: 1px solid #272A2E;
border-radius: 3px;
height: 50px;
max-width: 299px;
  }
}
button {
  flex:1 1 140px;
  max-width: 223px;
  height: 50px;
}
@media (max-width:400px){
  .box-container {
    flex-direction: column;
    .box, button{
      max-height: 50px;
    }
  }
}
</style>