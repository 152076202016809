<template>
  <div class="">otp mail is here</div>
</template>

<script>
export default {
  name: "OTPMail"
}
</script>

<style scoped>

</style>