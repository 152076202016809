<template>
  <div class="shop-container">
    <div class="best-price">
      <span class="gray">Best Market Price</span>
    </div>
    <div class="Amount" v-if="select === 'Stop-Market'">
      <input type="text" v-model="amount" placeholder="Stop" />
      <div class="unit">
        <span>USDT</span>
        <div class="change-btn">
          <span>+</span>
          <span>-</span>
        </div>
      </div>
    </div>
    <div class="Amount" v-if="select === 'Stop-Limit'">
      <input type="text" v-model="amount" placeholder="Stop" />
      <div class="unit">
        <span>USDT</span>
        <div class="change-btn">
          <span>+</span>
          <span>-</span>
        </div>
      </div>
    </div>
    <div class="Amount" v-if="select === 'Limit'">
      <input type="text" v-model="amount" placeholder="Limit" />
      <div class="unit">
        <span>USDT</span>
        <div class="change-btn">
          <span>+</span>
          <span>-</span>
        </div>
      </div>
    </div>
    <div class="Amount">
      <input type="text" v-model="amount" placeholder="Amount" />
      <div class="unit">
        <span>USDT</span>
        <div class="change-btn">
          <span @click="amount ++">+</span>
          <span @click="decrement>0 ? amount--: amount">-</span>                                                
        </div>
      </div>                
    </div>
    <date-picker placeholder="Expire date" v-if="select != 'Market'" />
    <div class="range">
      <button v-for="(item, index) in range" :key="index">
        {{ item.amount }}
      </button>
    </div>
    <div class="data">
      <div>
        <span>Available:</span>
        <span>0.0000025 USDT</span>
      </div>
      <div>
        <span>Volume:</span>
        <span>0 USDT</span>
      </div>
    </div>
    <button class="green-bg w100" @click="isOrder=true">Buy BTC</button>

    <shop-modal v-if="isOrder" @close='isOrder=false'/>
  </div>
</template>

<script>
import ShopModal from './ShopModal.vue';
export default {
  components: { ShopModal },
  name: "Buy",
  props: ["select"],
  data() {
    return {
      amount: 0,
      isOrder: false,
      range: [
        {
          amount: "25%",
          value: "0.25",
        },
        {
          amount: "50%",
          value: "0.5",
        },
        {
          amount: "75%",
          value: "0.75",
        },
        {
          amount: "100%",
          value: "1",
        },
      ],
    };
  },
  methods: {
    increament() {
      this.amount += 0.01;
    },

    decrement() {
      if (this.amount > 0) {
        this.amount -= 0.001;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.shop-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.range {
  display: flex;
  justify-content: space-between;
  button {
    border-radius: 3px;
    width: 54.12px;
    background: #1d1f22;
    height: 24px;
  }
}
.best-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background: #2e2f31;
  border: 1px solid #272a2e;
  border-radius: 3px;
  height: 36px;
  span {
    font-size: clamp(12px, 2vw, 14px);
  }
}
.Amount {
  padding: 3px 9px;
  input {
    height: 100%;
    width: 60%;
  }
  display: flex;
  justify-content: space-between;
  background: #1d1f22;
  border: 1px solid #272a2e;
  border-radius: 3px;
  height: 34px;
  .unit {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  .change-btn {
    display: flex;
    flex-direction: column;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      width: 19px;
      height: 13px;
      background: #313439;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.01);
      border-radius: 3px;
      cursor: pointer;
    }
    span:first-child {
      margin-bottom: 3px;
    }
  }
}
.green-bg {
  height: 36px;
  border-radius: 3px;
}
.data {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: clamp(10px, 1.5vw, 12px);
  color: var(--gray);
  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    span:last-child {
      text-align: right;
    }
  }
  //.keys,.values{
  //  display: flex;
  //  flex-direction: row;
  //  gap: 8px;
  //}
}
</style>