<template>
  <div >
    <div  class="backdrop-modal"  >
      <div class="modal" @click.self="$emit('close')">
        <div class="box">
          <p>Confirmation</p>

          <span>Please Click the Button for Confirmation of Order</span>
          <button @click="$emit('close')" class="btn-white">Confirm</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.backdrop-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.modal {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box {
  // min-height: 400px;
  border-radius: 3px;
  align-self: center;
  min-width: 392px;
  position: relative;
  display: flex;
  flex-direction: column;

  align-items: space-between;
  gap: 3.2em;
  background: #181a1d;
  border-radius: 4px;
  padding: 15px;
}
button {
  width: 100%;
  min-height: 40px;
}
</style>