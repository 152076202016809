<template>
<div class="">
  <div class="main-profile">
<sidebar/>

 <transition 
 name="out-in"
 enter-active-class="animate__animated animate__fadeInLeft"
 leave-active-class = "animate__animated animate__fadeOutLeft"
 >
    <router-view/>
 </transition>




  </div>
  <Footer/>
</div>
</template>

<script>
import Sidebar from "@/components/Profile/ProfileNavbar";
import Footer from "@/components/Public/Footer";

export default {
  name: "Profile",
  components: {Sidebar, Footer},
}
</script>

<style lang="scss" scoped>
.main-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
}


</style>