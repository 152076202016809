<template>
  <div class="container">
    <transition
      name="out-in"
      enter-active-class="animate__animated animate__fadeInRight"
      leave-active-class="animate__animated animate__fadeOutRight"
    >
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Wallet",
};
</script>

<style scoped>
.container {
  padding: clamp(30px, 10vw, 50px) 0;
  display: flex;
  flex-direction: column;
}

@media (max-width: 400px) {
  .container {
    padding: 12px 8px;
  }
}
</style>