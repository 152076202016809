<template>
  <div class="deposit">
    <div class="menu">
      <a
        class="item"
        @click.prevent="crypto = true"
        :class="{ active: crypto === true }"
        >Deposit Crypto</a
      >
      <a
        class="item"
        @click.prevent="crypto = false"
        :class="{ active: crypto === false }"
        >Deposit Fiat</a
      >
    </div>

    <div class="content-container">
      <for-spot v-if="crypto === true" />
      <for-fiat v-if="crypto === false" />
    </div>
  </div>
</template>

<script>
import ForFiat from "@/components/Profile/Wallet/overview/deposit-components/forFiat.vue";
import ForSpot from "@/components/Profile/Wallet/overview/deposit-components/forSpot.vue";
export default {
  name: "Tomandiposit",
  components: { ForFiat, ForSpot },
  data() {
    return {
      crypto: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.active {
  height: 100%;
  color: #ffbb38;
  border-bottom: 3px solid #ffbb38;
}
.deposit {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.menu {
  display: flex;
  gap: 40px;
  border-bottom: solid 1px rgba(230, 230, 230, 0.32);
  height: 35px;
}
</style>