<template>
<div class="">
  <div class="container">
    <div class="box">
      <h1>FAQ</h1>
      <div class="contents">
        <div class="col" >
        <div class="inner" v-for="(item,index) in colOne" :key='index' >
          <h6>{{item.title}}</h6>
          <p>{{item.text}}</p>
        </div>

        </div>
        <div class="col">
          <div class="inner" v-for="(item,index) in colTwo" :key='index' >
            <h6>{{item.title}}</h6>
            <p>{{item.text}}</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "FAQ",
  data(){
    return{
      colOne:[
        {
          title:'What is Bitcoin?',
          text:'Bitcoin is a decentralized digital currency, meaning that it lacks a central bank or single administrator. Bitcoin can be sent from user to user through the peer-to-peer network without the need for intermediaries.',

        },
        {
          title:'Can I start trading with just $1?',
          text:'Omani allows users to improve their practical experience through a variety of trading and financial products for as low as $1.',

        }
      ],
      colTwo:[
        {
          title:'Is Omani safe?',
          text:'Omani boasts one of the world\'s most sophisticated security technology and maintenance team, and is constantly upgrading our security systems to ensure the safety of user assets and accounts.',

        },
        {
          title:'Is there an exchange limit between fiat and crypto?',
          text:'Omani has no restrictions on the exchange between fiat and crypto, and supports over 50 fiat currencies through our P2P market and credit/debit card channel.',

        }
      ],
    }
  }
}
</script>










<style lang="scss" scoped>
.contents{
  display: flex;
column-gap: 9%;


}
.inner{
  display: flex;
  flex-direction: column;
  gap: 12px;

}
.box{
  display: flex;
  flex-direction: column;
gap: 52px;
  h1{
    align-self: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
}
.col{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  //margin-bottom: 1rem;
  h6{
    font-size: clamp(0.75em,2vw,1.5em);
    font-weight: 600;
  }
  p{
    color: var(--gray);
  }
}


@media (max-width: 768px) {
  .contents{
    flex-direction: column;
  }
  .col{
    flex-direction: column;
  }
  .inner{
    flex-direction: column;
  }
}



</style>