<!-- <template>
  <div class="navbar">
    <div class="container">
      <div class="nav">
        <div class="images">
          <a class="menu-image" @click.prevent="mobile = !mobile"><img alt="" src="../../assets/Public/menu.png"> </a>
          <a @click.prevent="$router.push('/')"><img id="img1" alt="" src="../../assets/Public/Logo.png"></a>
          <img id="img2" alt="" src="../../assets/Public/lil-logo.png">
        </div>
        <ul class="navigation">
          <li><a @click.prevent="$router.push('/market')">{{ nav.MarketsTitle }}</a></li>
          <li><a @click.prevent="$router.push('/guide')">{{ nav.GuideTitle }}</a></li>
          <li><a @click.prevent="$router.push('/fees')">{{ nav.FeesTitle }}</a></li>
          <li><a @click.prevent="$router.push('/blog')">{{ nav.BlogTitle }}</a></li>
        </ul>

      </div>
      <div class="btns">
        <div class="auth-btn">
          <button :class="{'active-btn' : $route.name === 'Login'}" class="btn" @click.prevent="$router.push('/login')">
            {{ nav.LoginBtn }}
          </button>
          <button :class="{'active-btn' : $route.name === 'Signup'}" class="btn"
                  @click.prevent="$router.push('/sign-up')">{{ nav.SignupBtn }}
          </button>
        </div>
        <div class="lang">
          <button class="lang-btn" @click.prevent="drop=!drop">

            <span id="lang">{{ headerlang.title }}</span>
            <img :src=headerlang.image alt="">
            <inline-svg
                :src="require('../../assets/Public/selector.svg')"
            ></inline-svg>
          </button>
          <div v-if="drop" :class="{'rtl': state.lang === 'fa', 'ltr': state.lang ==='en'}" class="lang-dropdown">
            <span v-for="(lang,index) in langMenu" :key="index"
                  @click.prevent="selectItem(index)">{{ lang.title }}</span>
          </div>

        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="mobile && state.lang==='fa'" class="mobile">
        <ul class="mobile-nav">
          <li><a @click.prevent="$router.push('/market') && (mobile===false)">{{ nav.MarketsTitle }}</a></li>
          <li><a @click.prevent="$router.push('/guide')">{{ nav.GuideTitle }}</a></li>
          <li><a @click.prevent="$router.push('/fees')">{{ nav.FeesTitle }}</a></li>
          <li><a @click.prevent="$router.push('/blog')">{{ nav.BlogTitle }}</a></li>
        </ul>
      </div>
      <div v-if="mobile && state.lang==='en'" class="mobile-ltr">
        <ul class="mobile-nav">
          <li><a @click.prevent="$router.push('/market') && (mobile===false)">{{ nav.MarketsTitle }}</a></li>
          <li><a @click.prevent="$router.push('/guide')">{{ nav.GuideTitle }}</a></li>
          <li><a @click.prevent="$router.push('/fees')">{{ nav.FeesTitle }}</a></li>
          <li><a @click.prevent="$router.push('/blog')">{{ nav.BlogTitle }}</a></li>
        </ul>
      </div>
    </transition>

  </div>

</template> -->



<template>
  <div class="navbar">
    <div class="menu">
      <a href="home" @click.prevent="$go('/')">
        <img src="@/assets/Public/logo.svg" alt=""
      /></a>
      <div class="" v-for="(item, index) in navMenu" :key="index">
        <a
          class="nav-item"
          :href="item.title"
          @click.prevent="$go(item.link)"
          >{{ item.title }}</a
        >
      </div>
    </div>
    <div class="hambergur" @click.prevent="isMobile = !isMobile">
      <div></div>
      <div></div>
      <div></div>
    </div>

    <transition
      name=""
      enter-active-class="animate__animated animate__bounceInLeft"
      leave-active-class="animate__animated animate__bounceOutLeft"
    >
      <div class="mobile-nav" v-if="isMobile">
        <span v-for="(item, index) in navMenu" :key="index" class="links">
          <a
            :href="item.title"
            @click.prevent="
              $go(item.link);
              isMobile = flase;
            "
            >{{ item.title }}</a
          >
        </span>
        <!-- <img src="@/assets/Public/logo.svg" alt="" class="img"> -->
      </div>
    </transition>

    <div class="auth">
      <div class="notoken" v-if="!state.token">
        <button class="btn-white" @click.prevent="$go('/login')">Log In</button>
        <button class="btn-primary" @click.prevent="$go('/sign-up')">
          Sign Up
        </button>
      </div>
      <span v-if="state.token" @click.prevent="$go('/profile/wallet')"
        >Wallet</span
      >
      <img
        v-if="state.token"
        @click.prevent="$go('/profile/notif')"
        src="../../assets/profile/notif.svg"
        alt=""
      />
      <img
        v-if="state.token"
        @click.prevent="$go('/profile')"
        src="../../assets/profile/profile.svg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
// import en from "@/Lib/multiLang/en-lang/en";
// import fa from "@/Lib/multiLang/fa-lang/fa"

export default {
  name: "NavbarComponent",
  data() {
    return {
      isMobile: false,
      navMenu: [
        {
          title: "Buy Crypto",
          link: "/OTC",
        },
        {
          title: "Markets",
          link: "/market",
        },
        {
          title: "Trades",
          link: "/trade",
        },
        {
          title: "Guide",
          link: "/guide",
        },
      ],

      // selectLang :'',
      // iconLang :'',
      drop: false,
    };
  },
  mounted() {},
  methods: {
    selectItem(Index) {
      // this.headerlang = this.langMenu[Index]
      // this.state.lang = this.langMenu[Index].value
      // this.state.lan = (this.state.lang === 'fa') ? fa : en
      // this.nav = this.state.lan.nav
      // this.drop = !this.drop
      // // this.lang = this.langMenu[Index].value
    },
  },
};
</script>

<style lang="scss" scoped>
.notoken {
  display: flex;
  gap: 16px;
}

.navbar {
  position: relative;
  z-index: 10;
  display: flex;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(30px);
  height: 56px;
  justify-content: space-between;
  padding: 0 clamp(8px, 5vw, 24px);

  .auth {
    display: flex;
    align-items: center;
    gap: clamp(8px, 1vw, 16px);
    justify-content: flex-end;
    cursor: pointer;
  }
}
.menu {
  position: relative;
  display: flex;
  align-items: center;
  gap: 48px;
}

.nav-item {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;

    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    &:before {
      transform: scaleX(1);
    }
  }
}

.hamburger {
  display: none;
}

.mobile-nav {
  display: none;
  z-index: 2;
}

//transitions
// .fadeHeight-enter-active,
// .fadeHeight-leave-active {
//   transition: all 0.9s;
//   max-height: 230px;
// }
// .fadeHeight-enter,
// .fadeHeight-leave-to {
//   opacity: 0;
//   max-height: 0px;
// }

@media screen and (max-width: 760px) {
  .menu {
    display: none;
  }
  .btn-primary,
  .btn-white {
    width: 80px;
    font-size: 12px;
  }
  .mobile-nav {
    z-index: 2;
    height: calc(100vh);
    position: fixed;
    top: 3.5rem;
    gap: 2em;
    left: 0;
    padding: 5px 10px;
    // font-size: 0.7em;
    display: flex;
    flex-direction: column;
    width: 100%;
    opacity: 1 !important;
    background-color: #000000;
    background-image: linear-gradient(315deg, #000000 0%, #414141 74%);
    .mobinav {
      display: flex;
      flex-direction: column;
    }
  }

  .links {
    margin-top: 20px;
    font-weight: 500;
  }

  .hambergur {
    align-self: center;
    width: 1.6rem;
    div {
      width: 1.6rem;
      height: 3px;
      border: 1px solid #fff;
      margin-bottom: 5px;
      border-radius: 5px;

      &:nth-child(2) {
        width: 1.3rem;
        background: #fff;
      }
    }
  }
}
</style>