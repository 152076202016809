<template>
  <div class="">
    <div class="container">
      <div class="struct">
        <div class="community"><Community/></div>
        <div class="market-place"><Marketplace/></div>
        <div class="wallets"><Wallets/></div>
      </div>
    </div>
  </div>
</template>

<script>
import Community from "@/components/Home-page/support/Community";
import Marketplace from "@/components/Home-page/support/Marketplace";
import Wallets from "@/components/Home-page/support/Wallets";
export default {
name:'Support',
  components: {Wallets, Marketplace, Community},
}
</script>

<style lang="scss" scoped>
.struct{
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  flex-direction: column;
  gap: clamp(1em ,2vw,2em);
  flex-wrap: wrap;
}

.community{

}

</style>