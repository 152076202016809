<template>
  <div class="box-container">
    <div class="box">
      <div class="texts">
        <h3>{{boxOne.title}}</h3>
        <span>{{boxOne.text}}</span>
        <button @click.prevent=" $go('/login') ">{{boxOne.button}} <img :src="require('../../../assets/Public/join.png')" alt=""></button>
      </div>
      <img class="pic" src="../../../assets/Public/community.svg" alt="">
    </div>
    <div class="box">
      <div class="texts">
        <h3>{{boxTwo.title}}</h3>
        <span>{{boxTwo.text}}</span>
        <button @click.prevent="!state.token ? $go('/login') : $go('/trade') ">{{boxTwo.button}} <img :src="require('../../../assets/Public/join.png')" alt=""></button>
      </div>
      <img class="pic" src="../../../assets/Public/community.svg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "Community",
  data() {
    return {
      boxOne: {
        title: 'Community',
        text: 'Join the biggest community on Terra and earn LOOPR tokens for creating great content, engaging with others, and learning about the Terra ecosystem.'
     ,
        button:'Join Now',

      },
      boxTwo:{
        title:"Crypto Exchange (DEX)",
        text:"Trade a range of tokens on our DEX or provide liquidity to earn swap fees and LOOP tokens from our yield farms!",
        button:"Trade Now"
      }
    };
  }
}
</script>

<style lang="scss" scoped>
.box-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}

.box {
  position: relative;
  height: 564px;
  display: flex;
  background-color: #1C1C1C;
  border-radius: 2em;
  flex-direction: column;
  flex: 1 1 300px;
  padding: clamp(1.5em, 2vw, 2.5em);

  h3{
font-weight: 600;
    //line-height: 50px;
  }

  .texts{
    display: flex;
    flex-direction: column;
    gap: 16px;
gap: 16px;
    button{

      display: flex;
      align-self: flex-start;
      font-size: clamp(0.75em, 1vw, 1.25em);
      font-weight: 600;
      gap:8px;
      img{
        align-self: center;
      }
    }
  }

  .pic{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

}


</style>