<template>

<div class="container">
  <div class="contents" v-if="forget">


    <div class="first-row">
      <div class="title">
        <div class="title-text">
          <h4>Forget Password</h4>
          <!--          <p class="deactive">{{ this.state.lan.login.GoogleTitle }}</p>-->
          <!--          <button>-->
          <!--&lt;!&ndash;            <inline-svg :src="require('../../assets/Authentication/google.svg')"/>&ndash;&gt;-->
          <!--&lt;!&ndash;            <span>{{ this.state.lan.login.GoogleBtn }}</span>&ndash;&gt;-->
          <!--          </button>-->
        </div>
      </div>
    </div>
    <div class="login">

      <div class="second-row">
        <div class="mail">
          <label>Email:</label>
          <div class="email-cont">
            <input placeholder="Your email address" name="email" v-model="email">
            <button class="btn-primary" @click="submition">Send Code</button>
          </div>
        </div>
        <div class="pass">
          <label>Email Verification Code:</label>
          <input class="pass" placeholder="OTP" name="pass" type="password" v-model="otp.code">
        </div>
      </div>
      <button class="btn-primary" @click="forget=false" :disabled="status ===false"  >
        Verify Email
      </button>


      <div class="forget">
        <span><a class="yellow" @click.prevent="$go('/login')">back to Login</a></span>

      </div>


    </div>

  </div>
  <div class="contents" v-else>


    <div class="first-row">
      <div class="title">
        <div class="title-text">
          <h4> Reset your password</h4>
          <!--          <p class="deactive">{{ this.state.lan.login.GoogleTitle }}</p>-->
          <!--          <button>-->
          <!--&lt;!&ndash;            <inline-svg :src="require('../../assets/Authentication/google.svg')"/>&ndash;&gt;-->
          <!--&lt;!&ndash;            <span>{{ this.state.lan.login.GoogleBtn }}</span>&ndash;&gt;-->
          <!--          </button>-->
        </div>
      </div>
    </div>
    <div class="login">

      <div class="second-row">
        <div class="mail">
          <label>Password:</label>
          <div class="email-cont">
            <input placeholder="Your email address" name="email" v-model="otp.password">
          </div>
        </div>
        <div class="pass">
          <label>Repeat Password:</label>
          <input class="pass" placeholder="OTP" name="pass" type="password" v-model="repeat">
        </div>
      </div>
      <button class="btn-primary" @click.prevent="submit"   :disabled="status ===false"  >
        Submit
      </button>


      <div class="forget">
        <span><a class="yellow" @click.prevent="$go('/login')">back to Login</a></span>

      </div>


    </div>

  </div>

</div>

</template>

<script>
export default {
  name: "Login",
  data(){

    return {
      status:false,
      forget:true,
      email: '',
      repeat:'',
      otp: {
        code: "",
        email: this.state.userInfo.email || this.$cookies.get("username") || "",
        mobile: this.state.userInfo.mobile || this.$cookies.get("mobile") || "",
        password: "",
      },
      data: {
        OTP: '',
        code: "",
        email: this.state.userInfo.email || this.$cookies.get("username") || "",
        mobile: this.state.userInfo.mobile || this.$cookies.get("mobile") || "",
        password: "",
      }
    }
  },
  methods:{
    async  checkMail(){
      if(!this.$validEmail(this.email) || !this.email){
        this.emailError = 'Incorrect Email Address'
        this.$error('Incorrect Email','Please Enter a Correct Email','','','','2500')
        return Promise.reject('repeat failed : ' + this.email)
      }
      else return this.emailError = ''

    },
    async checkPassword() {
      if (!this.repeat || !this.otp.password) {
        this.passError = "رمز اجباری می باشد";
        this.$error("", this.passError);
      } else if (this.repeat !== this.otp.password) {
        this.passError = "رمز های عبور باید یکی باشند";
        this.$error("خطای تکرار", this.passError);
      } else {
        if (this.otp.password.length < 8) {
          this.passError = "رمز حداقل باید 8 کارکتر باشد";
          this.$error("", this.passError);
        } else if (!this.$validPass(this.otp.password)) {
          this.passError = "رمز باید ترکیبی از حرف بزرگ و کوچک باشد";
          this.$error("", this.passError);
        } else {
          this.isPasswordCorrect =true;
          this.countDownTimer()
          this.now = DateTime.local().set({milliseconds: 0});
          this.end = DateTime.local()
              .set({milliseconds: 0})
              .plus({seconds: 5});

          this.tick = setInterval(() => {
            this.now = DateTime.local().set({milliseconds: 0});
          }, 1000);
          return (this.passError = "");
        }
      }
    },
    async submit() {
      // await this.checkPassword();

      if (!this.passError) {
        const res = await this.$axios.post("/users/reset-password/", this.otp);
        if (res) {
          this.$error("password changed", "", "success");
          await this.$router.push("/login");
        }
      }
    },

    async submition(){
      this.checkMail()
      if(!this.emailError){
        const res = await this.$axios.post('/users/forget-password?email=' + this.email)
        if (res.message === 'code sent successfully.') {
          this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
          this.state.userInfo.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType;
          this.status=true;
        } else {
          this.state.userInfo.towStepAuthenticationType = 'EMAIL'
          this.status=true;
        }

        this.state.userInfo.email =this.email
        // await this.$router.push({name:'Newpass'})

        this.$error('OTP SENT','Please Enter OTP and submit','','','','2500')


      }



    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  max-width: 456px;
}
.pass{

input{
  border: 1px solid #272A2E;
  border-radius: 3px;
  //padding: 1em;
  height: 38px;

  justify-content: space-between;
  display: flex;
}
}
.contents {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
}

.forget {
  display: flex;
  justify-content: space-between;
  font-size: clamp(.5em, 1.5vw, .8em);

  .yellow {
    color: #A1792B;
  }
}

.btn-primary {
  width: 100%;
  height: 50px;
}

.login {
  width: 100%;
  max-width: 500px;
  padding: 2em;
  background: #181A1D;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  //padding: 12px 24px;
  gap: 2em;

  //first row contains title and google login
  .first-row {
    //main title
    .title {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .title-text {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      button {
        display: flex;
        border-radius: 4px !important;
        align-items: center;
        font-size: clamp(10px, 1vw, 12px);
        font-weight: 400;
        background: #181A1D;
        border: 1px solid rgba(178, 176, 176, 0.5);
        height: 38px;
        padding: 0 12px;
      }

      span {
        margin-right: 27%;
      }
    }

    //texts of title
    .text {

    }
  }

  //second row contains inputs
  .second-row {
    display: flex;
    flex-direction: column;
    gap: 2.5em;

    .mail, .pass {
      font-weight: 500;
      gap: .7em;
      display: flex;
      flex-direction: column;
      //row-gap: 1.5em;
    .email-cont{
      border: 1px solid #272A2E;
      border-radius: 3px;
      button{
        height: 38px !important;
      }
      //padding: 1em;
      height: 38px;
      justify-content: space-between;
      display: flex;
      .btn-primary{
        width: 30%;
        font-size: clamp(0.3em,1.2vw,.6em);
        align-self: center;
        height: 50px;
      }


    }

      input::placeholder {
        color: var(--gray);
      }
    }
  }


}


//passed some style to deactive text
.deactive {
  font-size: clamp(10px, 1vw, 13px);
}
</style>