<template>
  <div class="order-book">
    <p>Order Book</p>
    <div class="item">
      <div class="head">
        <span>Price(USDT)</span>
        <span>Amount({{ "BTC" }})</span>
        <span>Total</span>
      </div>

      <div v-if="!sellOrderBook.length && !buyOrderBook.length" class="noData">
        <p class="text-no-data">There is no information to display</p>
      </div>

      <div
        class="buy-section"
        v-if="sellOrderBook !== null && buyOrderBook !== null"
      >
        <div
          class="sell-section"
          v-if="sellOrderBook !== null && buyOrderBook !== null"
        >
          <div
            v-for="(item, index2) in sellOrderBook"
            :key="index2"
            :class="{ disable: item.myOrder }"
            :style="{
              backgroundImage: `linear-gradient(-90deg  , rgba(254, 42, 42, 0.08) ${item.executedPercent}%, transparent ${item.executedPercent}%)`,
            }"
            class="prog"
          >
            <div>{{ $toLocal(item.unitPrice, 2) }}</div>
            <div>{{ $toLocal(item.volume, $decimal[$route.params.coin]) }}</div>
            <div style="color: #fe2a2a">{{ $toLocal(item.wholePrice, 2) }}</div>
          </div>
        </div>
      </div>

      <div v-if="sellOrderBook.length && buyOrderBook.length" class="border">
        <!--      <p class="totla"> 0 </p>-->
        <div class="total">0 <small class="gray"> ≈ 0 USD</small></div>
      </div>

      <div
        class="buy-section"
        v-if="sellOrderBook !== null && buyOrderBook !== null"
      >
        <div
          v-for="(item, index) in buyOrderBook"
          :key="index[0]"
          :class="{ disable: item.myOrder }"
          :style="{
            backgroundImage: `linear-gradient(-90deg  , rgba(0, 183, 106, 0.13) ${item.executedPercent}%, transparent ${item.percent}%)`,
          }"
          class="prog"
        >
          <div>{{ $toLocal(item.unitPrice, 2) }}</div>
          <div>{{ $toLocal(item.volume, $decimal[$route.params.coin]) }}</div>
          <div style="color: #00a35f">{{ $toLocal(item.wholePrice, 2) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "the-progresive-bar",

  //------------------------------------Data

  data() {
    return {
      type: "",

      sellOrderBook: [],

      buyOrderBook: [],
    };
  },

  methods: {
    marketType() {
      this.type = "BITCOIN_TETHER";
    },

    async getTrade() {
      const res = await this.$axios.get(
        "/orders/order-book?marketType=" + this.type
      );
      this.sellOrderBook = res.sellOrderBook;
      this.buyOrderBook = res.buyOrderBook;
    },
  },

  mounted() {
    this.marketType();
    this.getTrade();
  },
};
</script>

<style lang="scss" scoped>
.order-book {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 256px;
  background: #1d1f22;
  border-radius: 4px;
  max-width: 256px;
  padding: 8px;
}
.disable {
  opacity: 0.5;
}
.head {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;

  span {
    color: rgba(189, 189, 189, 1);
    font-size: clamp(10px, 1.5vw, 12px);
  }
}

.buy-section {
  // height: 350px;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sell-section {
  // height: 350px;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.border {
  display: flex;
  justify-content: center;
  // margin: 50px 0;
}
.total {
  width: 100%;
  border-top: 1px solid rgba(189, 189, 189, 0.22);
  border-bottom: 1px solid rgba(189, 189, 189, 0.22);
}

.prog {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 2px;
  color: rgba(72, 77, 86, 1);
  font-size: clamp(10px, 1.5vw, 12px);
  height: 18px;

  div {
    background-color: transparent;
    flex-grow: 1;
    display: flex;
    justify-content: space-around;
  }
}

.totla {
  text-align: center;
  color: rgb(74, 163, 113);
  font-weight: 600;
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.text-no-data {
  color: red;
  font-size: clamp(12px, 2vw, 16px);
  font-weight: bold;
}
</style>