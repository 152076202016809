var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"crypto-deposit"},[(_vm.isGuide === true)?_c('div',{staticClass:"guide"},[_c('span',{staticClass:"close",on:{"click":function($event){$event.preventDefault();_vm.isGuide = false}}},[_vm._v(" X ")]),_vm._l((_vm.guide),function(item,index){return _c('div',{key:index,staticClass:"guide-box"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"number"},[_vm._v(_vm._s(item.number))]),_c('p',[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"text"},[_c('p',[_vm._v(_vm._s(item.content))])])])})],2):_vm._e(),_c('div',{staticClass:"main"},[_c('div',{staticClass:"deposit-box"},[_c('div',{},[_c('span',[_vm._v("Select Coin")]),_c('div',{staticClass:"coin-box"},[_c('button',{staticClass:"drop-btn",on:{"click":function($event){_vm.dropdown = !_vm.dropdown}}},[_c('span',{staticClass:"coin-info"},[_c('img',{attrs:{"src":require(("@/assets/Coins/" + (_vm.sourceCoin.code) + ".png")),"width":"20","height":"20"}}),_vm._v(" "+_vm._s(_vm.sourceCoin.code))]),_c('svg',{attrs:{"width":"14","height":"8","viewBox":"0 0 14 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.49497 0.505025C1.22161 0.231658 0.778392 0.231658 0.505025 0.505025C0.231658 0.778392 0.231658 1.22161 0.505025 1.49497L1.49497 0.505025ZM7 7L6.50503 7.49497C6.77839 7.76834 7.22161 7.76834 7.49497 7.49497L7 7ZM13.495 1.49497C13.7683 1.22161 13.7683 0.778392 13.495 0.505025C13.2216 0.231658 12.7784 0.231658 12.505 0.505025L13.495 1.49497ZM0.505025 1.49497L6.50503 7.49497L7.49497 6.50503L1.49497 0.505025L0.505025 1.49497ZM7.49497 7.49497L13.495 1.49497L12.505 0.505025L6.50503 6.50503L7.49497 7.49497Z","fill":"#7A8083"}})])]),(_vm.dropdown)?_c('div',{staticClass:"dropdown"},_vm._l((_vm.coins.filter(
                function (coin) { return coin.coin !== 'TOMAN'; }
              )),function(coin,index){return _c('span',{key:index},[_c('img',{attrs:{"src":require(("@/assets/Coins/" + (coin.code) + ".png")),"alt":"","width":"18","height":"18"}}),_c('span',{on:{"click":function () {
                    _vm.getQR(coin.coin);
                    _vm.isNetSelect = true;
                    _vm.dropdown = !_vm.dropdown;
                    _vm.sourceCoin = coin;
                  }}},[_vm._v(" "+_vm._s(coin.code)+" ")])])}),0):_vm._e()])]),(_vm.isNetSelect)?_c('div',{},[_c('span',[_vm._v("Select network")]),_c('div',{staticClass:"networks"},[_c('button',{staticClass:"drop-btn",on:{"click":function($event){_vm.networkSelector = !_vm.networkSelector}}},[_c('span',[_vm._v(_vm._s(_vm.selectNet))]),_c('svg',{attrs:{"width":"14","height":"8","viewBox":"0 0 14 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.49497 0.505025C1.22161 0.231658 0.778392 0.231658 0.505025 0.505025C0.231658 0.778392 0.231658 1.22161 0.505025 1.49497L1.49497 0.505025ZM7 7L6.50503 7.49497C6.77839 7.76834 7.22161 7.76834 7.49497 7.49497L7 7ZM13.495 1.49497C13.7683 1.22161 13.7683 0.778392 13.495 0.505025C13.2216 0.231658 12.7784 0.231658 12.505 0.505025L13.495 1.49497ZM0.505025 1.49497L6.50503 7.49497L7.49497 6.50503L1.49497 0.505025L0.505025 1.49497ZM7.49497 7.49497L13.495 1.49497L12.505 0.505025L6.50503 6.50503L7.49497 7.49497Z","fill":"#7A8083"}})])]),(_vm.networkSelector)?_c('div',{staticClass:"network"},_vm._l((_vm.network),function(net,index){return _c('span',{key:index,on:{"click":function () {
                  _vm.networkSelector = !_vm.networkSelector;
                  _vm.selectNet = net.title;
                }}},[_vm._v(" "+_vm._s(net.title))])}),0):_vm._e()])]):_vm._e(),(_vm.isNetSelect)?_c('div',{staticClass:"address"},[(_vm.alertLink)?_c('p',{staticClass:"copy"},[_vm._v("Copied")]):_vm._e(),_c('span',[_vm._v("Address")]),_c('div',{staticClass:"address-container"},[_c('span',[_vm._v(_vm._s(_vm.address))]),_c('img',{attrs:{"src":require("@/assets/profile/copywallet.png"),"alt":""},on:{"click":_vm.copyLink}}),_c('img',{attrs:{"src":require("@/assets/profile/qr.png"),"alt":""},on:{"click":function($event){_vm.isQRCODE = !_vm.isQRCODE}}})])]):_vm._e(),_c('div',{staticClass:"faq"},[_c('div',{staticClass:"col"},[_vm._m(0),_c('div',{},[_c('span',{staticClass:"title"},[_vm._v("Minimum deposit")]),_c('div',{staticClass:"span"},[_vm._v(" "+_vm._s(_vm.sourceCoin.tokenTypeDetails[0].minWithdraw)+" ")])])]),_vm._m(1)]),(_vm.isNetSelect)?_c('div',{staticClass:"warning"},[_c('ul',[_c('li',[_vm._v("Send Only "+_vm._s(_vm.sourceCoin.code)+" to this Address")]),_c('li',[_vm._v("Ensure the network is "+_vm._s(_vm.selectNet))]),_vm._m(2)])]):_vm._e()])]),_c('div',{staticClass:"table"},[_c('p',[_vm._v("Recent Deposits")]),_c('div',{staticClass:"table-container"},[_c('table',[_vm._m(3),_vm._l((_vm.tableContents),function(item,index){return _c('tr',{key:index,staticClass:"tr-body"},[_c('td',{staticClass:"tablet"},[_vm._v(_vm._s(item.time))]),_c('td',[_vm._v(_vm._s(item.type))]),_c('td',[_vm._v(_vm._s(item.amount))]),_c('td',{staticClass:"tablet"},[_vm._v(_vm._s(item.DepositWallet))]),_c('td',{staticClass:"mobile"},[_vm._v(_vm._s(item.Destination))]),_c('td',{staticClass:"tablet"},[_vm._v(_vm._s(item.TxID.slice(0, 9) + "..."))]),_c('td',{class:{
              red: item.Status === 'Failed',
              green: item.Status === 'Completed',
              yellow: item.Status === 'Pending',
            }},[_vm._v(" "+_vm._s(item.Status)+" ")])])})],2)])]),(_vm.isQRCODE)?_c('QRCODE',{attrs:{"qrCode":_vm.qrCode,"sourceCoin":_vm.sourceCoin,"selectNet":_vm.selectNet},on:{"closeModaleEmit":function($event){_vm.isQRCODE = !_vm.isQRCODE}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('span',{staticClass:"title"},[_vm._v("Expected arrival")]),_c('span',[_vm._v("1 network confirmations")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col"},[_c('div',{},[_c('span',{staticClass:"title"},[_vm._v("Expected unlock")]),_c('span',[_vm._v("2 network confirmations")])]),_c('div',{},[_c('span',{staticClass:"title"},[_vm._v("Selected wallet")]),_c('span',{staticClass:"links"},[_vm._v("spot Wallet "),_c('span',{staticClass:"yellow"},[_vm._v("Change")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_vm._v(" Do not send NFT to this Address. "),_c('span',{staticClass:"yellow"},[_vm._v("learn how to deposit NFT")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"tr-head"},[_c('td',{staticClass:"tablet"},[_vm._v("Time")]),_c('td',[_vm._v("Type")]),_c('td',[_vm._v("Amount")]),_c('td',{staticClass:"tablet"},[_vm._v("Deposit Wallet")]),_c('td',{staticClass:"mobile"},[_vm._v("Destination")]),_c('td',{staticClass:"tablet"},[_vm._v("TxID")]),_c('td',[_vm._v("Status")])])}]

export { render, staticRenderFns }