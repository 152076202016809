<template>
  <div class="left-col">
    <div class="header">
      <div class="coin-selector">
        <img
          id="coin-img"
          src="../../../../assets/Coins/BTC.png"
          alt=""
          width="20"
          height="20"
        />
        <span>{{ tradeFrom }} / {{ tradeTo }}</span>
        <img src="../../../../assets/Public/selector.svg" alt="" />
      </div>
      <div class="info">
        <div class="cap">
          <span>Price:</span>
          <span class="light-green"
            >43,382.4 <small class="green">+13.13</small></span
          >
        </div>

        <div class="cap">
          <span>24H High:</span>
          <span>44,210.4</span>
        </div>

        <div class="cap">
          <span>24H Low:</span>
          <span>37,928.4</span>
        </div>

        <div class="cap">
          <span>24H Amount:</span>
          <span>14,767.4</span>
        </div>

        <div class="cap">
          <span>24H Volume:</span>
          <span>612,617,853.30</span>
        </div>
      </div>
    </div>
    <vue-trading-view
      :options="{
        symbol:
          tradeFrom !== 'TETHER'
            ? 'BINANCE:' + $coinUnit[tradeFrom] + 'USDT'
            : 'BINANCEUS:USDTUSD',
        height: '491px',
        width: '100%',
        theme: 'dark',
        timezone: 'US/Los_Angeles',
        style: '1',
        hide_top_toolbar: true,
        hide_legend: true,
        hide_bottom_toolbar: false,
        hide_legend: false,
        locale: 'en_US',
        enable_publishing: false,
        withdateranges: true,
        range: 'ytd',
        hide_side_toolbar: true,
        allow_symbol_change: true,
        enabled_features: ['header_fullscreen_button'],
        disabled_features: [
          'border_around_the_chart',
          'remove_library_container_border',
        ],
        loading_screen: {
          backgroundColor: 'black',
          foregroundColor: 'black',
        },
        overrides: {
          background: 'black',
        },
      }"
    />
    <trade-history />
  </div>
</template>

<script>
import { tradeFrom, tradeTo } from "@/library/reuseableFunction";
import VueTradingView from "vue-trading-view/src/vue-trading-view.vue";
import TradeHistory from "@/components/Profile/Trade/LeftCol/TradeHistory.vue";

export default {
  name: "LeftColumn",
  components: {
    TradeHistory,
    VueTradingView,
  },
  computed: {
    tradeTo,
    tradeFrom,
  },
};
</script>

<style lang="scss" scoped>
#coin-img {
  align-self: center;
}
.left-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.header {
  width: 100%;
  display: flex;
  height: 53px;
  background: #1d1f22;
  align-items: center;
  gap: 12%;
  padding: 0 3%;
  .coin-selector {
    display: flex;
    gap: 8px;
  }
}

.info {
  display: flex;
  width: 100%;
  max-width: 600px;
  gap: 5%;
  .cap {
    gap: 2%;
    display: flex;
    flex-direction: column;
    font-size: clamp(12px, 1.2vw, 14px);
    span:first-child {
      font-size: clamp(10px, 2vw, 12px);
      color: #7a8083;
    }
  }
}

@media (max-width: 600px) {
  .header {
    display: none;
  }
}
</style>