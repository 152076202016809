var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left-col"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"coin-selector"},[_c('img',{attrs:{"id":"coin-img","src":require("../../../../assets/Coins/BTC.png"),"alt":"","width":"20","height":"20"}}),_c('span',[_vm._v(_vm._s(_vm.tradeFrom)+" / "+_vm._s(_vm.tradeTo))]),_c('img',{attrs:{"src":require("../../../../assets/Public/selector.svg"),"alt":""}})]),_vm._m(0)]),_c('vue-trading-view',{attrs:{"options":{
      symbol:
        _vm.tradeFrom !== 'TETHER'
          ? 'BINANCE:' + _vm.$coinUnit[_vm.tradeFrom] + 'USDT'
          : 'BINANCEUS:USDTUSD',
      height: '491px',
      width: '100%',
      theme: 'dark',
      timezone: 'US/Los_Angeles',
      style: '1',
      hide_top_toolbar: true,
      hide_legend: true,
      hide_bottom_toolbar: false,
      hide_legend: false,
      locale: 'en_US',
      enable_publishing: false,
      withdateranges: true,
      range: 'ytd',
      hide_side_toolbar: true,
      allow_symbol_change: true,
      enabled_features: ['header_fullscreen_button'],
      disabled_features: [
        'border_around_the_chart',
        'remove_library_container_border' ],
      loading_screen: {
        backgroundColor: 'black',
        foregroundColor: 'black',
      },
      overrides: {
        background: 'black',
      },
    }}}),_c('trade-history')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"cap"},[_c('span',[_vm._v("Price:")]),_c('span',{staticClass:"light-green"},[_vm._v("43,382.4 "),_c('small',{staticClass:"green"},[_vm._v("+13.13")])])]),_c('div',{staticClass:"cap"},[_c('span',[_vm._v("24H High:")]),_c('span',[_vm._v("44,210.4")])]),_c('div',{staticClass:"cap"},[_c('span',[_vm._v("24H Low:")]),_c('span',[_vm._v("37,928.4")])]),_c('div',{staticClass:"cap"},[_c('span',[_vm._v("24H Amount:")]),_c('span',[_vm._v("14,767.4")])]),_c('div',{staticClass:"cap"},[_c('span',[_vm._v("24H Volume:")]),_c('span',[_vm._v("612,617,853.30")])])])}]

export { render, staticRenderFns }