<template>
  <div class="contents">
    <div class="first-row">
      <div class="title">
        <div class="title-text">
          <h4> Log In to Your Account </h4>
          <!--          <p class="deactive">{{ this.state.lan.login.GoogleTitle }}</p>-->
          <!--          <button>-->
          <!--&lt;!&ndash;            <inline-svg :src="require('../../assets/Authentication/google.svg')"/>&ndash;&gt;-->
          <!--&lt;!&ndash;            <span>{{ this.state.lan.login.GoogleBtn }}</span>&ndash;&gt;-->
          <!--          </button>-->
        </div>
      </div>
    </div>
    <div class="login">

      <div class="second-row">
        <div class="mail">
          <label >Email:</label>
<div class="input-container">
  <input placeholder="Your email address" name="email" v-model="data.email">
</div>
        </div>
        <div class="pass">
          <label >password:</label>
<div class="input-container">
  <input placeholder="Your password" name="pass" :type="type" v-model="data.password">
  <img src="../../assets/profile/eye.svg" alt="" width="18" height="18" @click="showingPass">
</div>
        </div>
      </div>
      <button class="btn-primary" @click="loginUser" :disabled="!data.email || !data.password">
        Login
      </button>


      <div class="forget">
        <span>Don't Have Account? <a class="yellow" @click.prevent="$go('/sign-up')">SignUp</a></span>

        <span class="yellow" @click.prevent="$go('/forget')">Forget Password</span>

      </div>


    </div>
  </div>

</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      type: 'password',
      data:{
        email: "",
        password: "",
        osType: "",
        deviceType: "",
        browser: "",
        referralCode: "",
      }
    }
  },
  methods: {
    showingPass() {
      if (this.type === "password") {
        this.type = "text";
      } else if (this.type === "text") {
        this.type = "password";
      }
    },
    async checkEmail() {
      if (!this.$validEmail(this.data.email) || !this.data.email) {
        this.emailError = "ایمیل صحیح وارد نمایید";
        this.$error("اطلاعات وارد شده اشتباه است", "ایمیل صحیح وارد نمایید");
        return Promise.reject("repeat failed : " + this.data.email);
      } else {
        this.emailError = "";
      }
    },
    googleSso() {
      window.location.href = this.$google_sso;
    },
    //login with google
    async loginUser() {
      this.checkEmail();
      this.state.loading = true;
      const res = await this.$axios.post("/users/login", this.data);

      if (res.message == "login successful, otp is required") {
        //this function will set username as email into cookies
        this.$cookies.set("username", this.data.email);
        this.state.userInfo.email = this.data.email;
        this.state.userInfo.mobilenumber =
            res.baseDTO.starTwoStepAuthentication || "";
        this.state.towStepAuthenticationType =
            res.baseDTO.towStepAuthenticationType;
        console.log(this.state.towStepAuthenticationType)
        await this.$router.push({name:'OTP'});
      }
      // else if (res.token) {
      //   this.$setToken(res.token, this.state.userInfo.remember)
      //   await this.$router.push('/profile')
      // }
    },
  },
  mounted() {
    this.data.osType = this.$detectOs();
    this.data.browser = this.$detectBrowser() || "CHROME";
    this.data.deviceType = this.$detectDevice();
  }
}
</script>

<style lang="scss" scoped>
.contents{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
}
.forget{
  display: flex;
  justify-content: space-between;
  font-size: clamp(.5em, 1.5vw, .8em);
  .yellow{
    color:#A1792B;
  }
}
.btn-primary{
  width: 100%;
  height: 50px;
}

.login {
  width: 100%;
  max-width: 465px;
padding: 2em;
  background: #181A1D;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  //padding: 12px 24px;
  gap: 2em;

  //first row contains title and google login
  .first-row {
    //main title
    .title {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .title-text {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      button {
        display: flex;
        border-radius: 4px !important;
        align-items: center;
        font-size: clamp(10px, 1vw, 12px);
        font-weight: 400;
        background: #181A1D;
        border: 1px solid rgba(178, 176, 176, 0.5);
        min-height: 40px;
        padding: 0 12px;
      }

      span {
        margin-right: 27%;
      }
    }

    //texts of title
    .text {

    }
  }

  //second row contains inputs
  .second-row {
    display: flex;
    flex-direction: column;
    gap: 2.5em;
    .mail, .pass {
      font-weight: 500;
      gap: .7em;
      display: flex;
      flex-direction: column;
      //row-gap: 1.5em;
      .input-container{
        border: 1px solid #272A2E;
        border-radius: 3px;
        padding: 0 1em;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        input{
          width: 60%;
          height: 100%;
        }
      }

      //input{
      //  border: 1px solid #272A2E;
      //  border-radius: 3px;
      //  padding: 1em;
      //  height: 50px;
      //}
      input::placeholder{
        color:var(--gray);
      }
    }
    }



}


//passed some style to deactive text
.deactive {
  font-size: clamp(10px, 1vw, 13px);
}
</style>