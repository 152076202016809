<template>
<div class="container">
  <div class="box">
    <div class="title">
      <h1>Omani by Your Side</h1>
      <span>Contact Omani customer support with your </span>
      <span>questions at any time.</span>
    </div>
    <div class="options">
      <div class="option" v-for="(option,index) in options" :key="index">
        <img :id="`img${index}`" :src="require('../../assets/Public/'+option.icon)" alt="" >
        <h2>{{option.title}}</h2>
        <span>{{option.text}}</span>
      </div>


    </div>
  </div>
</div>
</template>

<script>
export default {
name:'ByYourSide',
  data(){
  return{
    options:[
      {title:'24/7 Customer Service ',
        text:'Contact Omani customer support with your questions at any time.',
        icon:'Support-icon1.png',
        class:''
      },
      {title:'Community',
        text:'The Omani Global Community covers 205 countries and 19 languages.',
        icon:'Support-icon2.png',
        class:''
      },
      {title:'Omani News',
        text:'Keeping you up to date with the latest news in crypto.',
        icon:'Support-icon3.png',
        class:'no-anime'
      },

    ]
  }
  }
}
</script>

<style lang="scss" scoped>

#img1{
  //-webkit-animation: rotating 5s linear infinite;
  //-moz-animation: rotating 5s linear infinite;
  //-ms-animation: rotating 5s linear infinite;
  //-o-animation: rotating 5s linear infinite;
  //animation: rotating 5s linear infinite;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}





.box{
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  gap: 50px;
}
.title{
  display: flex;
  flex-direction: column;
  align-items: center;
  h1{
    font-weight: 600;
    font-size: clamp(2.5rem, 3rem , 3.5em)

  }
  span{
    color: var(--gray);
  }
}
.options{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}
.option{
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex:1 1 300px;
  img{
    margin-bottom: 1.5rem;
    align-self: center;
    width: 120px;
    height: 120px;

  }



  h2,span{
    align-self: center;
    text-align: center;
  }
}
</style>