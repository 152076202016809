<template>
  <div class="crypto-deposit">
    <div class="guide" v-if="isGuide === true">
      <span class="close" @click.prevent="isGuide = false"> X </span>
      <div class="guide-box" v-for="(item, index) in guide" :key="index">
        <div class="title">
          <span class="number">{{ item.number }}</span>
          <p>{{ item.title }}</p>
        </div>
        <div class="text">
          <p>{{ item.content }}</p>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="deposit-box">
        <div class="">
          <span>Select Coin</span>
          <div class="coin-box">
            <button class="drop-btn" @click="dropdown = !dropdown">
              <span class="coin-info">
                <img
                  :src="require(`@/assets/Coins/${sourceCoin.code}.png`)"
                  width="20"
                  height="20"
                />
                {{ sourceCoin.code }}</span
              >

              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.49497 0.505025C1.22161 0.231658 0.778392 0.231658 0.505025 0.505025C0.231658 0.778392 0.231658 1.22161 0.505025 1.49497L1.49497 0.505025ZM7 7L6.50503 7.49497C6.77839 7.76834 7.22161 7.76834 7.49497 7.49497L7 7ZM13.495 1.49497C13.7683 1.22161 13.7683 0.778392 13.495 0.505025C13.2216 0.231658 12.7784 0.231658 12.505 0.505025L13.495 1.49497ZM0.505025 1.49497L6.50503 7.49497L7.49497 6.50503L1.49497 0.505025L0.505025 1.49497ZM7.49497 7.49497L13.495 1.49497L12.505 0.505025L6.50503 6.50503L7.49497 7.49497Z"
                  fill="#7A8083"
                />
              </svg>

              <!--      <img src="@/assets/Public/selector.svg" alt="">-->
            </button>
            <div class="dropdown" v-if="dropdown">
              <span
                v-for="(coin, index) in coins.filter(
                  (coin) => coin.coin !== 'TOMAN'
                )"
                :key="index"
              >
                <img
                  :src="require(`@/assets/Coins/${coin.code}.png`)"
                  alt=""
                  width="18"
                  height="18"
                />
                <span
                  @click="
                    () => {
                      getQR(coin.coin);
                      isNetSelect = true;
                      dropdown = !dropdown;
                      sourceCoin = coin;
                    }
                  "
                >
                  {{ coin.code }}
                  <!--                  <img-->
                  <!--                      :src="require(`@/assets/Coins/${$coin[coin.englishName].code}.png`)"-->
                  <!--                    width="20"-->
                  <!--                    height="20"-->
                  <!--                  />-->
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="" v-if="isNetSelect">
          <span>Select network</span>
          <div class="networks">
            <button
              class="drop-btn"
              @click="networkSelector = !networkSelector"
            >
              <span>{{ selectNet }}</span>

              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.49497 0.505025C1.22161 0.231658 0.778392 0.231658 0.505025 0.505025C0.231658 0.778392 0.231658 1.22161 0.505025 1.49497L1.49497 0.505025ZM7 7L6.50503 7.49497C6.77839 7.76834 7.22161 7.76834 7.49497 7.49497L7 7ZM13.495 1.49497C13.7683 1.22161 13.7683 0.778392 13.495 0.505025C13.2216 0.231658 12.7784 0.231658 12.505 0.505025L13.495 1.49497ZM0.505025 1.49497L6.50503 7.49497L7.49497 6.50503L1.49497 0.505025L0.505025 1.49497ZM7.49497 7.49497L13.495 1.49497L12.505 0.505025L6.50503 6.50503L7.49497 7.49497Z"
                  fill="#7A8083"
                />
              </svg>
              <!--      <img src="@/assets/Public/selector.svg" alt="">-->
            </button>
            <div class="network" v-if="networkSelector">
              <span
                v-for="(net, index) in network"
                :key="index"
                @click="
                  () => {
                    networkSelector = !networkSelector;
                    selectNet = net.title;
                  }
                "
              >
                {{ net.title }}</span
              >
            </div>
          </div>
        </div>


     
        <div class="address" v-if="isNetSelect">
           <p class="copy" v-if="alertLink">Copied</p>
          <span>Address</span>
          <div class="address-container">
            <span  >{{ address }}</span>
            <img @click="copyLink" src="@/assets/profile/copywallet.png" alt="" />
            <img
              src="@/assets/profile/qr.png"
              alt=""
              @click="isQRCODE = !isQRCODE"
            />
          </div>
        </div>
        <div class="faq">
          <div class="col">
            <div class="">
              <span class="title">Expected arrival</span>
              <span>1 network confirmations</span>
            </div>
            <div class="">
              <span class="title">Minimum deposit</span>
              <div class="span">
                {{ sourceCoin.tokenTypeDetails[0].minWithdraw }}
              </div>
            </div>
          </div>
          <div class="col">
            <div class="">
              <span class="title">Expected unlock</span>
              <span>2 network confirmations</span>
            </div>
            <div class="">
              <span class="title">Selected wallet</span>
              <span class="links"
                >spot Wallet <span class="yellow">Change</span>
              </span>
            </div>
          </div>
        </div>
        <div class="warning" v-if="isNetSelect">
          <ul>
            <li>Send Only {{ sourceCoin.code }} to this Address</li>
            <li>Ensure the network is {{ selectNet }}</li>
            <li>
              Do not send NFT to this Address.
              <span class="yellow">learn how to deposit NFT</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="table">
      <p>Recent Deposits</p>

      <div class="table-container">
        <table>
          <tr class="tr-head">
            <td class="tablet">Time</td>
            <td>Type</td>
            <td>Amount</td>
            <td class="tablet">Deposit Wallet</td>
            <td class="mobile">Destination</td>
            <td class="tablet">TxID</td>
            <td>Status</td>
          </tr>
          <tr
            class="tr-body"
            v-for="(item, index) in tableContents"
            :key="index"
          >
            <td class="tablet">{{ item.time }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.amount }}</td>
            <td class="tablet">{{ item.DepositWallet }}</td>
            <td class="mobile">{{ item.Destination }}</td>
            <td class="tablet">{{ item.TxID.slice(0, 9) + "..." }}</td>
            <td
              :class="{
                red: item.Status === 'Failed',
                green: item.Status === 'Completed',
                yellow: item.Status === 'Pending',
              }"
            >
              {{ item.Status }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <QRCODE
      v-if="isQRCODE"
      :qrCode="qrCode"
      :sourceCoin="sourceCoin"
      :selectNet="selectNet"
      @closeModaleEmit="isQRCODE = !isQRCODE"
    />
  </div>
</template>

<script>
import QRCODE from "@/components/Profile/Wallet/overview/QRCODE";

export default {
  name: "forSpot",
  components: { QRCODE },
  data() {
    return {
      alertLink:false,
      dropdown: false,
      isQRCODE: false,
      selectNet: "",
      networkSelector: false,
      coin: "",
      coins: [],
      isSource: false,
      sourceCoin: "BTC",
      isGuide: true,
      address: "",
      qrCode: "",
      isNetSelect: false,
      tag: "",
      network: [
        {
          title: "BTC",
        },
        {
          title: "BEP",
        },
        {
          title: "ERC20",
        },
        {
          title: "TRC20",
        },
      ],
      tableContents: [
        {
          time: "2019-01-01",
          type: "BTC/USD",
          amount: "0.0001",
          DepositWallet: "887654156213216",
          Destination: "324ion425589X2",
          TxID: "0x8d12a197cb00d4747a1fe03395095ce2a5cc6819",
          Status: "Pending",
        },
        {
          time: "2019-01-01",
          type: "BCH/USD",
          amount: "0.0001",
          DepositWallet: "887654156213216",
          Destination: "324ion425589X2",
          TxID: "0x8d12a197cb00d4747a1fe03395095ce2a5cc6819",
          Status: "Completed",
        },
        {
          time: "2019-01-01",
          type: "OMANI/USD",
          amount: "0.0001",
          DepositWallet: "887654156213216",
          Destination: "324ion425589X2",
          TxID: "0x8d12a197cb00d4747a1fe03395095ce2a5cc6819",
          Status: "Failed",
        },
        {
          time: "2019-01-01",
          type: "BTC/USD",
          amount: "0.0001",
          DepositWallet: "887654156213216",
          Destination: "324ion425589X2",
          TxID: "0x8d12a197cb00d4747a1fe03395095ce2a5cc6819",
          Status: "Pending",
        },
        {
          time: "2019-01-01",
          type: "BCH/USD",
          amount: "0.0001",
          DepositWallet: "887654156213216",
          Destination: "324ion425589X2",
          TxID: "0x8d12a197cb00d4747a1fe03395095ce2a5cc6819",
          Status: "Completed",
        },
        {
          time: "2019-01-01",
          type: "OMANI/USD",
          amount: "0.0001",
          DepositWallet: "887654156213216",
          Destination: "324ion425589X2",
          TxID: "0x8d12a197cb00d4747a1fe03395095ce2a5cc6819",
          Status: "Failed",
        },
        {
          time: "2019-01-01",
          type: "BTC/USD",
          amount: "0.0001",
          DepositWallet: "887654156213216",
          Destination: "324ion425589X2",
          TxID: "0x8d12a197cb00d4747a1fe03395095ce2a5cc6819",
          Status: "Pending",
        },
        {
          time: "2019-01-01",
          type: "BCH/USD",
          amount: "0.0001",
          DepositWallet: "887654156213216",
          Destination: "324ion425589X2",
          TxID: "0x8d12a197cb00d4747a1fe03395095ce2a5cc6819",
          Status: "Completed",
        },
        {
          time: "2019-01-01",
          type: "OMANI/USD",
          amount: "0.0001",
          DepositWallet: "887654156213216",
          Destination: "324ion425589X2",
          TxID: "0x8d12a197cb00d4747a1fe03395095ce2a5cc6819",
          Status: "Failed",
        },
      ],

      guide: [
        {
          number: 1,
          title: "Copy Address",
          content:
            "Choose the crypto and its network on this page, and copy the deposit address.",
        },
        {
          number: 2,
          title: "Initiate a withdrawal",
          content: "Initiate a withdrawal on the withdrawal platform.",
        },
        {
          number: 3,
          title: "Network confirmation",
          content: "Wait for the blockchain network to confirm your transfer.",
        },
        {
          number: 4,
          title: "Deposit successful",
          content:
            "After the network confirmation, Binance will credit the crypto for you.",
        },
      ],
    };
  },
  methods: {
      copyLink() {
      navigator.clipboard.writeText(this.address);
      this.alertLink = true;
      setTimeout(() => {
        this.alertLink = false;
      }, 1500);
    },
    async getCoinsInfoFromServer() {
      let coins = await this.$axios("/coins/details");
      this.coins = coins.content;
      console.log(this.coins);
      this.sourceCoin = coins.content[0];
    },
    async getWallets() {
      this.state.loading = true;
      // const coinsList="ETHEREUM,BITCOIN_CASH,TRON,RIPPLE,TETHER,TOMAN,BITCOIN"
      this.loading = true;
      const [res] = await this.$http.get(
        "/wallets/customer-addresses?walletType=P2P"
      );

      if (res) {
        let entireRes = Object.entries(res);
        const sortCoins = this.$walletSortCoins;
        entireRes.sort(
          (a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0])
        );
        this.wallets = entireRes;
        // this.tomancash = res["TOMAN"].credit;
        console.log("response", res);
      }
    },
    async getQR(coin) {
      this.state.loading = true;
      const token = this.selectNet;
      let relatedCoin = this.$coin2Snake[this.sourceCoin];
      console.log("related", relatedCoin);
      let params = {
        relatedCoin: coin,
      };
      if (this.sourceCoin[0] === "TETHER") {
        params.tokenType = token;
      }
      const res = await this.$axios(
        "/wallets/customer/wallet-address?walletType=" +
          "P2P" +
          "&tokenType=" +
          token,
        {
          params,
        }
      ).catch(() => {
        // this.closeModale()
      });
      if (res) {
        this.address = res.baseDTO.address;
        this.qrCode = res.baseDTO.qrCode;
        this.tag = res.baseDTO.tag;
      }
    },
  },
  mounted() {
    this.getCoinsInfoFromServer();
    this.getWallets();
    this.selectNet = this.network[0].title;
    this.getQR("BITCOIN");
  },
};
</script>

<style lang="scss" scoped>
.copy{
  position: absolute;
  right: 40px;
}
.green {
  color: green !important;
}

.red {
  color: red !important;
}

.yellow {
  color: #a1792b !important;
}

.drop-btn {
  background: #1d1f22;
  border: 1px solid #272a2e;
  border-radius: 3px;
}

.network {
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 15px;
  left: 0;
  right: 0;
  width: 100%;
  height: 150px;
  overflow: scroll;
  max-height: 200px;
  background: #1d1f22;
  border: 1px solid #272a2e;
  border-radius: 3px;
}

.crypto-deposit {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .guide {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    padding: 0 3%;
    align-items: center;
    flex-wrap: wrap;
    background: #1d1f22;
    border-radius: 8px;
    min-height: 140px;

    .guide-box {
      max-width: 239px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      //align-items: center;
      .text {
        p {
          letter-spacing: -0.03em;
          color: #7a8083;
          font-size: clamp(8px, 1.2vw, 12px);
        }
      }
    }

    .title {
      display: flex;
      gap: 12px;

      .number {
        display: flex;
        width: 28px;
        height: 28px;
        justify-content: center;
        align-items: center;
        background: #ffbb38;
        border-radius: 3px;
      }
    }
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //align-items: center;
  .deposit-box {
    position: relative;
    max-width: 550px;
    width: 100%;
    min-width: 270px;
    align-self: center;
    display: flex;
    flex-direction: column;
    background: #181a1d;
    border-radius: 8px;
    padding: clamp(24px, 1.2vw, 32px);
    gap: 32px;
  }
}

.dropdown {
  z-index: 3;

  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5em;
  }

  position: absolute;
  //top: 3.4em;
  //left: 0;
  //left: 67%;
  //width: 120px;
  left: 0;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  overflow: scroll;
  max-height: 200px;
  background: #1d1f22;
  border: 1px solid #272a2e;
  border-radius: 3px;
}

.drop-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;

  .coin-info {
    display: flex;
    align-items: center;
    gap: 12px;
    height: 100%;
  }
}

.address {
  span:first-child {
    font-size: clamp(12px, 1.2vw, 16px);
  }

  display: flex;
  gap: 12px;
  flex-direction: column;
}

.address-container {
  display: flex;
  justify-content: center;
  padding: 0;
  flex-wrap: wrap;
}

.faq {
  display: flex;
  gap: clamp(32px, 1.2vw, 48px);
  flex-wrap: wrap;

  .col {
    display: flex;
    flex-direction: column;
    gap: 16px;

    div {
      font-size: clamp(10px, 1.2vw, 14px);
      display: flex;
      flex-direction: column;
      gap: 8px;

      span {
        font-size: clamp(12px, 1.2vw, 16px);
      }

      .title {
        color: #7a8083;
      }
    }
  }
}

.links {
  display: flex !important;
  gap: 8px;
}

.yellow {
  color: #a1792b;
}

.warning {
  align-self: flex-start;
  font-size: 14px;

  ul {
    li {
      list-style-type: disc;
    }
  }
}

.table-container {
  max-height: 300px;
  overflow: scroll;
  table {
    td {
      height: 40px;
      font-size: clamp(8px, 1.2vw, 12px);
      color: #7a8083;
    }
  }
}

@media (max-width: 1020px) {
  .tablet {
    display: none;
  }
}

@media (max-width: 576px) {
  .mobile {
    display: none;
  }
  .guide-box {
    flex-direction: column;
    width: 100%;
    padding: 10px 0;
  }
}
</style>